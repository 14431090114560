import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { SolarSystemLiteItem } from "../../models/Locations/solarSystem";
import { PagingParams } from "../../models/pagination";


export default class UserLiteStore {
    loadingGalaxies = false;
    totalSolarSystemLiteCount: number = 0;
    pagingParamsSolarSystemLite = new PagingParams(-1, 0, "name");
    solarSystemLiteItems: SolarSystemLiteItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsSolarSystemLite = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalSolarSystemLiteCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsSolarSystemLite.take.toString());
        params.append('skip', this.pagingParamsSolarSystemLite.skip.toString());
        params.append('sort', this.pagingParamsSolarSystemLite.sort);

        return params;
    }

    loadSolarSystemList = async (galaxyId: number = 0) => {
        this.loadingGalaxies = true;

        try {
            var axiosParams = this.axiosParams;

            if (galaxyId !== 0) {
                axiosParams.append('galaxyId', galaxyId.toString());
            }

            const result = await agent.SolarSystemApi.getLite(axiosParams);

            runInAction(() => {
                this.solarSystemLiteItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingGalaxies = false;
            })
        } catch(error) {
            runInAction(() => this.loadingGalaxies = false);
            throw error;
        }
    }
}