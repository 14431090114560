import { Button, FormControl, FormControlLabel, Switch, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import CategoryFilter from "../../common/filters/CategoryFilter";
import SchematicFilter from "../../common/filters/SchematicFilter";

interface Props {
    handleAddSchematic: (id: number, qty: number, name: string) => void;
}

export default observer(function SelectSchematicComponent({ handleAddSchematic }: Props) {
    const [categoryId, setCategoryId] = useState(0);
    const [includeArtifacts, setIncludeArtifacts] = useState(false);
    const [includeModules, setIncludeModules] = useState(false);
    const [schematicId, setSchematicId] = useState(0);
    const [schematicName, setSchematicName] = useState('');
    const [quantity, setQuantity] = useState(1);

    const handleIncludeArtifacts = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeArtifacts(!includeArtifacts);
    }

    const handleSetSchematic = (schematicId: number, schematicName: string) => {
        setSchematicId(schematicId);
        setSchematicName(schematicName);
    }

    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);
        input < 1 ? setQuantity(1) : setQuantity(input);
    }

    const handleSetCategory = (categoryId: number) => {
        setCategoryId(categoryId);

        if(categoryId === 0) {
            setIncludeArtifacts(false);
            setIncludeModules(false);
        } else {
            setIncludeModules(true);
        }
    }

    function handleClickAdd() {
        if (schematicId !== 0) {
            handleAddSchematic(schematicId, quantity, schematicName);
        }
    }

    return (
        <>
           <CategoryFilter setCategoryId={handleSetCategory} />
            <FormControl>
                <FormControlLabel control={<Switch checked={includeArtifacts} color="success" onChange={handleIncludeArtifacts} disabled={categoryId === 0} />} label="Include Artifacts" />
            </FormControl>
            <SchematicFilter categoryId={categoryId} includeModules={includeModules} includeArtifacts={includeArtifacts} setSchematicId={handleSetSchematic} />
            <FormControl>
                <TextField 
                    id="Quantity-input"
                    label="Quantity"
                    variant="outlined"
                    type="number"
                    value={quantity}
                    onChange={handleChangeQuantity}
                    sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                />
            </FormControl>   
            <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{marginBottom: '15px', width: "250px", marginLeft: '-1px'}}
                onClick={handleClickAdd}
                disabled={schematicId === 0}
            >
                Add
            </Button>         
        </>
      
    )
})