import { observer } from "mobx-react-lite";
import {  Box, Grid, Table, TableBody, TableContainer, TableHead, TablePagination, TableSortLabel, Typography, Link as MuiLink, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { SchematicResource } from "../../../app/models/Schematics/Schematic";
import { useState } from "react";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import { visuallyHidden } from '@mui/utils';
import { useAuth0 } from "@auth0/auth0-react";
import * as _ from "lodash";

interface Props {
    resources: SchematicResource[]
}

export default observer(function SchematicResources({ resources }: Props) {
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);
    const { isAuthenticated } = useAuth0();

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTake(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
            } else {
                setSortOrder(SortingType.asc);
            }
            setSortColumn(column);
        }
    }
    
    return (
        <Grid container>
            <Grid item md={12} sm={12}>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="sold at table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Resource
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "quantity"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("quantity")}
                                    >
                                        Quantity
                                        {sortColumn === "quantity" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "cost"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("cost")}
                                    >
                                        Cost
                                        {sortColumn === "cost" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                              
                                        <StyledTableCell>
                                            <TableSortLabel
                                                active={sortColumn === "myQty"}
                                                direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                                onClick={ () => handleChangeSort("myQty")}
                                            >
                                                Quantity Have
                                                {sortColumn === "myQty" ? (
                                                    <Box 
                                                        component="span" 
                                                        sx={visuallyHidden}
                                                    >
                                                        {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null }
                                            </TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel
                                                active={sortColumn === "missingQty"}
                                                direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                                onClick={ () => handleChangeSort("missingQty")}
                                            >
                                                Quantity Needed
                                                {sortColumn === "missingQty" ? (
                                                    <Box 
                                                        component="span" 
                                                        sx={visuallyHidden}
                                                    >
                                                        {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null }
                                            </TableSortLabel>
                                        </StyledTableCell>
                                    </TableRow>
                            </TableHead>
                            <TableBody>
                                {resources && resources.length > 0 ? _.orderBy(resources, [sortColumn], [sortOrder == SortingType.asc ? 'asc' : 'desc']).slice(page * take, page * take + take).map((item: SchematicResource) => (
                                    <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                        <StyledTableCell>
                                            <MuiLink
                                                component={Link}
                                                to={`/resources/${item.slug}`}
                                                color="inherit"
                                            >
                                                {item.name}
                                            </MuiLink>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                                        </StyledTableCell>
                                       
                                                <StyledTableCell align="right">
                                                    {item.myQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Typography component="span" variant="body2" color={item.missingQty > 0 ? "error.main" : "success.main"}>
                                                        {item.missingQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </Typography>
                                                </StyledTableCell>
                                    </StyledTableRow>
                                )) : 
                                <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell colSpan={5}>
                                        <Typography variant="body2" component="div">No Schematic Resources Found</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={resources?.length || 0}
                        page={page}
                        rowsPerPage={take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </Grid>
        </Grid>
    )    
})
