import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, Grid, TextField, FormControl, Select, MenuItem, SelectChangeEvent, InputLabel, Typography, TableSortLabel, Box } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { pageRows,  SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { ResourceListItem } from "../../../../app/models/Resources/Resource";
import { visuallyHidden } from '@mui/utils';

export default observer(function StoreList() {
    const { resourcesStore } = useStore();
    const { loading, pagingParams, totalCount, resourceItems, loadResourceList } = resourcesStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [resourceType, setResourceType] = useState(0);
    const [lootType, setLootType] = useState(0);
    
    useEffect(() => {
        loadResourceList(resourceType, searchString, lootType);

    }, [loadResourceList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadResourceList(resourceType, searchString, lootType);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadResourceList(resourceType, searchString, lootType);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadResourceList(resourceType, searchString, lootType);
    }

    const handleSearch = (change : string) => {
        setSearchString(change);
    }

    const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            pagingParams.skip = 0;
            loadResourceList(resourceType, searchString, lootType);
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        pagingParams.skip = 0;
        setResourceType(parseInt(event.target.value));
        loadResourceList(parseInt(event.target.value), searchString, lootType);
    }

    const displayType = (type: number) => {
        switch(type)
        {
            case 1:
                return ("Resource");
            case 2:
                return ("Commodity");
            case 3:
                return ("Expanse");    
            case 4:
                return ("Alien");
            case 5:
                return ("Settlement");
            case 6:
                return ("Nebula");
            case 7:
                return ("Special Resource");
            default:
                return ("None");
        }
    }

    if (loading) return <LoadingComponent />

    return (
            <>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="resource-select">Type</InputLabel>
                            <Select
                                id="resource-select"
                                value={resourceType.toString()}
                                onChange={handleChange}
                                label="Type"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem value={1}>Resource</MenuItem>
                                <MenuItem value={2}>Commodity</MenuItem>
                                <MenuItem value={3}>Expanse</MenuItem>
                                <MenuItem value={4}>Alien</MenuItem>
                                <MenuItem value={5}>Settlement</MenuItem>
                                <MenuItem value={6}>Nebula</MenuItem>
                                <MenuItem value={7}>Special Resource</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField id="name" label="Search" variant="outlined" onChange={event => handleSearch(event.target.value)} onKeyDown={handleCompleteSearch} value={searchString}  />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Resources table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Resource
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "slug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("slug")}
                                    >
                                        Slug
                                        {sortColumn === "slug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "basePrice"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("basePrice")}
                                    >
                                        Base Price
                                        {sortColumn === "basePrice" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "averagePrice"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("averagePrice")}
                                    >
                                        Average Price
                                        {sortColumn === "averagePrice" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "type"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("type")}
                                    >
                                        Type
                                        {sortColumn === "type" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resourceItems ? resourceItems.map((item: ResourceListItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/resources/${item.slug}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{item.slug}</StyledTableCell>
                                    <StyledTableCell>{`${item.basePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`}</StyledTableCell>
                                    <StyledTableCell>{`${item.averagePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`}</StyledTableCell>
                                    <StyledTableCell>{displayType(item.type)}</StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={6}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No resources found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

