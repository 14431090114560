import { FactoryCalcType } from "../../utils/globalConstants";

export class SmrRequest {
    smrFacilities: SmrRequestItem[] = [];
    locationId: number = 0;

    constructor(form?: SmrRequest) {
        if (form) {
            this.smrFacilities = form.smrFacilities;
            this.locationId = form.locationId;
        }
    }
}

export class SmrRequestItem {
    facilityId: number = 0;
    quantity: number = 0;
    researchLevel: number = 0;
    productionQuantity: number = 0;
    calcType: FactoryCalcType = 1;

    constructor(form?: SmrRequestItem) {
        if (form){
            this.facilityId = form.facilityId;
            this.quantity = form.quantity;
            this.researchLevel = form.researchLevel;
            this.productionQuantity = form.productionQuantity;
            this.calcType = form.calcType;
        }
    }
}


export interface SmrResponse {
    facilities: SmrFacilityResponse[];
    production: SmrProductionResponse[];
    requirements: SmrRequirementResponse[];
}

export interface SmrFacilityResponse {
    facilityId: number;
    facility: string;
    quantity: number;
    researchLevel: number;
    productionQuantity: number;
    productionType: FactoryCalcType;
}

export interface SmrProductionResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    duration: number;
    productionType: FactoryCalcType;
}

export interface SmrRequirementResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}