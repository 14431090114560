import { observer } from "mobx-react-lite";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import SchematicIcon from "@mui/icons-material/Apartment";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { Box, FormControlLabel, FormGroup, Grid, Link as MuiLink, Switch, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { SchematicListItem } from "../../app/models/Schematics/Schematic";
import { Link, useSearchParams } from "react-router-dom";
import { visuallyHidden } from '@mui/utils';
import AddSchematicComponent from "./components/AddSchematicComponent";
import CategoryFilter from "../common/filters/CategoryFilter";

export default observer(function SchematicsListPage() {
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Schematics", "pageurl": "/schematics", "active": true, icon: <SchematicIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    const { schematicStore, categoryStore } = useStore();
    const { loading, pagingParams, totalCount, schematicItems, loadSchematicList } = schematicStore;
    const { loadCategoryList, categoryItems } = categoryStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [includeModules] = useState(true);
    const [includeArtifacts, setIncludeArtifacts] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get("cat");
    const [categoryId, setCategoryId] = useState(0);

    useEffect(() => {
        loadSchematicList(categoryId, searchString, includeModules, includeArtifacts, category!);

    }, [loadSchematicList, category])

    useEffect(() => {
        loadCategoryList(false, 1);
    }, [loadCategoryList]);

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadSchematicList(categoryId, searchString, includeModules, includeArtifacts, category!);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        setPage(0);

        loadSchematicList(categoryId, searchString, includeModules, includeArtifacts, category!);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadSchematicList(categoryId, searchString, includeModules, includeArtifacts, category!);
    }

    const handleSearch = (change : string) => {
        setSearchString(change);
    }

    const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            pagingParams.skip = 0;
            setPage(0);

            loadSchematicList(categoryId, searchString, includeModules, includeArtifacts, category!);
        }
    }

    const handleIncludeArtifacts = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeArtifacts(!includeArtifacts);
        pagingParams.skip = 0;
        setPage(0);

        if (event.target.checked) {
            loadSchematicList(categoryId, searchString, includeModules, true, category!);
        } else {
            loadSchematicList(categoryId, searchString, includeModules, false, category!);
        }
    }

    const handleChangeCateogry = (categoryId: number) => {
        pagingParams.skip = 0;
        setPage(0);

        const newCat = categoryItems.find(e => e.id === categoryId); 

        if (newCat !== undefined) {
            setSearchParams({ cat: newCat?.slug!});
        } else {
            searchParams.delete('cat');
            setSearchParams(searchParams);
        }

        setCategoryId(categoryId);
        loadSchematicList(categoryId, searchString, includeModules, includeArtifacts);
    }

    return (
        <PageContainer pagetitle="Schematics" breadcrumbs={breadcrumbs}>
            <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={includeArtifacts} color="success" onChange={handleIncludeArtifacts} />} label="Artifacts" />
                        </FormGroup>
                    </Grid>
                    <Grid item>
                        <CategoryFilter setCategoryId={handleChangeCateogry} />
                    </Grid>
                    <Grid item>
                        <TextField id="name" label="Search" variant="outlined" onChange={event => handleSearch(event.target.value)} onKeyDown={handleCompleteSearch} value={searchString}  />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Schematics table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Schematic
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "level"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("level")}
                                    >
                                        Level
                                        {sortColumn === "level" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "category"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("category")}
                                    >
                                        Category
                                        {sortColumn === "category" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "isAlien"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isAlien")}
                                    >
                                        Alien
                                        {sortColumn === "isAlien" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "isArtifact"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isArtifact")}
                                    >
                                        Artifact
                                        {sortColumn === "isArtifact" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {schematicItems ? schematicItems.map((item: SchematicListItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/schematics/${item.slug}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{`${item.level.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</StyledTableCell>
                                    <StyledTableCell>{item.category}</StyledTableCell>
                                    <StyledTableCell><Switch checked={item.isAlien} disabled color="success" /></StyledTableCell>
                                    <StyledTableCell><Switch checked={item.isArtifact} disabled color="success" /></StyledTableCell>
                                    <StyledTableCell>
                                        <AddSchematicComponent id={item.id} name={item.name} small={true} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={8}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No schematics found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </PageContainer>
    )
})

