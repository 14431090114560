import { Typography } from "@mui/material";

interface PageHeaderProps {
    pagetitle?: string;
    pagetext?: string;
  }

export default function PageHeader({pagetitle, pagetext}: PageHeaderProps) {
    return (
        <>
            <Typography component="h4" variant="h4">
                {pagetitle}
            </Typography>
            <Typography component="p" variant="body1">
                {pagetext}
            </Typography>
        </>
    )
}