import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Container, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ProtectedRoutes } from './utils/ProtecedRoutes';

import FooterBar from './layout/FooterBar';
import MainMenu from './layout/MainMenu';
import SideMenu from './layout/SideMenu';

import NotFound from '../features/errors/NotFound';
import ServerError from '../features/errors/ServerError';
import HomePage from '../features/home/HomePage';
import CompleteRegistration from '../features/auth/completeRegistration';
import ProfilePage from '../features/profile/ProfilePage';
import UserListPage from '../features/users/UserListPage';
import UserDetailsPage from '../features/users/UserDetailsPage';
import ArticleListPage from '../features/articles/ArticleListPage';
import ArticleFormPage from '../features/articles/ArticleFormPage';
import TimelineListPage from '../features/timelines/TimelineListPage';
import TimelineFormPage from '../features/timelines/TimelineFormPage';
import FaqListPage from '../features/faq/FaqListPage';
import FaqFormPage from '../features/faq/FaqFormPage';
import Unauthorized from '../features/errors/Unauthorized';
import ArticlePage from '../features/articles/ArticlePage';
import FaqPage from '../features/faq/FaqPage';
import TimelinePage from '../features/timelines/TimelinePage';
import StoresListPage from '../features/admin/stores/StoresListPage';
import LocationPage from '../features/admin/locations/LocationPage';
import ResourcesListPage from '../features/admin/resources/ResourcesListPage';
import ResourceListPage from '../features/resources/ResourceListPage';
import ResourceDetailPage from '../features/resources/ResourceDetailPage';
import SchematicsListPageAdmin from '../features/admin/schematics/SchematicsListPageAdmin';
import SchematicsListPage from '../features/schematics/SchematicsListPage';
import SchematicsDetailPage from '../features/schematics/SchematicsDetailPage';
import BuildShip from '../features/shipBuilder/BuildShip';
import BuildStarbase from '../features/starbaseBuilder/BuildStarbase';
import MyShips from '../features/shipBuilder/MyShips';
import MyStarbases from '../features/starbaseBuilder/MyStarbases';
import BlueprintsListPageAdmin from '../features/admin/blueprints/BlueprintsListPageAdmin';
import BlueprintListPage from '../features/blueprints/BlueprintListPage';
import BlueprintDetailPage from '../features/blueprints/BlueprintDetailPage';
import NotLoggedIn from '../features/errors/NotLoggedIn';
import GbmHistoryListPage from '../features/Gbm/GbmHistoryListPage';
import CraftingList from '../features/Crafting/CraftingList';
import PlayerResourcesPage from '../features/MyResources/PlayerResourcesPage';
import PlayerModulesPage from '../features/MySchematics/PlayerModulesPage';
import MyBattleshipModulesPage from '../features/MyBattleships/MyBattleshipModulesPage';
import FoundryCalculatorPage from '../features/Foundry/FoundryCalculatorPage';
import HeavyFuelCalcPage from '../features/Factories/HeavyFuel/HeavyFuelCalcPage';
import SmrPage from '../features/Factories/Smr/SmrPage';
import AdvRefineryPage from '../features/Factories/AdvRefinery/AdvRefineryPage';
import FactoryPage from '../features/Factories/Factories/FactoryPage';
import RefineryPage from '../features/Factories/Refineries/RefineryPage';

const curDrawerOpenState = JSON.parse(localStorage.getItem("drawerOpen") || "true");

const CustomBox = styled(Box)(({ theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(40,40,40)' : '#fff',
}));

function App() {
  const [open, setOpen] = React.useState(curDrawerOpenState);

  function toggleDrawer() {
    setOpen(!open);
    localStorage.setItem("drawerOpen", JSON.stringify(!open));
  };

  return (
    <>
      <MainMenu open={open} toggleDrawer={toggleDrawer} />

      <SideMenu open={open} />
      
      <CustomBox
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4}}>
          <Routes>
             <Route path='/' element={<HomePage />} />
             <Route path='/callback' element={<HomePage />} />
             <Route path='/completeregistration' element={<CompleteRegistration />} />

             <Route path='/article/:id' element={<ArticlePage />} />

             <Route path='/faq' element={<FaqPage />} />
             <Route path="/timeline" element={<TimelinePage />} />

             <Route path='/resources' element={<ResourceListPage />} />
             <Route path='/resources/:id' element={<ResourceDetailPage />} />
             <Route path='/gbmHistory' element={<GbmHistoryListPage />} />

             <Route path='/schematics' element={<SchematicsListPage />} />
             <Route path='/schematics/:id' element={<SchematicsDetailPage />} />
             <Route path='/crafting' element={<CraftingList />} />

             <Route path='/shipbuilder/build' element={<BuildShip />} />

             <Route path='/starbasebuilder/build' element={<BuildStarbase />} />

             <Route path='/blueprints' element={<BlueprintListPage />} />
             <Route path='/blueprints/:id' element={<BlueprintDetailPage />} />

             <Route path='/foundry/calculator' element={<FoundryCalculatorPage />} />

             <Route path='/factory/heavyfuel' element={<HeavyFuelCalcPage />} />
             <Route path='/factory/smr' element={<SmrPage />} />
             <Route path='/factory/advRefinery' element={<AdvRefineryPage />} />
             <Route path='/factory/factory' element={<FactoryPage />} />
             <Route path='/factory/refinery' element={<RefineryPage />} />

            <Route element={<ProtectedRoutes />}>
              <Route path="/profile" element={<ProfilePage />} />

              <Route path='/shipbuilder' element={<MyShips />} />
              <Route path='/shipbuilder/build/:id' element={<BuildShip />} />

              <Route path='/starbasebuilder' element={<MyStarbases />} />
              <Route path='/starbasebuilder/build/:id' element={<BuildStarbase />} />

              <Route path='/resources/cs' element={<PlayerResourcesPage />} />

              <Route path='/schematics/modules' element={<PlayerModulesPage />} />
              <Route path='/schematics/battleships' element={<MyBattleshipModulesPage />} />

              <Route path="/admin/users" element={<UserListPage />} />
              <Route path="/admin/users/:id" element={<UserDetailsPage />} />

              <Route path='/admin/articles' element={<ArticleListPage />}  />
              <Route path='/admin/articles/create' element={<ArticleFormPage />} />
              <Route path='/admin/articles/:id' element={<ArticleFormPage />} />

              <Route path='/admin/timelines' element={<TimelineListPage />} />
              <Route path='/admin/timelines/create' element={<TimelineFormPage />} />
              <Route path='/admin/timelines/:id' element={<TimelineFormPage />} />

              <Route path='/admin/faqs' element={<FaqListPage />} />
              <Route path='/admin/faqs/create' element={<FaqFormPage />} />
              <Route path='/admin/faqs/:id' element={<FaqFormPage />} />

              <Route path='/admin/stores' element={<StoresListPage />} />

              <Route path='/admin/locations' element={<LocationPage />} />

              <Route path='/admin/resources' element={<ResourcesListPage />} />

              <Route path='/admin/schematics' element={<SchematicsListPageAdmin />} />

              <Route path='/admin/blueprints' element={<BlueprintsListPageAdmin />} />
            </Route>

            <Route path='/unauthorized' element={<Unauthorized />} /> 
            <Route path='/server-error' element={<ServerError />} />
            <Route path='/not-found' element={<NotFound />} />
            <Route path='/login-error' element={<NotLoggedIn />} />
            <Route path='*' element={<Navigate to="/not-found" />} />
          </Routes>
          <FooterBar />
        </Container>
      </CustomBox>
      
    </>
  );
}

export default App;
