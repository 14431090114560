import { observer } from "mobx-react-lite";
import { Box, Button, Grid, Paper, Tab, Tabs } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import CraftingIcon from '@mui/icons-material/Construction';

import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import SelectSchematicComponent from "./Components/SelectSchematicComponent";
import { SchematicQuery, SchematicQueryLite, SchematicRequest } from "../../app/models/Schematics/Schematic";
import { useStore } from "../../app/stores/store";
import ReductionMatrixFilter from "../common/filters/ReductionMatrixFilter";
import { useEffect, useState } from "react";
import ResourceLocationFilter from "../common/filters/ResourceLocationFilter";
import { useAuth0 } from "@auth0/auth0-react";
import SchematicListComponent from "./Components/SchematicListComponent";
import SchematicSummaryComponent from "./Components/SchematicSummaryComponent";
import { TabPanel, a11yProps } from "../../app/utils/TabUtils"
import PageHeader from "../../app/common/page/PageHeader";
import SchematicResourceDetail from "./Components/SchematicResourceDetail";
import SchematicModuleDetail from "./Components/SchematicModuleDetail";
import SchematicListUploadResourcesDetail from "./Components/SchematicListUploadResourcesDetail";


export default observer(function CraftingList() {
    const { commonStore, craftingStore } = useStore();
    const { redmat, setRedMat } = commonStore;
    const { craftingItem, loadCraftingList, setLocationId, locationId, schematicList, addSchematic, resetSchematics, loading } = craftingStore;
    const { isAuthenticated } = useAuth0();
    const [value, setValue] = useState(0);

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Crafting List", "pageurl": "/crafting", "active": true, icon: <CraftingIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
       handleLoad();
    }, [redmat, locationId, schematicList])

    function handleAddSchematic(id: number, qty: number, name: string) {
        const form : SchematicQuery = {
            id: id,
            quantity: qty,
            name: name
        };

        addSchematic(form);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const handleLoad = () => {
        const form : SchematicRequest = {
            locationId: locationId,
            redMat: redmat!,
            schematics: schematicList?.map(item => new SchematicQueryLite(item))!
        };

        loadCraftingList(form);
    }

    return(
        <PageContainer breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item lg={2} md={3} sm={12} minWidth="270px">
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              width: 270,
                              padding: '10px',
                              marginLeft: '-5px'
                            },
                          }}
                    >
                        <Paper elevation={1}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={resetSchematics}
                                sx={{width: "250px", marginLeft: '-1px'}}
                            >
                                Clear All Schematics
                            </Button>
                        </Paper>
                        <Paper>
                            <ReductionMatrixFilter reductionMatrixId={redmat!} setReductionMatrixId={setRedMat} />
                        </Paper>
                        <Paper>
                            <SelectSchematicComponent handleAddSchematic={handleAddSchematic} />
                        </Paper>
                        { isAuthenticated && 
                            <Paper>
                                <ResourceLocationFilter setLocationId={setLocationId} locationId={locationId} />
                            </Paper>
                        }
                        <SchematicListComponent />
                    </Box>
                </Grid>
                <Grid item lg={9} md={8} sm={12}>
                    <SchematicSummaryComponent schematic={craftingItem!} />
                    <Grid>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="schematics menu"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            sx={{ borderBottom: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Resources" {...a11yProps(0)} />
                            <Tab label="Schematics & Modules" {...a11yProps(1)} />
                            {isAuthenticated && 
                                <Tab label="Upload Resources" {...a11yProps(2)} />
                            }
                        </Tabs>
                    </Grid>
                    <Grid>
                        <TabPanel value={value} index={0}>
                            <PageHeader pagetitle="Resources" />
                            <SchematicResourceDetail resources={craftingItem?.resources!} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PageHeader pagetitle="Schematics & Modules" />
                            <SchematicModuleDetail schematics={craftingItem?.schematics!} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <PageHeader pagetitle="Upload Resources" />
                            <SchematicListUploadResourcesDetail handleOnUpload={handleLoad} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    )
})