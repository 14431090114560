export interface TimelineItem {
    id: number;
    title: string;
    contents: string;
    isPublished: boolean;
    publishedDate: Date | null;
}

export interface ITimelineForm {
    id: number;
    title: string;
    contents: string;
    isPublished: boolean;
    publishedDate: Date | null;
}

export class TimelineForm {
    id: number = 0;
    title: string = '';
    contents: string = '';
    isPublished: boolean = false;
    publishedDate: Date | null = null;

    constructor(timeline?: ITimelineForm) {
        if (timeline) {
            this.id = timeline.id;
            this.title = timeline.title;
            this.contents = timeline.contents;
            this.isPublished = timeline.isPublished;
            this.publishedDate = timeline.publishedDate;
        }
    }
}