import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CurrentUser, ThemeForm, ReductionMatrixForm, FoundryCubeForm } from "../../models/Users/currentUser";
import { store } from "../store";

export default class CurrentUserStore {
    currentUser: CurrentUser | null  = JSON.parse(localStorage.getItem('currentUser') || '{}');

    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    login = async () => {
        this.loading = true;

        try {
            var user = await agent.CurrentUserApi.get();

            runInAction(() => {
                this.currentUser = user;

                store.commonStore.setCurrentUser(JSON.stringify(user));
                store.commonStore.setTheme(user.theme);
                store.commonStore.setRedMat(user.reductionMatrix);
                store.commonStore.setFoundry(user.foundryCube);

                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    logoutUser = async () => {
        localStorage.removeItem('currentUser');
    }

    getCurrentUser = async () => {
            try {
                const user : CurrentUser = JSON.parse(localStorage.getItem('currentUser') || '');
                this.currentUser = user;

                return user;
            }
            catch {
                this.login();
    
                return this.currentUser;
            }
    }

    updateTheme = async (form: ThemeForm) => {
        try {
            await agent.CurrentUserApi.setTheme(form);

            runInAction(() => {
                this.login();
            })
        } catch(error) {
            throw error;
        }
    }
    
    updateReductionMatrix = async (form: ReductionMatrixForm) => {
        try {
            await agent.CurrentUserApi.setReductionMatrix(form);

            runInAction(() => {
                this.login();
            })
        } catch(error) {
            throw error;
        }
    }

    updateFoundryCube = async (form: FoundryCubeForm) => {
        try {
            await agent.CurrentUserApi.setFoundryCube(form);

            runInAction(() => {
                this.login();
            })
        } catch(error) {
            throw error;
        }
    }

}