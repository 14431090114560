import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { FoundryCalcuationNanos } from "../../../app/models/Foundry/Foundry";
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable";
import { useState } from "react";
import { SortingType, pageRows } from "../../../app/utils/globalConstants";
import _ from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";



export default observer(function NanoPatternListComponent() {
    const { foundryCalculationStore } = useStore();
    const { loading, foundryCalculations } = foundryCalculationStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const { isAuthenticated } = useAuth0();
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);

    const columns: IColumnType<FoundryCalcuationNanos>[] = 
        isAuthenticated 
        ? [
        {
            key: "name",
            title: "Pattern",
            sortLabel: "name",
            isSortable: true
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "quantityHave",
            title: "Quantity Have",
            sortLabel: "quantityHave",
            isSortable: true,
            align: "right",
            render: (_, { quantityHave }) => (
                <>{quantityHave.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "quantityNeed",
            title: "Quantity Need",
            sortLabel: "quantityNeed",
            isSortable: true,
            align: "right",
            render: (_, { quantityNeed }) => (
                <Typography component="span" variant="body2" color={quantityNeed > 0 ? "error.main" : "success.main"}>
                    {quantityNeed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
            )
        }]
        : [
            {
                key: "name",
                title: "Pattern",
                sortLabel: "name",
                isSortable: true
            },
            {
                key: "quantity",
                title: "Quantity",
                sortLabel: "quantity",
                isSortable: true,
                align: "right",
                render: (_, { quantity }) => (
                    <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
                )
            }]
    

    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: foundryCalculations?.patterns?.length  ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    return (
        <CustomTable
                    data={_.orderBy(foundryCalculations?.nanos, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    pagination={pagination}
                    tableName="Foundry Patterns"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Patterns Found"
                />
    )

})