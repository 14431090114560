import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { RegisterForm, CompleteRegistrationForm } from "../../models/Users/register";


export default class RegistrationStore { 
    constructor() {
        makeAutoObservable(this);
    }

    register = async(form: RegisterForm) => {
        try {
            await agent.RegistrationApi.register(form);
        } catch(error) {
            throw error;
        }
    }

    completeRegistration = async(form: CompleteRegistrationForm) => {
        try {
            await agent.RegistrationApi.comleteRegistration(form);
        } catch(error) {
            throw error;
        }
    }

}