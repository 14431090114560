import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store"
import { observer } from "mobx-react-lite";
import { SortingType, pageRows } from "../../../app/utils/globalConstants";
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable";
import { FactoryByResourceItem } from "../../../app/models/Factory/Factory";

interface Props {
    slug: string
}

export default observer(function FactoryByResourceList({ slug }: Props) {
    const { factoryByResourceStore } = useStore();
    const { loadFactoryList, loading, factoryItems, pagingParams, totalCount } = factoryByResourceStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);

    useEffect(() => {
        loadFactoryList(slug);
    }, [loadFactoryList, slug, page, rowsPerPage, sortOrder, sortColumn])

    const handlePageChange = (page:number) => {
        pagingParams.skip = page * pagingParams.take;
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        pagingParams.take = rows;
        pagingParams.skip = 0;

        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        if (sortOrder === SortingType.asc) {
            pagingParams.sort = column;
        } else {
            pagingParams.sort = `-${column}`;
        }

        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: totalCount,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<FactoryByResourceItem>[] = [
        {
            key: "itemName",
            title: "Facility",
            sortLabel: "itemName",
            isSortable: false
        },
        {
            key: "category",
            title: "Category",
            sortLabel: "category",
            isSortable: false
        },
        {
            key: "subCategory",
            title: "Sub Category",
            sortLabel: "subCategory",
            isSortable: false
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: false,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "duration",
            title: "Production Cycle",
            sortLabel: "duration",
            isSortable: false,
            align: "right",
            render: (_, { duration }) => (
                <>{duration.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Hr(s)</>
            )
        }
    ]

    return (
        <CustomTable
            data={factoryItems}
            columns={columns}
            pagination={pagination}
            tableName="Resource Facilities"
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            onSortChange={handleSortChange}
            loading={loading}
            emptyTableText="No Facilities found"
        />
    )
})