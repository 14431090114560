import { observer } from "mobx-react-lite";
import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from '@mui/icons-material/Dashboard';
import UsersIcon from "@mui/icons-material/Groups";
import UserIcon from "@mui/icons-material/Person"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { history } from "../..";
import { Alert, Button, FormControl, FormControlLabel, Grid, Switch, Tab, Tabs } from "@mui/material";
import PageHeader from "../../app/common/page/PageHeader";
import { useStore } from "../../app/stores/store";
import ThemeToggler from "./components/ThemeToggler";
import ReductionMatrixToggler from "./components/ReductionMatrixToggler";
import ActivityLog from "./components/ActivityLog";
import { ErrorMessage, Form, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import { reductionMatrixOptionList, themeOptionList } from "../../app/utils/globalConstants";
import MyResourcesComponent from "../MyResources/Components/MyResourcesComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default observer(function UserDetailsPage() {
    const { user } = useAuth0();
    const { userAdminStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState(0);
    const { loadUser, peUser, updateUser } = userAdminStore;
    const navigate = useNavigate();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Users", "pageurl": "/admin/users", "active": false, icon: <UsersIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": `${peUser?.userName!}`, "pageurl": `/admin/users/${id}`, "active": true, icon: <UserIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
          navigate('/unauthorized');
      }

        loadUser(id!);
    }, [id, loadUser])

    

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };

    return(
        <PageContainer pagetitle={peUser?.userName} breadcrumbs={breadcrumbs}>
            <Grid>
                <Grid item>
                    <Tabs
                        value={value} 
                        onChange={handleChange} 
                        aria-label="User menu"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Profile" {...a11yProps(0)} />
                        <Tab label="Theme" {...a11yProps(1)} />
                        <Tab label="Reduction Matrix" {...a11yProps(2)} />
                        <Tab label="Activity Log" {...a11yProps(3)} />
                        <Tab label="Resources" {...a11yProps(4)} />
                    </Tabs>
                </Grid>
                <Grid item>
                    <TabPanel value={value} index={0}>
                        <PageHeader pagetext="Profile information." />
                        { peUser && 
                          <Formik
                            initialValues={{id: peUser.id, emailAddress: peUser.emailAddress, userName: peUser.userName, isLocked: peUser.isLocked, theme: peUser.theme, reductionMatrix: peUser.reductionMatrix, registrationComplete: peUser.registrationComplete, error: null}}
                            onSubmit={(values, { setErrors }) => updateUser(values).catch((error: any) => setErrors({error: error}))}
                          >
                            {({handleSubmit, isSubmitting, isValid, dirty, errors, values, setFieldValue}) => (
                              <Form className="form" onSubmit={handleSubmit} autoComplete="off">
                                <MyTextInput name="userName" label="Username" help={errors.userName} placeholder={""} />
                                <MyTextInput name="emailAddress" label="Email address" help={errors.emailAddress} placeholder={""} />
                                <MySelectInput name="theme" label="Theme" options={themeOptionList} />
                                <MySelectInput name="reductionMatrix" label="Reduction Matrix" options={reductionMatrixOptionList} />
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Switch 
                                        name="isLocked"
                                        value="isLocked"
                                        checked={values.isLocked}
                                        onChange={(event, lockedChecked) => {
                                          setFieldValue("isLocked", lockedChecked);
                                        }}
                                      />
                                    }
                                    label="Locked"
                                  />
                                </FormControl>
                                <br />
                                <FormControl>
                                  <FormControlLabel
                                    control={
                                      <Switch 
                                        name="registrationComplete"
                                        value="registrationComplete"
                                        checked={values.registrationComplete}
                                        onChange={(event, regCompleteChecked) => {
                                          setFieldValue("registrationComplete", regCompleteChecked);
                                        }}
                                      />
                                    }
                                    label="Registration Complete"
                                  />
                                </FormControl>
                                <br />
                                <ErrorMessage
                                  name="error"
                                  render={() => (<Alert severity="error">{errors.error}</Alert>)}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={!isValid || !dirty || isSubmitting}
                                >
                                  Update
                                </Button>
                              </Form>
                            )}
                          </Formik>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PageHeader pagetext="Edit theme settings." />
                        { peUser &&
                          <ThemeToggler theme={peUser?.theme!} userId={peUser?.id!} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PageHeader pagetext="Edit Reduction Matrix settings." />
                        { peUser && 
                          <ReductionMatrixToggler redMat={peUser?.reductionMatrix!} userId={peUser?.id!} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <PageHeader pagetext="Review account activity" />
                        { peUser &&
                          <ActivityLog userId={peUser?.id} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <PageHeader pagetext="Resources" />
                        <MyResourcesComponent userId={peUser?.id!} />
                    </TabPanel>
                </Grid>
            </Grid>
        </PageContainer>
    )
})