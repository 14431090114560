import { Box, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

import { SortingType, StyledTableCell } from "../../../app/utils/globalConstants";
import { IColumnType } from "./CustomTable";

interface Props<T> {
    column: IColumnType<T>;
    sortColumn: string;
    sortOrder: SortingType;
    onSortChange: (column: string, sortOrder: SortingType) => void;
}

export function TableHeaderCell<T>({ column, sortColumn, sortOrder, onSortChange }: Props<T>): JSX.Element {

    const handleChangeSort = (
        column: string
    ) => {

        if (column !== sortColumn) {
            onSortChange(column, SortingType.asc);
        } else {
            if (sortOrder === SortingType.asc) {
                onSortChange(column, SortingType.desc);                
            } else {
                onSortChange(column, SortingType.asc);
            }
        }
    }

    return (
        <StyledTableCell>
            {column.isSortable ? 
                <TableSortLabel
                    active={sortColumn === column.sortLabel}
                    direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                    onClick={ () => handleChangeSort(column.sortLabel)}
                >
                    {column.title}
                    {sortColumn === column.sortLabel ? (
                        <Box 
                            component="span" 
                            sx={visuallyHidden}
                        >
                            {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                    ) : null }
                </TableSortLabel> : column.title}
        </StyledTableCell>
    )
}