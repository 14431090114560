import { Avatar, Button, Card, CardActions, CardContent, ListItemAvatar, ListItemText, Typography, Link } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { ArticleListItem } from "../../../app/models/Blog/Article";
import UserIcon from "@mui/icons-material/Person"
import Moment from "react-moment";
import { Link as RouterLink } from "react-router-dom";

interface Props {
    article: ArticleListItem;
}

export default function ArticleSummaryComponent({article} : Props) {
    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h5">
                    <Link to={`/article/${article.slug}`} component={RouterLink} color="inherit" underline="hover">
                        {article.title}
                    </Link>
                </Typography>
                <ReactMarkdown>
                    {article.summary}
                </ReactMarkdown>
            </CardContent>
            <CardActions>
                <ListItemAvatar>
                    <Avatar>
                        <UserIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={article.authorName} secondary={<Moment format="DD MMM YYYY">{article.publishedDate!}</Moment>} />
                <Button size="small" color="inherit" component={RouterLink} to={`/article/${article.slug}`}>Learn More</Button>
            </CardActions>
        </Card>
    )
}