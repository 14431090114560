import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ThemeForm } from "../../../app/models/Users/currentUser";
import { useStore } from "../../../app/stores/store";
import { themeOptionList } from "../../../app/utils/globalConstants";

interface Props {
    theme: string;
    userId: string
}

export default observer(function ThemeToggler({theme, userId} : Props) {
    const { userAdminStore } = useStore();
    const { updateTheme } = userAdminStore;
    const [selected, setTheme] = useState(theme);

    const handleChange = (event: SelectChangeEvent) => {
        const form : ThemeForm = { theme: event.target.value as string, userId: userId }
        updateTheme(form);
        setTheme(event.target.value);
    }

    return (
        <FormControl fullWidth>
            <Select
                id="theme-select"
                value={selected}
                onChange={handleChange}
            >
                {themeOptionList.map(map => 
                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                )} 
            </Select>
        </FormControl>
    )
})