import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { ResourceItem, ResourceListItem } from "../../models/Resources/Resource";

export default class ResourceStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "name");
    resourceItems: ResourceListItem[] = [];
    resourceItem: ResourceItem | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadResourceList = async (type: number = 0, name: string, lootType: number) => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (type !== 0) {
                axiosParams.append('type', type.toString());
            }

            if (name !== "") {
                axiosParams.append('name', name);
            }

            if (lootType !== 0) {
                axiosParams.append('lootType', lootType.toString());
            }

            const result = await agent.ResourcesApi.getList(axiosParams);

            runInAction(() => {
                this.resourceItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadResource = async (slug: string) => {
        this.loading = true;

        try {
            const resource = await agent.ResourcesApi.get(slug);

            runInAction(() => {
                this.resourceItem = resource;
                this.loading = false;
                
            });
            
            return resource;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    uploadResources = async(form: FormData) => {
        try {
            console.log("form", form);

            await agent.ResourcesApi.process(form);
        } catch(error) {
            throw error;
        }
    }

}