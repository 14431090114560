import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { LocationLiteItem } from "../../models/Locations/location";
import { PagingParams } from "../../models/pagination";


export default class UserLiteStore {
    loadingGalaxies = false;
    totalLocationLiteCount: number = 0;
    pagingParamsLocationLite = new PagingParams(-1, 0, "name");
    locationLiteItems: LocationLiteItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsLocationLite = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalLocationLiteCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsLocationLite.take.toString());
        params.append('skip', this.pagingParamsLocationLite.skip.toString());
        params.append('sort', this.pagingParamsLocationLite.sort);

        return params;
    }

    loadLocationLiteList = async (galaxyId: number = 0, solarSystemId: number = 0, owner: string = '', resourceLocations: boolean = false) => {
        this.loadingGalaxies = true;

        try {
            var axiosParams = this.axiosParams;

            if (galaxyId !== 0) {
                axiosParams.append('galaxyId', galaxyId.toString());
            }

            if (solarSystemId !== 0) {
                axiosParams.append('solarSystemId', solarSystemId.toString());
            }

            if(resourceLocations) {
                axiosParams.append('resourceLocations', 'true');
            }

            if (owner !== '') {
                axiosParams.append('owner', owner);
            }

            const result = await agent.LocationApi.getLite(axiosParams);

            runInAction(() => {
                this.locationLiteItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingGalaxies = false;
            })
        } catch(error) {
            runInAction(() => this.loadingGalaxies = false);
            throw error;
        }
    }
}