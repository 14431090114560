import { observer } from "mobx-react-lite";
import {  Box, Grid, Table, TableBody, TableContainer, TableHead, TablePagination, TableSortLabel, Typography, Link as MuiLink, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { SchematicItem } from "../../../app/models/Schematics/Schematic";
import { useState } from "react";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import { visuallyHidden } from '@mui/utils';
import * as _ from "lodash";

interface Props {
    schematics : SchematicItem[]
}

export default observer(function SchematicModules({ schematics }: Props) {
    const [sortColumn, setSortColumn] = useState("order");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTake(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
            } else {
                setSortOrder(SortingType.asc);
            }
            setSortColumn(column);
        }
    }
    
    return (
        <Grid container>
            <Grid item md={12} sm={12}>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="sold at table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "order"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("order")}
                                    >
                                        Craft Order
                                        {sortColumn === "order" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Schematics
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "quantity"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("quantity")}
                                    >
                                        Quantity
                                        {sortColumn === "quantity" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "category"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("category")}
                                    >
                                        Category
                                        {sortColumn === "category" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "store"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("store")}
                                    >
                                        Store
                                        {sortColumn === "store" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "cost"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("cost")}
                                    >
                                        Cost
                                        {sortColumn === "cost" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "prod"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("prod")}
                                    >
                                        Prod
                                        {sortColumn === "prod" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "manu"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("manu")}
                                    >
                                        Manu
                                        {sortColumn === "manu" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "Tech"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("Tech")}
                                    >
                                        Tech
                                        {sortColumn === "Tech" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {schematics && schematics.length > 0 ? _.orderBy(schematics, [sortColumn], [sortOrder == SortingType.asc ? 'asc' : 'desc']).slice(page * take, page * take + take).map((item: SchematicItem) => (
                                    <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                        <StyledTableCell>
                                            {item.order}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <MuiLink
                                                component={Link}
                                                to={`/schematics/${item.slug}`}
                                                color="inherit"
                                            >
                                                {item.name}
                                            </MuiLink>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.category}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.store}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.isCamp && <> CAMP: {item.camp} |</>} {item.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {item.currency} 
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.prod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.manu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item.tech.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : 
                                <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell colSpan={9}>
                                        <Typography variant="body2" component="div">No Schematics Found</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={schematics?.length || 0}
                        page={page}
                        rowsPerPage={take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </Grid>
        </Grid>
    )    
})
