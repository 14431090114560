import { Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
    url: string;
    name: string;
}

export default function TableLink({ url, name }: Props) {
    return (
        <MuiLink
            component={Link}
            to={url}
            color="inherit"
        >
            {name}
        </MuiLink>
    )
}