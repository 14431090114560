import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";

import { useStore } from "../../../app/stores/store";
import { ReductionMatrixForm } from "../../../app/models/Users/currentUser";
import { reductionMatrixOptionList } from "../../../app/utils/globalConstants";

interface Props {
    reductionMatrixId: string;
    setReductionMatrixId: (reductionmatrixId: string) => void;
}

export default observer(function ReductionMatrixFilter({ reductionMatrixId, setReductionMatrixId }: Props) {
    const { isAuthenticated, user } = useAuth0();
    const { currentUserStore } = useStore();
    const { updateReductionMatrix } = currentUserStore;

    const handleChangeRedMat = (event: SelectChangeEvent) => {
        if (isAuthenticated) {
            const form : ReductionMatrixForm = { reductionMatrix: event.target.value as string, userId: user?.id }
            updateReductionMatrix(form)
        }
        setReductionMatrixId(event.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="reductionMatrix-select">Reduction Matrix</InputLabel>
            <Select
                id="reductionMatrix-select"
                value={reductionMatrixId}
                onChange={handleChangeRedMat}
                label="Reduction Matrix"
                sx={{minWidth: "250px"}}
            >
                {reductionMatrixOptionList.map(map => 
                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                )}   
            </Select>
        </FormControl>
    )
})