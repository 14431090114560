import { observer } from "mobx-react-lite"
import SchematicListUploadResourcesDetail from "../../Crafting/Components/SchematicListUploadResourcesDetail"
import { useEffect, useState } from "react"
import { useStore } from "../../../app/stores/store"
import { Grid, TextField } from "@mui/material"
import ResourceGroupByFilter from "../../common/filters/ResourceGroupByFilter"
import ResourceLocationFilter from "../../common/filters/ResourceLocationFilter"
import ResourceTypeFilter from "../../common/filters/ResourceTypeFilter"
import { SortingType, pageRows } from "../../../app/utils/globalConstants"
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable"
import { MyResourceItem } from "../../../app/models/Resources/Resource"
import TableLink from "../../common/tables/TableLink"
import MyGlobalResourceStats from "./MyGlobalResourceStats"
import MyFilteredResourceStats from "./MyFilteredResourceStats"
import LootTypeFilter from "../../common/filters/LootTypeFilter"

interface Props {
    userId: string
}

export default observer(function MyResourcesPage({ userId } : Props) {
    const { myResourcesStore } = useStore();
    const { loading, pagingParams, totalCount, myResources, loadMyResources } = myResourcesStore;
    const [resourceGroupById, setResourceGroupById] = useState('none');
    const [locationId, setLocationId] = useState(0);
    const [resourceTypeId, setResourceTypeId] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const [lootType, setLootType] = useState(0);

    useEffect(() => {
        handleLoad();
     }, [resourceTypeId, locationId, resourceGroupById, userId, page, sortColumn, sortOrder, rowsPerPage, lootType])

     const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            pagingParams.skip = 0;
            handleLoad();
        }
    }

    const handleLoad = () => {
        loadMyResources(resourceTypeId, locationId, searchString, resourceGroupById, userId, lootType);
    }

    const handlePageChange = (page:number) => {
        pagingParams.skip = page * pagingParams.take;
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        pagingParams.take = rows;
        pagingParams.skip = 0;

        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        if (sortOrder === SortingType.asc) {
            pagingParams.sort = column;
        } else {
            pagingParams.sort = `-${column}`;
        }

        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: totalCount,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const displayType = (type: number) => {
        switch(type)
        {
            case 1:
                return ("Resource");
            case 2:
                return ("Commodity");
            case 3:
                return ("Expanse");    
            case 4:
                return ("Alien");
            case 5:
                return ("Settlement");
            case 6:
                return ("Nebula");
            case 7:
                return ("Special Resource");
            default:
                return ("None");
        }
    }

    const columns: IColumnType<MyResourceItem>[] = [
        {
            key: "resourceName",
            title: "Resource",
            sortLabel: "name",
            isSortable: true,
            render: (_, { resourceSlug, resourceName}) => (
                <TableLink url={`/resources/${resourceSlug}`} name={resourceName} />
            )
        },
        {
            key: "resourceType",
            title: "Type",
            sortLabel: "type",
            isSortable: true,
            render: (_, { resourceType}) => (
                <>{displayType(resourceType)}</>
            )
        },
        {
            key: "locationName",
            title: "Location",
            sortLabel: "location",
            isSortable: true
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "cpu",
            title: "CPU",
            sortLabel: "cpu",
            isSortable: true,
            align: "right",
            render: (_, { cpu }) => (
                <>{cpu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
        {
            key: "avgCPU",
            title: "GBM CPU",
            sortLabel: "avgCPU",
            isSortable: true,
            align: "right",
            render: (_, { avgCPU }) => (
                <>{avgCPU.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
        {
            key: "total",
            title: "Total",
            sortLabel: "total",
            isSortable: false,
            align: "right",
            render: (_, { quantity, cpu }) => (
                <>{(quantity * cpu).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
        {
            key: "avgTotal",
            title: "Avg GBM Total",
            sortLabel: "avgTotal",
            isSortable: false,
            align: "right",
            render: (_, { quantity, avgCPU }) => (
                <>{(quantity * avgCPU).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
    ]

    const isFiltered = () => {
        if (locationId !== 0 || resourceTypeId !== 0 || searchString !== '') {
            return true;
        }

        return false;
    }

    const handleChangeGroup = (resourceGroupById: string) => {
        pagingParams.skip = 0;
        setPage(0);

        if (resourceGroupById !== 'none') {
            setLocationId(0);
        }

        setResourceGroupById(resourceGroupById);
    }

    const handleChangeType = (resourceTypeId: number) => {
        pagingParams.skip = 0;
        setPage(0);
        setResourceTypeId(resourceTypeId);
    }

    const handleChangeLocation = (locationId: number) => {
        pagingParams.skip = 0;
        setPage(0);

        if (locationId !== 0) {
            setResourceGroupById('none');
        }

        setLocationId(locationId);
    }

    return (
        <>
            <SchematicListUploadResourcesDetail handleOnUpload={handleLoad} />
            <Grid container>
                <Grid>
                    <MyGlobalResourceStats userId={userId} updating={loading} />
                </Grid>
                {
                    isFiltered() &&
                    <Grid>
                        <MyFilteredResourceStats userId={userId} locationId={locationId} type={resourceTypeId} name={searchString} updating={loading} />
                    </Grid>
                }
                
            </Grid>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <ResourceGroupByFilter resourceGroupById={resourceGroupById} setResourceGroupById={handleChangeGroup} />
                </Grid>
                <Grid item>
                    <ResourceLocationFilter setLocationId={handleChangeLocation} locationId={locationId} />
                </Grid>
                <Grid item>
                    <ResourceTypeFilter resourceTypeId={resourceTypeId} setResourceTypeId={handleChangeType} />
                </Grid>
                <Grid item>
                    <LootTypeFilter lootTypeId={lootType} setLootTypeId={setLootType} />
                </Grid>
                <Grid item>
                    <TextField id="name" label="Search" variant="outlined" onChange={event => setSearchString(event.target.value)} onKeyDown={handleCompleteSearch} value={searchString}  />
                </Grid>
            </Grid>
            <CustomTable
                    data={myResources}
                    columns={columns}
                    pagination={pagination}
                    tableName="My Resources"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Resources found"
                />
        </>
    )
})