import { Alert, Button } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from 'yup';

import HomeIcon from "@mui/icons-material/Home";
import ProfileIcon from '@mui/icons-material/Person';

import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";
import { useStore } from "../../app/stores/store";
import MyTextInput from "../../app/common/form/MyTextInput";
import { history } from "../..";
import { useNavigate } from "react-router-dom";

export default function CompleteRegistration() {
    const { registrationStore, currentUserStore } = useStore();
    const { completeRegistration } = registrationStore;
    const { login, currentUser } = currentUserStore;
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        userName: Yup.string().required().max(256)
    });

    const breadCrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Complete registration", "pageurl": "/completeregistration", "active": false, icon: <ProfileIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    return (
        <PageContainer 
            pagetitle="Complete registration" 
            breadcrumbs={breadCrumbs} 
            pagetext="Your account has been created, however, if you like to change your username to something more relevant, you can do so here. ">

            <Formik
                initialValues={{userId: currentUser?.id!, userName: currentUser?.userName!, error: null}}
                validationSchema={validationSchema}
                onSubmit={(values, {setErrors}) => completeRegistration(values).catch((error: any) => setErrors({error: error})).then(() => login()).finally(() => navigate('/'))}
            >
                {({handleSubmit, isSubmitting, isValid, dirty, errors}) => (
                    <Form className="form" onSubmit={handleSubmit} autoComplete="off">
                        <MyTextInput name="userName" placeholder="User Name" label="User Name" help={errors.userName} />
                        <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isValid || !dirty || isSubmitting}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </PageContainer>
        
    )
}