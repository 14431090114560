import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    certName: string;
    certLevel: number;
    setCertLevel: (resourcetypeId: number) => void;
}

export default observer(function FactoryCertSelect({ certName, certLevel, setCertLevel }: Props) {

    const handleChangeType = (event: SelectChangeEvent) => {
        setCertLevel(parseInt(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id='{certName}-select'>{certName}</InputLabel>
            <Select
                id={`${certName}-select`}
                value={certLevel.toString()}
                onChange={handleChangeType}
                label={certName}
                sx={{minWidth: "250px"}}
            >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
            </Select>
        </FormControl>
    )
})