import { observer } from "mobx-react-lite";
import {  Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as _ from "lodash";

import { SchematicResource } from "../../../app/models/Schematics/Schematic";
import { pageRows, SortingType } from "../../../app/utils/globalConstants";
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable";
import TableLink from "../../common/tables/TableLink";

interface Props {
    resources: SchematicResource[];
    loading: boolean;
}

export default observer(function SchematicResourceDetail({ resources, loading }: Props) {
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const { isAuthenticated } = useAuth0();

    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: resources?.length ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<SchematicResource>[] = isAuthenticated ? [
        {
            key: "name",
            title: "Resource",
            sortLabel: "name",
            isSortable: true,
            render: (_, { name, slug}) => (
                <TableLink url={`/resources/${slug}`} name={name} />
            )
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "cost",
            title: "Cost",
            sortLabel: "cost",
            isSortable: true,
            align: "right",
            render: (_, { cost}) => (
                <>{cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
        {
            key: "myQty",
            title: "Quantity Have",
            sortLabel: "myQty",
            isSortable: true,
            align: "right",
            render: (_, { myQty}) => (
                <>{myQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "missingQty",
            title: "Quantity Need",
            sortLabel: "missingQty",
            isSortable: true,
            align: "right",
            render: (_, { missingQty}) => (
                <Typography component="span" variant="body2" color={missingQty > 0 ? "error.main" : "success.main"}>
                    {missingQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
            )
        },
    ]
    : [
        {
            key: "name",
            title: "Resource",
            sortLabel: "name",
            isSortable: true,
            render: (_, { name, slug}) => (
                <TableLink url={`/resources/${slug}`} name={name} />
            )
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "cost",
            title: "Cost",
            sortLabel: "cost",
            isSortable: true,
            align: "right",
            render: (_, { cost}) => (
                <>{cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
    ]
    
    return (
        <Grid container>
            <Grid item md={12} sm={12}>
            <CustomTable
                    data={_.orderBy(resources, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    pagination={pagination}
                    tableName="Schematic Resources"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Schematic Resources found"
                />
            </Grid>
        </Grid>
    )    
})
