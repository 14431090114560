import { makeAutoObservable, reaction, runInAction } from "mobx";
import { FoundryCalculationResult, FoundryRequest, FoundryRequestItem } from "../../models/Foundry/Foundry";
import agent from "../../api/agent";

export default class FoundryCalculationStore {
    loading = false;
    foundryCalculations: FoundryCalculationResult | null = null;
    foundryRequestList: FoundryRequestItem[] = JSON.parse(localStorage.getItem('foundryList') || '[]');

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.foundryRequestList,
            foundryRequestList => {
                if (foundryRequestList) {
                    localStorage.setItem('foundryList', JSON.stringify(foundryRequestList));
                } else {
                    localStorage.setItem('foundryList', JSON.stringify([]));
                }
            }
        )
    }

    savePatterns = (request: FoundryRequestItem[]) => {
        this.foundryRequestList = request;

        this.loadPatternList(new FoundryRequest({
            foundryCube: "",
            patterns: request
        }));

    }

    resetPatterns = () => {
        this.foundryRequestList = [];
        this.foundryCalculations = null;
    }

    loadPatternList = async (form: FoundryRequest) => {
        this.loading = true;

        try {
            const result = await agent.FoundryApi.getFoundryCalculation(form);

            runInAction(() => {
                this.foundryCalculations = result;
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}