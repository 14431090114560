import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
  } from '@mui/material/AccordionSummary';
  import MuiAccordionDetails from '@mui/material/AccordionDetails';

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { observer } from "mobx-react-lite";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { FaqItem } from "../../app/models/Blog/Faq";
import ReactMarkdown from "react-markdown";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
    ))(({ theme }) => ({
        backgroundColor:
          theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
          marginLeft: theme.spacing(1),
        },
      }));
      
      const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
      }));

export default observer(function FaqPage() {
    const { faqStore } = useStore();
    const { loading, loadFaqList, faqList } = faqStore;

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Faq", "pageurl": "/faq", "active": true, icon: <QuestionAnswerIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    const [expanded, setExpanded] = useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
        };

    useEffect(() => {
        loadFaqList();
    }, [loadFaqList])

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle="Frequently Asked Questions" breadcrumbs={breadcrumbs}>
            { faqList && 
                <>
                    {faqList.map((item: FaqItem) => (
                        <Accordion key={item.id} expanded={expanded === `${item.id}`} onChange={handleChange(`${item.id}`)}>
                            <AccordionSummary aria-controls={`${item.id}-contents`} id={`${item.id}-header`}>
                                <Typography>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReactMarkdown>
                                    {item.answer}
                                </ReactMarkdown>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            }
        </PageContainer>
        
    )
})