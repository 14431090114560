import { observer } from "mobx-react-lite";
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { useStore } from "../../../app/stores/store";
import { FoundryCubeForm, ReductionMatrixForm } from "../../../app/models/Users/currentUser";
import { foundryCubeOptions, reductionMatrixOptionList } from "../../../app/utils/globalConstants";

export default observer(function ReductionMatrixTab() {
    const { currentUserStore, commonStore } = useStore();
    const { currentUser, updateReductionMatrix, updateFoundryCube } = currentUserStore;
    const { redmat, foundry } = commonStore;

    const handleChange = (event: SelectChangeEvent) => {
        const form : ReductionMatrixForm = { reductionMatrix: event.target.value, userId: currentUser?.id! };

        updateReductionMatrix(form);
    }

    const handleFoundryChange = (event: SelectChangeEvent) => {
        const form : FoundryCubeForm = { foundryCube: event.target.value, userId: currentUser?.id! };

        updateFoundryCube(form);
    }

    return (
        <>
            <Grid container>
                <Grid item>
                    <FormControl fullWidth>
                        <Select
                            id="redMat-select"
                            value={redmat!}
                            onChange={handleChange}
                        >
                            {reductionMatrixOptionList.map(map => 
                                <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                            )}  
                        </Select>
                    </FormControl>
                </Grid>
                
            </Grid>
            <Grid container>
                <Grid item>
                <FormControl fullWidth>
                        <Select
                            id="foundry-select"
                            value={foundry!}
                            onChange={handleFoundryChange}
                        >
                            {foundryCubeOptions.map(map => 
                                <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                            )}  
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )    
})
