import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { GalaxyItem } from "../../models/Locations/galaxy";
import { PagingParams } from "../../models/pagination";


export default class UserLiteStore {
    loadingGalaxies = false;
    totalGalaxyCount: number = 0;
    pagingParamsGalaxy = new PagingParams(10, 0, "name");
    galaxyItems: GalaxyItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsGalaxy = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalGalaxyCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsGalaxy.take.toString());
        params.append('skip', this.pagingParamsGalaxy.skip.toString());
        params.append('sort', this.pagingParamsGalaxy.sort);

        return params;
    }

    loadGalaxyList = async () => {
        this.loadingGalaxies = true;

        try {
            const result = await agent.GalaxyApi.getList(this.axiosParams);

            runInAction(() => {
                this.galaxyItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingGalaxies = false;
            })
        } catch(error) {
            runInAction(() => this.loadingGalaxies = false);
            throw error;
        }
    }
}