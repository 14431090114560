import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { RefineryRequest, RefineryRequestItem, RefineryResponse } from "../../models/Factory/Refinery";

export default class RefineryStore {
    loading = false;
    locationId: number = JSON.parse(localStorage.getItem('locationId') as string) || 0;
    archimedes: boolean = JSON.parse(localStorage.getItem('archimedes') as string) || false;
    requestList: RefineryRequestItem[] | null = JSON.parse(localStorage.getItem('refineryList') || '[]');
    response: RefineryResponse | null = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.locationId,
            locationId => {
                if(locationId) {
                    localStorage.setItem('locationId', locationId.toString());
                } else {
                    localStorage.setItem('locationId', "0");
                }
            }
        )

        reaction(
            () => this.archimedes,
            archimedes => {
                if(archimedes) {
                    localStorage.setItem('archimedes', archimedes.toString());
                } else {
                    localStorage.setItem('archimedes', "false");
                }
            }
        )

        reaction(
            () => this.requestList,
            requestList => {
                if(requestList) {
                    localStorage.setItem('refineryList', JSON.stringify(requestList));
                } else {
                    localStorage.setItem('refineryList', JSON.stringify([]));
                }
            }
        )
    }

    setLocationId = (locationId: number) => {
        this.locationId = locationId;
    }

    setArchimedes = (archimedes: boolean) => {
        this.archimedes = archimedes;
    }

    addUpdate = (smr: RefineryRequestItem) => {
        const smrs :  RefineryRequestItem[] = JSON.parse(localStorage.getItem('refineryList') || '[]');

        if (smr.facilityId !== 0) {
            const existing = smrs.find(e => e.facilityId === smr.facilityId);

            if (existing !== undefined) {
                const index = smrs?.indexOf(existing);
                smrs.splice(index, 1); // Remove exisitng record
            }

            smrs.push(smr);            
        
            this.requestList = smrs;
        }
    }

    deleteItem = (id: number) => {
        const smrs :  RefineryRequestItem[] = JSON.parse(localStorage.getItem('refineryList') || '[]');

        this.requestList = smrs.filter((smr) => smr.facilityId !== id);
    }

    resetList = () => {
        this.requestList = [];
    }
    
    loadList = async () => {
        this.loading = true;

        try {
            const requestDataList :  RefineryRequestItem[] = JSON.parse(localStorage.getItem('refineryList') || '[]');

            const data = await agent.FactoryApi.getRefinery(new RefineryRequest({
                locationId: this.locationId,
                facilities: requestDataList,
                archimedes: this.archimedes
            }));

            runInAction(() => {
                this.response = data;
                this.loading = false;
            })

        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}