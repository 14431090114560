import { Grid, Tabs, Tab, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import PageHeader from "../../app/common/page/PageHeader";
import BlueprintIcon from "@mui/icons-material/Business";
import BlueprintResources from "./components/BlueprintResources";
import BlueprintModules from "./components/BlueprintModules";
import BlueprintParents from "./components/BlueprintParents";
import BlueprintItems from "./components/BlueprintItems";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default observer(function BlueprintsDetailPage() {
    const { blueprintStore } = useStore();
    const { loading, loadBlueprint, blueprintItem } = blueprintStore;
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState(0);

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Blueprints", "pageurl": "/blueprints", "active": false, icon: <BlueprintIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": `${blueprintItem?.blueprints[0].name}`, "pageurl": `/blueprints/${id}`, "active": true, icon: <BlueprintIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
        loadBlueprint(id!);
    }, [loadBlueprint, id])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle={blueprintItem?.blueprints[0].name!} breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Category: ${blueprintItem?.blueprints[0]?.category}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Blueprint XP: ${blueprintItem?.blueprints[0]?.xp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total XP: ${blueprintItem?.totalXp?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total Blueprint Cost: ${blueprintItem?.blueprintCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total Resource Cost: ${blueprintItem?.resourcesCost?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`} />
                        </ListItem>
                    </List>
                </Grid>
                {
                    blueprintItem?.blueprints[0]?.store && 
                    <Grid item md={4}>
                        <List>
                            <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                                <ListItemText primary={`Blueprint Store: ${blueprintItem?.blueprints[0]?.store}`} />
                            </ListItem>
                            <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                                <ListItemText primary={`Blueprint Cost: ${blueprintItem?.blueprints[0]?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${blueprintItem?.blueprints[0]?.currency}`} />
                            </ListItem>
                        </List>
                    </Grid>
                }
                
            </Grid>
            <Grid item md={12} sm={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="blueprints menu"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tab label="Resources" {...a11yProps(0)} />
                    <Tab label="Items" {...a11yProps(1)} />
                    <Tab label="Modules" {...a11yProps(2)} />
                    <Tab label="Blueprints Used In" {...a11yProps(3)} />
                </Tabs>
            </Grid>
            <Grid item md={12}>
                <TabPanel value={value} index={0}>
                    <PageHeader pagetitle="Resources" />
                    <BlueprintResources resources={blueprintItem?.resources!} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PageHeader pagetitle="Items" />
                    <BlueprintItems items={blueprintItem?.blueprints!} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <PageHeader pagetitle="Modules" />
                    <BlueprintModules blueprints={blueprintItem?.blueprints!} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <PageHeader pagetitle="Blueprints Used In" />
                    <BlueprintParents id={id!} />
                </TabPanel>
            </Grid>
        </PageContainer>
    )
})