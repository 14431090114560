import { FactoryCalcType } from "../../utils/globalConstants";

export class advRefineryRequest {
    facilities: AdvRefineryRequestItem[] = [];
    locationId: number = 0;
    industryCertLevel: number = 0;
    metallurgyCertLevel: number = 0;
    refiningCertLevel: number = 0;

    constructor(form?: advRefineryRequest) {
        if (form) {
            this.facilities = form.facilities;
            this.locationId = form.locationId;
            this.industryCertLevel = form.industryCertLevel;
            this.metallurgyCertLevel = form.metallurgyCertLevel;
            this.refiningCertLevel = form.refiningCertLevel;
        }
    }
}

export class AdvRefineryRequestItem {
    facilityId: number = 0;
    quantityFacilities: number = 0;
    productionQuantity: number = 0;
    calcType: FactoryCalcType = 1;

    constructor(form?: AdvRefineryRequestItem) {
        if (form){
            this.facilityId = form.facilityId;
            this.quantityFacilities = form.quantityFacilities;
            this.productionQuantity = form.productionQuantity;
            this.calcType = form.calcType;
        }
    }
}


export interface AdvRefineryResponse {
    facilities: AdvFacilityResponse[];
    production: AdvProductionResponse[];
    requirements: AdvRequirementResponse[];
}

export interface AdvFacilityResponse {
    facilityId: number;
    facility: string;
    quantityFacilities: number;
    productionQuantity: number;
    productionType: FactoryCalcType;
}

export interface AdvProductionResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    duration: number;
    productionType: FactoryCalcType;
}

export interface AdvRequirementResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}