import { Outlet } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import LoadingComponent from "../common/page/LoadingComponent"

export function ProtectedRoutes() {

  const Component = withAuthenticationRequired(Outlet, {
    onRedirecting: () => (
        <LoadingComponent />
    ),
  });

  return <Component />;
}
