import React, { useEffect } from "react";
import { Menu, Button, MenuItem, Divider, Avatar,  CardHeader,  ListItemIcon } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ProfileIcon from '@mui/icons-material/Person';
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";


export default observer(function LoginMenu() {
    const { currentUserStore: { currentUser, login, logoutUser }} = useStore();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { loginWithRedirect, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

      useEffect(() => {
        if (isAuthenticated) {
          const requestInterceptorId = axios.interceptors.request.use(async config => {

            const token = await getAccessTokenSilently({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
              }
            });

            delete axios.defaults.headers.common["Authorization"];
        
            config.headers = config.headers ?? {};
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
          });
        } else {
          delete axios.defaults.headers.common["Authorization"];
        }
      })
      
      useEffect(() => {
        if (isAuthenticated) {
          login();
        }
      }, [isAuthenticated, getAccessTokenSilently])

      useEffect(() => {
        if (currentUser?.registrationComplete === false) {
          navigate('/completeregistration');
        }
      }, [currentUser])

      const handleLogin = async () => {
        await loginWithRedirect();
      }

      const handleLogout = async() => {
        logoutUser();
        logout({
            logoutParams: {
              returnTo: window.location.origin
            }
        });
      }

    return (
        <>
            {isAuthenticated ? (
                <>
                    <Button startIcon={<ProfileIcon />} color="inherit" aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick} sx={{marginLeft: "15px"}}>
                        {currentUser?.userName}
                    </Button>
                    <Menu
                        id="profile-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                              },
                            },
                          }}
                          
                    >
                        <CardHeader avatar={
                            <Avatar sx={{ width: 24, height: 24 }}>
                                <ProfileIcon />
                            </Avatar>
                            } 
                            title={currentUser?.userName}
                            sx={{ marginLeft: "-5px", marginRight: "5px"}}
                        />
                        <Divider />
                        <MenuItem component={Link} to="/profile" onClick={handleClose}>
                            <ListItemIcon>
                                <ProfileIcon /> 
                            </ListItemIcon>
                            Profile
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                            <LogoutIcon /> 
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <Button color="inherit" startIcon={<LoginIcon />} onClick={handleLogin} sx={{marginLeft: "15px"}}>Login</Button>
            )}
        </>
    );
})