import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useStore } from "../../../app/stores/store"
import { Grid, TextField } from "@mui/material"
import { SortingType, pageRows } from "../../../app/utils/globalConstants"
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable"
import TableLink from "../../common/tables/TableLink"
import SchematicListUploadModuleDetail from "../../Crafting/Components/SchematicListUploadModuleDetail"
import CategoryFilter from "../../common/filters/CategoryFilter"
import { MyModuleItem } from "../../../app/models/Schematics/Schematic"

interface Props {
    userId: string
}

export default observer(function MyModulesPage({ userId } : Props) {
    const { mySchematicsStore } = useStore();
    const { loading, pagingParams, totalCount, myModules, loadMyModules } = mySchematicsStore;
    const [searchString, setSearchString] = useState('');
    const [sortColumn, setSortColumn] = useState("name");
    const [categoryId, setCategoryId] = useState(0);
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);

    useEffect(() => {
        handleLoad();
     }, [userId, categoryId, page, sortColumn, sortOrder, rowsPerPage])

     const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            pagingParams.skip = 0;
            handleLoad();
        }
    }

    const handleLoad = () => {
        loadMyModules(searchString, categoryId, userId);
    }

    const handleSetCategory = (categoryId: number) => {
        setCategoryId(categoryId);
    }

    const handlePageChange = (page:number) => {
        pagingParams.skip = page * pagingParams.take;
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        pagingParams.take = rows;
        pagingParams.skip = 0;

        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        if (sortOrder === SortingType.asc) {
            pagingParams.sort = column;
        } else {
            pagingParams.sort = `-${column}`;
        }

        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: totalCount,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<MyModuleItem>[] = [
        {
            key: "schematicName",
            title: "Module",
            sortLabel: "name",
            isSortable: true,
            render: (_, { schematicSlug, schematicName}) => (
                <TableLink url={`/schematics/${schematicSlug}`} name={schematicName} />
            )
        },
        {
            key: "category",
            title: "Category",
            sortLabel: "category",
            isSortable: true
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
    ]

    return (
        <>
            <SchematicListUploadModuleDetail handleOnUpload={handleLoad} />
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <CategoryFilter setCategoryId={handleSetCategory} />
                </Grid>
                <Grid item>
                    <TextField id="name" label="Search" variant="outlined" onChange={event => setSearchString(event.target.value)} onKeyDown={handleCompleteSearch} value={searchString}  />
                </Grid>
            </Grid>
            <CustomTable
                    data={myModules}
                    columns={columns}
                    pagination={pagination}
                    tableName="My Modules"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Modules found"
                />
        </>
    )
})