import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

import { useStore } from "../../app/stores/store";
import { Breadcrumb } from "../../app/models/common";
import { ResourceGbmItem } from "../../app/models/Resources/Resource";

import PageContainer from "../../app/common/page/PageContainer";
import ResourceFilter from "../common/filters/ResourceFilter";
import { pageRows, SortingType } from "../../app/utils/globalConstants";
import { CustomTable, IColumnType, IPagination } from "../common/tables/CustomTable";
import TableLink from "../common/tables/TableLink";
import DisplayDateWithTime from "../common/components/DisplayDateWithTime";

export default observer(function GbmHistoryListPage() {
    const { resourceGbmStore } = useStore();
    const { loading, loadResourceGbms, resourceGbmItems, pagingParams, totalCount } = resourceGbmStore;
    const [sortColumn, setSortColumn] = useState("date");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const [selectedId, setSelectedId] = useState(0);
    
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "GBM History", "pageurl": "/gbmHistory", "active": true, icon: <LocalGroceryStoreIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]
    
    useEffect(() => {
        pagingParams.skip = 0;
        pagingParams.take = pageRows[1];
        pagingParams.sort = "-date";
    }, [])

    useEffect(() => {
        loadResourceGbms('', selectedId);
    }, [loadResourceGbms, selectedId, page, sortColumn, sortOrder, rowsPerPage])

    const handlePageChange = (page:number) => {
        pagingParams.skip = page * pagingParams.take;
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        pagingParams.take = rows;
        pagingParams.skip = 0;

        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        if (sortOrder === SortingType.asc) {
            pagingParams.sort = column;
        } else {
            pagingParams.sort = `-${column}`;
        }

        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: totalCount,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<ResourceGbmItem>[] = [
        {
            key: "date",
            title: "Date",
            sortLabel: "date",
            isSortable: true,
            render: (_, { date}) => (
                <DisplayDateWithTime date={date} />
            )
        },
        {
            key: "resourceName",
            title: "Resource",
            sortLabel: "name",
            isSortable: true,
            render: (_, { resourceName, slug}) => (
                <TableLink url={`/resources/${slug}`} name={resourceName} />
            )
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "cpu",
            title: "CPU",
            sortLabel: "cpu",
            isSortable: true,
            align: "right",
            render: (_, { cpu}) => (
                <>{cpu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</>
            )
        },
        {
            key: "player",
            title: "Player",
            sortLabel: "player",
            isSortable: true
        }
    ]

    return (
            <PageContainer pagetitle="Gbm History" breadcrumbs={breadcrumbs}>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <ResourceFilter setResourceId={setSelectedId} />
                    </Grid>
                </Grid>
                <CustomTable
                    data={resourceGbmItems}
                    columns={columns}
                    pagination={pagination}
                    tableName="GBM History"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Gbm History found"
                />
            </PageContainer>
    )
})

