import { Breadcrumbs, Link } from "@mui/material";
import { Breadcrumb } from "../../models/common";
import {Link as RouterLink } from 'react-router-dom';
import PageHeader from "./PageHeader";

interface PageContainerProps {
    pagetitle?: string;
    pagetext?: string;
    breadcrumbs?: Breadcrumb[];
    children: JSX.Element | JSX.Element[];
  }

export default function PageContainer({pagetitle, pagetext, breadcrumbs, children}: PageContainerProps) {
   
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" style={{paddingBottom: "15px"}}>
                {breadcrumbs?.map(({title, pageurl, icon, active}) => (
                    <Link
                        component={RouterLink}
                        key={title}
                        underline="hover"
                        color="inherit"
                        to={pageurl}
                        aria-current={active}
                        style={{pointerEvents: active ? 'none' : 'auto'}}
                    >
                        {icon}{title}
                    </Link>
                ))}
            </Breadcrumbs>
            { (pagetitle || pagetext) && <PageHeader pagetitle={pagetitle} pagetext={pagetext} /> }
            {children}
            <br />
        </>
    )
}