import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import { Box, Button, Switch, Table, TableBody, TableContainer, TableHead, TablePagination, Toolbar, Link as MuiLink, IconButton, Typography, TableSortLabel } from "@mui/material";
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Link, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import { useStore } from "../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import { Breadcrumb, PublishForm } from "../../app/models/common";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import PageContainer from "../../app/common/page/PageContainer";
import { ArticleListItem } from "../../app/models/Blog/Article";
import PublishedListItem from "../components/PublishedListItem";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import { history } from "../..";
import { visuallyHidden } from '@mui/utils';

export default observer(function ArticleListPage() {
    const { user } = useAuth0();
    const { articleAdminStore: articleStore } = useStore()
    const { loading, pagingParams, totalCount, articleList, loadArticleList, publishArticle, deleteArticle } = articleStore;
    const [page, setPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState("publishedDate");
    const [sortOrder, setSortOrder] = React.useState(SortingType.asc);
    const navigate = useNavigate();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Articles", "pageurl": "/admin/articles", "active": true, icon: <ArticleIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        loadArticleList();
    }, [loadArticleList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadArticleList();
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadArticleList();
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadArticleList();
    }

    const handlePublishArticle = (
        articleId: number
    ) => {
        const form: PublishForm = {
            id: articleId
        }

        publishArticle(form).then(() => loadArticleList());
    }

    const handleDeleteArticle = (
        articleId: number
    ) => {
        deleteArticle(articleId).then(() => loadArticleList());
    }

    if (loading) return <LoadingComponent />
    
    return (
        <PageContainer breadcrumbs={breadcrumbs} pagetitle="Articles">
            <>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PostAddIcon />}
                        component={Link}
                        to="/admin/articles/create"
                    >
                        New Article
                    </Button>
                </Toolbar>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Articles table">
                        <TableHead>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "title"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("title")}
                                    >
                                        Title
                                        {sortColumn === "title" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "author"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("author")}
                                    >
                                        Author
                                        {sortColumn === "author" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "summary"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("summary")}
                                    >
                                        Summary
                                        {sortColumn === "summary" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "isSticky"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isSticky")}
                                    >
                                        Sticky
                                        {sortColumn === "isSticky" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "isPage"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isPage")}
                                    >
                                        Page
                                        {sortColumn === "isPage" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "publishedDate"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("publishedDate")}
                                    >
                                        Published
                                        {sortColumn === "publishedDate" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                Actions
                            </StyledTableCell>
                        </TableHead>
                        <TableBody>
                            {articleList ? articleList.map((article: ArticleListItem) => (
                                <StyledTableRow key={article.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/articles/${article.slug}`}
                                            color="inherit"
                                        >
                                            {article.title}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{article.authorName}</StyledTableCell>
                                    <StyledTableCell>
                                        <ReactMarkdown>
                                            {article.summary}
                                        </ReactMarkdown>
                                    </StyledTableCell>
                                    <StyledTableCell><Switch checked={article.isSticky} color="success" /></StyledTableCell>
                                    <StyledTableCell><Switch checked={article.isPage} color="success" /></StyledTableCell>
                                    <StyledTableCell>
                                        <PublishedListItem isPublished={article.isPublished} publishedDate={article.publishedDate} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {!article.isPublished && <IconButton aria-label="publish" color="primary" onClick={() => handlePublishArticle(article.id)}>
                                            <PublishIcon />
                                        </IconButton>}

                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteArticle(article.id)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={7}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No articles found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        </PageContainer>
    )
})