import { Alert, Button, FormGroup, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";
import * as Yup from 'yup';
import HomeIcon from "@mui/icons-material/Home";
import TimelineIcon from "@mui/icons-material/Timeline";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { history } from "../.."
import { Link } from "react-router-dom";
import MyTextAreaInput from "../../app/common/form/MyTextAreaInput";
import { CheckboxWithLabel } from "formik-material-ui";
import ReactMarkdown from "react-markdown";
import { useStore } from "../../app/stores/store";
import { TimelineForm } from "../../app/models/Blog/Timeline";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function TimelineFormPage() {
    const { user } = useAuth0();
    const { timelineAdminStore: timelineStore } = useStore();
    const { createTimeline, updateTimeline, loadTimeline, loading } = timelineStore;
    const [localTimeline, setTimeline] = React.useState<TimelineForm>(new TimelineForm());
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        title: Yup.string().required().max(256),
        contents: Yup.string().required()
    })

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        if (id) {
            loadTimeline(id).then((timeline) => setTimeline(new TimelineForm(timeline)))
        }
    }, [id, loadTimeline])

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Timelines", "pageurl": "/admin/timelines", "active": false, icon: <TimelineIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    function handleFormSubmit(timeline: TimelineForm) {
        if (!id) {
            createTimeline(timeline).then(() => navigate("/admin/timelines"))
        } else {
            updateTimeline(timeline).then(() => navigate("/admin/timelines"))
        }
    }

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle="Create Timeline" breadcrumbs={breadcrumbs}>
            <Formik
                initialValues={localTimeline}
                onSubmit={values => handleFormSubmit(values)}
                validationSchema={validationSchema}
            >
                {({handleSubmit, isSubmitting, isValid, dirty, errors, values}) => (
                    <Form className="form" onSubmit={handleSubmit} autoComplete='off'>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <MyTextInput name="title" placeholder="Title" label="Title" help={errors.title} />
                                <MyTextAreaInput name="contents" placeholder="Contents" label="Contents" help={errors.contents} rows={5} />
                                <FormGroup sx={{paddingLeft: '5px'}}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        type="checkbox"
                                        name="isPublished"
                                        Label={{ label: "Publish" }}
                                    />
                                </FormGroup>
                                <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!isValid || !dirty || isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        component={Link}
                                        to="/admin/timelines"
                                    >
                                        Cancel
                                    </Button>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{paddingLeft: '20px'}}>
                                <ReactMarkdown>
                                    {values.contents}
                                </ReactMarkdown>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    )
})