import { Switch } from "@mui/material";
import Moment from "react-moment";

interface Props {
    isPublished: boolean;
    publishedDate: Date | null;
  }

export default function PublishedListItem({isPublished, publishedDate} : Props)  {

    if (isPublished) return (
        <>
            <Switch checked={isPublished} color="success" />
            <Moment format="DD/MM/YYYY hh:mm:ss a">{publishedDate != null ? publishedDate : Date.now()}</Moment>
        </>
    )
    return (
        <Switch checked={isPublished} color="success" />
    )
}