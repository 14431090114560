import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    factoryCalcTypeId: number;
    setFactoryCalcTypeId: (resourcetypeId: number) => void;
}

export default observer(function FactoryCalcTypeFilter({ factoryCalcTypeId: factoryCalcTypeId, setFactoryCalcTypeId: setFactoryCalcTypeId }: Props) {

    const handleChangeType = (event: SelectChangeEvent) => {
        setFactoryCalcTypeId(parseInt(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id="factory-calc-select">Calculation Type</InputLabel>
            <Select
                id="factory-calc-select"
                value={factoryCalcTypeId.toString()}
                onChange={handleChangeType}
                label="CalculationType Type"
                sx={{minWidth: "250px"}}
            >
                <MenuItem value={1}>Units</MenuItem>
                <MenuItem value={2}>Hours</MenuItem>
                <MenuItem value={3}>Days</MenuItem>
            </Select>
        </FormControl>
    )
})