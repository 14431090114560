import { Switch } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStore } from "../../../app/stores/store";

interface Props {
    isLocked: boolean;
    userId: string
}

export default observer(function UserLockComponent({isLocked, userId} : Props) {
    const { userAdminStore } = useStore();
    const { lockUser, unlockUser } = userAdminStore;
    const [checked, setChecked] = useState(isLocked);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            lockUser(userId);
        } else {
            unlockUser(userId);
        }

        setChecked(event.target.checked);
    }

    return (
        <Switch checked={checked} color="success" onChange={handleChange} />
    )
})