import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { BlueprintListItem, BlueprintSummary } from "../../models/Blueprints/Blueprint";
import { PagingParams } from "../../models/pagination";

export default class BlueprintStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "name");
    blueprintItems: BlueprintListItem[] = [];
    blueprintItem: BlueprintSummary | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadBlueprintList = async (type: number = 0, name: string, cat: string = '') => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (type !== 0) {
                axiosParams.append('categoryId', type.toString());
            }

            if (name !== "") {
                axiosParams.append('name', name);
            }

            if (cat !== null && cat !== "") {
                axiosParams.append('category', cat);
            }

            const result = await agent.BlueprintApi.getList(axiosParams);

            runInAction(() => {
                this.blueprintItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadBlueprint = async (slug: string) => {
        this.loading = true;

        try {
            const blueprint = await agent.BlueprintApi.get(slug);

            runInAction(() => {
                this.blueprintItem = blueprint;
                this.loading = false;
            });
            
            return blueprint;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    uploadBlueprints = async(form: FormData) => {
        try {
            console.log("form", form);

            await agent.BlueprintApi.process(form);
        } catch(error) {
            throw error;
        }
    }
}