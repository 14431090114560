import { observer } from "mobx-react-lite";
import {  FormControl, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { ThemeContext } from "../../../app/utils/ThemeProvider";
import { useContext } from "react";
import { useStore } from "../../../app/stores/store";
import { themeOptionList } from "../../../app/utils/globalConstants";

export default observer(function ThemeTab() {
    const {commonStore} = useStore();
    const { theme } = commonStore;
    const setThemeName = useContext(ThemeContext);

    const handleChange = (event: SelectChangeEvent) => {
        setThemeName(event.target.value);
    }

    return (
        <Grid container>
            <Grid item>
                <FormControl fullWidth>
                    <Select
                        id="theme-select"
                        value={theme!}
                        onChange={handleChange}
                    >
                        {themeOptionList.map(map => 
                            <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                        )} 
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )    
})
