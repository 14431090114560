import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { SchematicRelatedItem } from "../../models/Schematics/Schematic";

export default class SchematicChildrenStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "name");
    schematics: SchematicRelatedItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadSchematics = async (slug: string) => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            axiosParams.append('slug', slug);

            const result = await agent.SchematicsApi.getChildren(axiosParams);

            runInAction(() => {
                this.schematics = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}