import { observer } from "mobx-react-lite"
import { useState } from "react"
import { FactoryCalcType, SortingType, pageRows } from "../../../../app/utils/globalConstants"
import { CustomTable, IColumnType, IPagination } from "../../../common/tables/CustomTable"
import TableLink from "../../../common/tables/TableLink"
import { SmrProductionResponse } from "../../../../app/models/Factory/Smr"
import { useAuth0 } from "@auth0/auth0-react"
import { Grid } from "@mui/material"
import _ from "lodash"
import Moment from "react-moment"

interface Props {
    smrList: SmrProductionResponse[];
    loading: boolean;
}

export default observer(function SmrProductionList({ smrList, loading }: Props) {
    const [sortColumn, setSortColumn] = useState("resourceName");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const { isAuthenticated } = useAuth0();

    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: smrList?.length ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<SmrProductionResponse>[] = [
        {
            key: "resourceName",
            title: "Resource",
            sortLabel: "resourceName",
            isSortable: true,
            render: (_, { resourceSlug, resourceName}) => (
                <TableLink url={`/resources/${resourceSlug}`} name={resourceName} />
            )
            
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "duration",
            title: "Duration",
            sortLabel: "duration",
            isSortable: true,
            align: "right",
            render: (_, { duration}) => (
                <>{duration.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hrs</>
            )
        },
        {
            key: "estimateCompletion",
            title: "Estimate Completion",
            sortLabel: "estimateCompletion",
            isSortable: false,
            align: "right",
            render: (_, { duration}) => (
                <Moment format="DD MMM YYYY HH:mm">{Date.now() + duration * (60 * 60 * 1000)}</Moment>
            )
        },
        {
            key: "productionType",
            title: "Production Type",
            sortLabel: "productionType",
            isSortable: true,
            align: "right",
            render: (_, { productionType}) => (
                FactoryCalcType[productionType]
            )
        },
    ]

    return (
        <Grid container>
            <Grid item md={12} sm={12}>
            <CustomTable
                    data={_.orderBy(smrList, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    pagination={pagination}
                    tableName="Smr Production"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Smr Production found"
                />
            </Grid>
        </Grid>
    )    
})