import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { observer } from "mobx-react-lite";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import TimelineIcon from "@mui/icons-material/Timeline";
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineItem as TimelineDetail } from "../../app/models/Blog/Timeline";
import { TimelineItem, TimelineOppositeContent } from "@mui/lab";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";

export default observer(function TimelinePage() {
    const { timelineStore } = useStore();
    const { loading, loadTimelineList, timelineList } = timelineStore;

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Timeline", "pageurl": "/timeline", "active": true, icon: <TimelineIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        loadTimelineList();
    }, [loadTimelineList])

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle="Timeline" breadcrumbs={breadcrumbs}>
            { timelineList && 
                <Timeline position="alternate">
                    { timelineList.map((item: TimelineDetail) => (
                        <TimelineItem key={item.id}>
                            <TimelineOppositeContent style={{margin: "auto 0"}}>
                                <Typography variant="body2" color="primary">
                                    <Moment format="DD MMM YYYY">{item.publishedDate!}</Moment>
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={24} variant="elevation" style={{ margin: "-16px 16px"}}>
                                    <Box p={1}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            <ReactMarkdown>
                                                {item.contents}
                                            </ReactMarkdown>
                                        </Typography>
                                    </Box>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            }
        </PageContainer>
        
    )
})