import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { CategoryListItem } from "../../models/Categories/Category";

export default class CategoryStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(-1, 0, "name");
    categoryItems: CategoryListItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadCategoryList = async (includeSubCategories: boolean, type: number) => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (includeSubCategories) {
                axiosParams.append('IncludeSubCategories', "true");
            }

            if (type !== null && type > 0) {
                axiosParams.append('Type', type.toString())
            }

            const result = await agent.CategoriesApi.getList(axiosParams);

            runInAction(() => {
                this.categoryItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}