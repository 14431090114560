import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ResourceStatsItem } from "../../models/Resources/Resource";

export default class MyResourceStatsStore {
    loading = false;
    globalStats: ResourceStatsItem | null = null;    
    filteredStats: ResourceStatsItem | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get axiosParams() {
        const params = new URLSearchParams();

        return params;
    }

    loadFilteredStats = async (type: number = 0, locationId: number = 0, name: string, userId: string) => {
        this.loading = true;

        try {

            var axiosParams = this.axiosParams;

            if (type !== 0) {
                axiosParams.append('type', type.toString());
            }

            if (name !== '') {
                axiosParams.append('name', name);
            }

            if (locationId !== 0) {
                axiosParams.append('locationId', locationId.toString());
            }

            if (userId !== '') {
                axiosParams.append('userId', userId);
            }

            const resource = await agent.ResourcesApi.getMyResourceStats(axiosParams);

            runInAction(() => {
                this.filteredStats = resource;
                this.loading = false;
                
            });
            
            return resource;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadGlobalStats = async (userId: string) => {
        this.loading = true;

        try {

            var axiosParams = this.axiosParams;

            if (userId !== '') {
                axiosParams.append('userId', userId);
            }

            const resource = await agent.ResourcesApi.getMyResourceStats(axiosParams);

            runInAction(() => {
                this.globalStats = resource;
                this.loading = false;
                
            });
            
            return resource;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }


}