import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { SchematicQuery, SchematicRequest, SchematicSummary } from "../../models/Schematics/Schematic";

export default class CraftingStore {
    loading = false;
    craftingItem: SchematicSummary | null = null;
    locationId: number = JSON.parse(localStorage.getItem('locationId') as string) || 0;
    schematicList: SchematicQuery[] | null = JSON.parse(localStorage.getItem('craftingList') || '[]');

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.locationId,
            locationId => {
                if(locationId) {
                    localStorage.setItem('locationId', locationId.toString());
                } else {
                    localStorage.setItem('locationId', "0");
                }
            }
        )

        reaction(
            () => this.schematicList,
            schematicList => {
                if(schematicList) {
                    localStorage.setItem('craftingList', JSON.stringify(schematicList));
                } else {
                    localStorage.setItem('craftingList', JSON.stringify([]));
                }
            }
        )
    }

    setLocationId = (locationId: number) => {
        this.locationId = locationId;
    }

    addSchematics = (schematics: SchematicQuery[]) => {
        const schems : SchematicQuery[] = JSON.parse(localStorage.getItem('craftingList') || '[]');

        schematics.forEach(item => {
            const existingSchem = schems.find(e => e.id === item.id);

            if (existingSchem !== undefined) {
                item.quantity = item.quantity + existingSchem!.quantity;
        
                const index = schems?.indexOf(existingSchem);
                schems.splice(index, 1); // Remove existing record - will be updated with new record
            }
        
            schems.push(item);
        });

        this.schematicList = schems;
    }

    addSchematic = (schematic: SchematicQuery) => {

        if (schematic.id !== 0) {
            const schems : SchematicQuery[] = JSON.parse(localStorage.getItem('craftingList') || '[]');
            const existingSchem = schems.find(e => e.id === schematic.id);
    
            if (existingSchem !== undefined) {
                schematic.quantity = schematic.quantity + existingSchem!.quantity;
    
                const index = schems?.indexOf(existingSchem);
                schems.splice(index, 1); // Remove existing record - will be updated with new record
            }
    
            schems.push(schematic);
    
            this.schematicList = schems;
        }
    }

    deleteSchematic = (id: number) => {
        const schems : SchematicQuery[] = JSON.parse(localStorage.getItem('craftingList') || '[]');

        this.schematicList = schems.filter((schem) => schem.id !== id);
    }

    resetSchematics = () => {
        this.schematicList = [];
    }

    loadCraftingList = async (form: SchematicRequest) => {
        this.loading = true;

        try {
            const schematic = await agent.SchematicsApi.getQuery(form);

            runInAction(() => {
                this.craftingItem = schematic;
                this.loading = false;
            });
            
            return schematic;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

}