import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { FactoryByResourceItem } from "../../models/Factory/Factory";

export default class FactoryByResourceStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(-1, 0, "name");
    factoryItems: FactoryByResourceItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadFactoryList = async (slug: string = '') => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (slug !== null && slug !== "") {
                axiosParams.append('resourceSlug', slug);
            }

            const result = await agent.FactoryApi.getByResource(axiosParams);

            runInAction(() => {
                this.factoryItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}