import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { HeavyFuelRequestModel, HeavyFuelResponseModel } from "../../models/Factory/HeavyFuel";

export default class HeavyFuelStore {
    loading = false;
    heavyFuelModel: HeavyFuelResponseModel | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    calculateFuel = async (form: HeavyFuelRequestModel) => {
        this.loading = true;

        try {
            const result = await agent.FactoryApi.getHeavyFuel(form);

            runInAction(() => {
                this.heavyFuelModel = result;
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}