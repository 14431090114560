import { observer } from "mobx-react-lite";
import _ from "lodash";
import { List, Paper, Typography } from "@mui/material";

import { useStore } from "../../../app/stores/store";
import { SchematicQuery } from "../../../app/models/Schematics/Schematic";
import SchematicListItemComponent from "./SchematicListItemComponent";

export default observer(function SchematicListComponent() {
    const { craftingStore } = useStore();
    const { schematicList, deleteSchematic } = craftingStore;

    function handleDelete(id: number) {
        deleteSchematic(id);
    }

    return (
        <Paper>
            <>
                <Typography variant="body2" component="h6">
                    Schematics
                </Typography>
                <List dense>
                    {schematicList && _.orderBy(schematicList, ["name"]).map((item: SchematicQuery) => (
                        <SchematicListItemComponent schematic={item} deleteSchematic={handleDelete} key={item.id} />
                    ))}
                </List>
            </>
        </Paper>
    )
})