import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { ShipModifierListItem } from "../../models/Resources/Resource";
import { ShipDesign, ShipOverview } from "../../models/ShipBuilder/ShipBuilder";
import { UserDesignCreateForm, UserDesignItem, UserDesignUpdateForm } from "../../models/UserDesigns/UserDesigns";

export default class ShipBuilderStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(-1, 0, "name");
    shipModifiers: ShipModifierListItem[] = [];
    shipDesigns: ShipDesign[] = [];
    shipDesign: ShipDesign | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadModifiers = async () => {
        this.loading = true;

        try {
            const result = await agent.ResourcesApi.getShipModifiers();

            runInAction(() => {
                this.shipModifiers = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadShipDesigns = async () => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            axiosParams.append("type", "ships");

            const results = await agent.UserDesignApi.getList(axiosParams);

            runInAction(() => {
                this.shipDesigns = [];

                results.data.forEach((e) => {
                    const overview = JSON.parse(e.data) as ShipOverview;

                    const form : ShipDesign = {
                        id: e.id,
                        name: e.name,
                        details: overview.details,
                        summary: overview.summary
                    };

                    this.shipDesigns.push(form);
                })
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadShipDesign = async (id: number) => {
        this.loading = true;

        try {
            const result = await agent.UserDesignApi.get(id);

            runInAction(() => {
                const overview = JSON.parse(result.data) as ShipOverview;

                const form : ShipDesign = {
                    id: result.id,
                    name: result.name,
                    details: overview.details,
                    summary: overview.summary
                };

                this.shipDesign = form;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    addShipDesign = async (design: ShipDesign) => {
        try {
            const shipOverview : ShipOverview = {
                details: design.details,
                summary: design.summary
            }

            const form : UserDesignCreateForm = {
                type: "ships",
                name: design.name,
                data: JSON.stringify(shipOverview)
            }

            const id = await agent.UserDesignApi.create(form);

        } catch(error) {
            throw error;
        }
    }

    updateShipDesign = async (design: ShipDesign) => {
        try {
            const shipOverview : ShipOverview = {
                details: design.details,
                summary: design.summary
            }

            const form : UserDesignUpdateForm = {
                id: design.id,
                name: design.name,
                data: JSON.stringify(shipOverview)
            }

            await agent.UserDesignApi.update(form);
        } catch(error) {
            throw error;
        }
    }

    deleteShipDesign = async (id: number) => {
        try {
            await agent.UserDesignApi.delete(id);
        } catch(error) {
            throw error;
        }
    }
    
}