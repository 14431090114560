import { observer } from "mobx-react-lite";

import HomeIcon from "@mui/icons-material/Home";
import SchematicIcon from "@mui/icons-material/Apartment";
import MyBsModulesIcon from '@mui/icons-material/ViewModule';

import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import MyBattleshipModulesComponent from "./Components/MyBattleshipModulesComponent";

export default observer(function MyBattleshipModulesPage() {

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Schematics", "pageurl": "/schematics", "active": false, icon: <SchematicIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "My Battleship Modules", "pageurl": "/schematics/battleships", "active": true, icon: <MyBsModulesIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    return (
            <PageContainer pagetitle="My Battleship Modules" breadcrumbs={breadcrumbs}>
                <MyBattleshipModulesComponent />
            </PageContainer>
    )
})
