import { observer } from "mobx-react-lite"
import { useState } from "react"
import { SortingType, pageRows } from "../../../../app/utils/globalConstants"
import { CustomTable, IColumnType, IPagination } from "../../../common/tables/CustomTable"
import TableLink from "../../../common/tables/TableLink"
import { useAuth0 } from "@auth0/auth0-react"
import { Grid, Typography } from "@mui/material"
import _ from "lodash"
import { FactoryRequirementResponse } from "../../../../app/models/Factory/Factory"

interface Props {
    responseList: FactoryRequirementResponse[];
    loading: boolean;
}

export default observer(function FactoryRefineryRequirementList({ responseList, loading }: Props) {
    const [sortColumn, setSortColumn] = useState("resourceName");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const { isAuthenticated } = useAuth0();

    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: responseList?.length ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<FactoryRequirementResponse>[] = isAuthenticated ? [
        {
            key: "resourceName",
            title: "Resource",
            sortLabel: "resourceName",
            isSortable: true,
            render: (_, { resourceSlug, resourceName}) => (
                <TableLink url={`/resources/${resourceSlug}`} name={resourceName} />
            )
            
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "quantityHave",
            title: "Quantity Have",
            sortLabel: "quantityHave",
            isSortable: true,
            align: "right",
            render: (_, { quantityHave}) => (
                <>{quantityHave.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "missingQty",
            title: "Quantity Need",
            sortLabel: "missingQty",
            isSortable: true,
            align: "right",
            render: (_, { quantityNeed}) => (
                <Typography component="span" variant="body2" color={quantityNeed > 0 ? "error.main" : "success.main"}>
                    {quantityNeed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
            )
        },
    ] : [
        {
            key: "resourceName",
            title: "Resource",
            sortLabel: "resourceName",
            isSortable: true,
            render: (_, { resourceSlug, resourceName}) => (
                <TableLink url={`/resources/${resourceSlug}`} name={resourceName} />
            )
            
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        }
    ]

    return (
        <Grid container>
            <Grid item md={12} sm={12}>
            <CustomTable
                    data={_.orderBy(responseList, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    pagination={pagination}
                    tableName="Factory Requirements"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Factory Requirments found"
                />
            </Grid>
        </Grid>
    )    
})