import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

interface Props {
    locationId: number;
    setLocationId: (locId: number) => void;
}

export default observer(function LocationListSelector({ locationId, setLocationId }: Props) {
    const { locationLiteStore } = useStore();
    const { locationLiteItems, loadLocationLiteList} = locationLiteStore;

    useEffect(() => {
        loadLocationLiteList(0,0,'', true);
    }, [loadLocationLiteList])

    const handleChangeLocation = (event: SelectChangeEvent) => {
        setLocationId(parseInt(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id="location-select">Location Filter</InputLabel>
            <Select
                id="location-select"
                value={locationId.toString()}
                onChange={handleChangeLocation}
                label="Location Filter"
                sx={{minWidth: "250px"}}
            >
                <MenuItem value={0}>None</MenuItem>
                    {locationLiteItems && locationLiteItems.map(map => 
                        <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                    )}  
            </Select>
        </FormControl>
    )
})