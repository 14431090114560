import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { GalaxyLiteItem } from "../../models/Locations/galaxy";
import { PagingParams } from "../../models/pagination";


export default class UserLiteStore {
    loadingGalaxies = false;
    totalGalaxyLiteCount: number = 0;
    pagingParamsGalaxyLite = new PagingParams(-1, 0, "name");
    galaxyLiteItems: GalaxyLiteItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsGalaxyLite = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalGalaxyLiteCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsGalaxyLite.take.toString());
        params.append('skip', this.pagingParamsGalaxyLite.skip.toString());
        params.append('sort', this.pagingParamsGalaxyLite.sort);

        return params;
    }

    loadGalaxyList = async () => {
        this.loadingGalaxies = true;

        try {
            const result = await agent.GalaxyApi.getLite(this.axiosParams);

            runInAction(() => {
                this.galaxyLiteItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingGalaxies = false;
            })
        } catch(error) {
            runInAction(() => this.loadingGalaxies = false);
            throw error;
        }
    }
}