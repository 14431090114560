import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { SmrRequest, SmrRequestItem, SmrResponse } from "../../models/Factory/Smr";

export default class SmrStore {
    loading = false;
    locationId: number = JSON.parse(localStorage.getItem('locationId') as string) || 0;
    requestList: SmrRequestItem[] | null = JSON.parse(localStorage.getItem('smrList') || '[]');
    smrResponse: SmrResponse | null = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.locationId,
            locationId => {
                if(locationId) {
                    localStorage.setItem('locationId', locationId.toString());
                } else {
                    localStorage.setItem('locationId', "0");
                }
            }
        )

        reaction(
            () => this.requestList,
            requestList => {
                if(requestList) {
                    localStorage.setItem('smrList', JSON.stringify(requestList));
                } else {
                    localStorage.setItem('smrList', JSON.stringify([]));
                }
            }
        )
    }

    setLocationId = (locationId: number) => {
        this.locationId = locationId;
    }

    addUpdateSmr = (smr: SmrRequestItem) => {
        const smrs :  SmrRequestItem[] = JSON.parse(localStorage.getItem('smrList') || '[]');

        if (smr.facilityId !== 0) {
            const existingSmr = smrs.find(e => e.facilityId === smr.facilityId);

            if (existingSmr !== undefined) {
                const index = smrs?.indexOf(existingSmr);
                smrs.splice(index, 1); // Remove exisitng record
            }

            smrs.push(smr);            
        
            this.requestList = smrs;
        }

    }

    deleteSmr = (id: number) => {
        const smrs :  SmrRequestItem[] = JSON.parse(localStorage.getItem('smrList') || '[]');

        this.requestList = smrs.filter((smr) => smr.facilityId !== id);

    }

    resetSmr = () => {
        this.requestList = [];

    }
    
    loadSmrList = async () => {
        this.loading = true;

        try {
            const smrs :  SmrRequestItem[] = JSON.parse(localStorage.getItem('smrList') || '[]');


            const smrData = await agent.FactoryApi.getSmr(new SmrRequest({
                locationId: this.locationId,
                smrFacilities: smrs
            }));

            runInAction(() => {
                this.smrResponse = smrData;
                this.loading = false;
            })

        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}