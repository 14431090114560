import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ArticleForm, ArticleItem, ArticleListItem } from "../../models/Blog/Article";
import { PublishForm } from "../../models/common";
import { PagingParams } from "../../models/pagination";

export default class ArticleAdminStore {
    article: ArticleItem | null = null;
    articleList: ArticleListItem[] = [];

    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "-changedDateTime");

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadArticleList = async () => {
        this.loading = true;

        try {
            const result = await agent.ArticleApi.getList(this.axiosParams);

            runInAction(() => {
                this.articleList = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadArticle = async (slug: string) => {
        this.loading = true;

        try {
            const article = await agent.ArticleApi.get(slug);

            runInAction(() => {
                this.article = article;
                this.loading = false;
                
            });
            
            return article;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    createArticle = async(article: ArticleForm) => {
        try {
            await agent.ArticleApi.create(article);
        } catch(error) {
            throw error;
        }
    }

    updateArticle = async(article: ArticleForm) => {
        try {
            await agent.ArticleApi.update(article);
        } catch(error) {
            throw error;
        }
    }

    deleteArticle = async(id: number) => {
        try {
            await agent.ArticleApi.delete(id);
        } catch(error) {
            throw error;
        }
    }

    publishArticle = async(publish: PublishForm) => {
        try {
            await agent.ArticleApi.publish(publish);
        } catch(error) {
            throw error;
        }
    }
}