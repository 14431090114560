import { observer } from "mobx-react-lite";
import { Box,  Button, Modal, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { ItemListItem } from "../../../app/models/Items/Item";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import { useState } from "react";
import * as _ from "lodash";

interface Props {
    title: string;
    items: ItemListItem[];
    open: boolean;
    handleClose: (id: number) => void;
}

export default observer(function SelectStarbaseComponent({title, items, open, handleClose} : Props) {
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);    

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTake(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={3} sx={{ maxWidth: "90%", maxHeight: "90%", minHeight: "90%", padding: "10px", paddingRight: "25px", border: "1px solid #000", position: "relative", left: "5%", right: "5%", top: "5%"}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Select {title}
                </Typography>
                <Box id="modal-model-description" sx={{margin: "5px"}}>
                    <TableContainer>
                        <Table aria-label="Starbase component select table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Component</StyledTableCell>
                                    <StyledTableCell>Armour</StyledTableCell>
                                    <StyledTableCell>Hull</StyledTableCell>
                                    <StyledTableCell>Energy</StyledTableCell>
                                    <StyledTableCell>Recycling Efficiency</StyledTableCell>
                                    <StyledTableCell>Ecosystem Efficiency</StyledTableCell>
                                    <StyledTableCell>Scientific Efficiency</StyledTableCell>
                                    <StyledTableCell>Population Management</StyledTableCell>
                                    <StyledTableCell>Build Time</StyledTableCell>
                                    <StyledTableCell>Select</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { items &&  _.orderBy(items, [sortColumn], [sortOrder == SortingType.asc ? 'asc' : 'desc']).slice(page * take, page * take + take).map((item: ItemListItem) => (
                                    <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell>{item.name}</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "Armour")?.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "Hull")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "Energy")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "Recycling")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "Ecosystem")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "Scientific")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                                        <StyledTableCell align="right">{item.attributes.find(e => e.key === "PopulationManagement")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                                        <StyledTableCell align="right">{`${item.attributes.find(e => e.key === "BuildTime")?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hrs`}</StyledTableCell>
                                        <StyledTableCell>
                                        <Button size="small" onClick={() => handleClose(item.id)}>Select</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={items?.length || 0}
                        page={page}
                        rowsPerPage={take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Paper>
        </Modal>    
    )
})

