export class ShipSummary {
    armour: number = 0;
    hull: number = 0;
    energy: number = 0;
    cargo: number = 0;
    speed: number = 0;
    specials: number = 0;
    pods: number = 0;
    level: number = 0;
    skillBonus: number = 0;
    buildTime: number = 0;

    constructor(form?: ShipSummary) {
        if (form) {
            this.armour = form.armour;
            this.hull = form.hull;
            this.energy = form.energy;
            this.cargo = form.cargo;
            this.speed = form.speed;
            this.specials = form.specials;
            this.pods = form.pods;
            this.level = form.level;
            this.skillBonus = form.skillBonus;
            this.buildTime = form.buildTime;
        }
    }
}

export class ShipDetail {
    chassis: number = 0;
    frame: number = 0;
    module1: number = 0;
    module2: number = 0;
    module3: number = 0;
    module4: number = 0;
    module5: number = 0;
    modifier: number = 0;

    constructor(form?: ShipDetail) {
        if (form) {
            this.chassis = form.chassis;
            this.frame = form.frame;
            this.module1 = form.module1;
            this.module2 = form.module2;
            this.module3 = form.module3;
            this.module4 = form.module4;
            this.module5 = form.module5;
            this.modifier = form.modifier;
        }
    }
}

export class ShipOverview {
    summary: ShipSummary = new ShipSummary();
    details: ShipDetail = new ShipDetail();

    constructor(form?: ShipOverview) {
        if (form) {
            this.summary = form.summary;
            this.details = form.details;
        }
    }
}

export class ShipDesign {
    id: number = 0;
    name: string = "";
    summary: ShipSummary = new ShipSummary();
    details: ShipDetail = new ShipDetail();

    constructor(form?: ShipDesign) {
        if (form) {
            this.id = form.id;
            this.name = form.name;
            this.summary = form.summary;
            this.details = form.details;
        }
    }
}