import { observer } from "mobx-react-lite";
import { Box, Button, Modal, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
    open: boolean;
    id: number;
    pattern: string;
    quantity: number;
    handleClose: (id: number, quantity: number) => void;
}

export default observer(function EditQuantityModal({open, id, pattern, quantity, handleClose} : Props) {
    const [localQuantity, setLocalQuantity] = useState(0);

    useEffect(() => {
        setLocalQuantity(quantity);
    }, [quantity])


    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        try {
            const input = parseInt(event.target.value);

            if (input < 1) {
                setLocalQuantity(1);
            } else  {
                setLocalQuantity(input);
            }
        } catch {
            setLocalQuantity(1);
        }
    }

    const handleOnClose = () => {
        handleClose(id, localQuantity);
    }

    return (
        <Modal
            open={open}
            onClose={() => handleOnClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={3} sx={{ maxWidth: "90%", padding: "10px", paddingRight: "25px", border: "1px solid #000", position: 'absolute', top: '30%', left: '50%', width: 400}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update {pattern}
                </Typography>
                <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    <TextField
                        id="pattern-quantity"
                        label="Quantity"
                        value={localQuantity}
                        
                        onChange={handleChangeQuantity}
                        type="number"
                    />   
                </Box>
                <Box id="modal-modal-buttons" sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleOnClose}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        type="submit"
                        onClick={handleOnClose}
                    >
                        Cancel
                    </Button>   
                </Box>
            </Paper>
        </Modal>    
    )
})

