import { observer } from "mobx-react-lite";
import { Grid, Typography } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import StatComponent from "./StatComponent";

interface Props {
    userId: string;
    locationId: number;
    type: number;
    name: string;
    updating: boolean;
}

export default observer(function MyFilteredResourceStats({ userId, locationId, type, name, updating } : Props) {
    const { myResourceStatsStore } = useStore();
    const { filteredStats, loadFilteredStats } = myResourceStatsStore;

    useEffect(() => {
        loadFilteredStats(type, locationId, name, userId);
    }, [loadFilteredStats, userId, locationId, type, name, updating])
   

    return (
        <>
            <Typography variant="body1">
                Filtered stats (Statistics based on current filters)
            </Typography>
            {
                filteredStats && 
                <Grid container>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Total Value
                                <br />
                                {filteredStats.totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Total Value (GBM)
                                <br />
                                {filteredStats.totalValueGbm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Highest Value:
                                <br />
                                {filteredStats.topValueName}
                                <br />
                                {filteredStats.topValueValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Highest Quantity:
                                <br />
                                {filteredStats.topQuantityName}
                                <br /> 
                                {filteredStats.topQuantityValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Highest Value (GBM):
                                <br />
                                {filteredStats.topGbmName}
                                <br />
                                {filteredStats.topGbmValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Most used Location
                                <br />
                                {filteredStats.topLocationName}
                                <br />
                                {filteredStats.topLocationValue} Items
                            </Typography>
                        </Grid>
                    </StatComponent>
                </Grid>
            }
        </>
    )
})

