export interface ArticleListItem {
    id: number;
    slug: string;
    title: string;
    authorName: string;
    summary: string;
    isSticky: boolean;
    isPage: boolean;
    isPublished: boolean;
    publishedDate: Date | null;
}

export interface ArticleItem {
    id: number;
    slug: string;
    title: string;
    authorId: string;
    authorName: string;
    summary: string;
    contents: string;
    isSticky: boolean;
    isPage: boolean;
    isPublished: boolean;
    publishedDate: Date | null;
}

export interface IArticleForm {
    id: number;
    title: string;
    authorId: string;
    summary: string;
    contents: string;
    isSticky: boolean;
    isPage: boolean;
    isPublished: boolean;
    publishedDate: Date | null;
}

export class ArticleForm {
    id: number = 0;
    title: string = '';
    authorId: string = '';
    summary: string = '';
    contents: string = '';
    isSticky: boolean = false;
    isPage: boolean = false;
    isPublished: boolean = false;
    publishedDate: Date | null = null;

    constructor(article?: IArticleForm) {
        if (article) {
            this.id = article.id;
            this.title = article.title;
            this.authorId = article.authorId;
            this.summary = article.summary;
            this.contents = article.contents;
            this.isSticky = article.isSticky;
            this.isPage = article.isPage;
            this.isPublished = article.isPublished;
            this.publishedDate = article.publishedDate;
        }
    }
}

