import { IconButton, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { SchematicQuery } from "../../../app/models/Schematics/Schematic";
import { useStore } from "../../../app/stores/store";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState } from "react";

interface Props {
    id: number;
    name: string;
    small: boolean
}

export default observer(function AddSchematicComponent({ id, name, small }: Props) {
    const { craftingStore } = useStore();
    const { addSchematic } = craftingStore;
    const [quantity, setQuantity] = useState(1);

    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);

        if (input < 1) {
            setQuantity(1);
        } else  {
            setQuantity(input);
        }
    }

    function handleClickAdd() {
        const form : SchematicQuery = {
            id: id,
            quantity: quantity,
            name: name
        };

        addSchematic(form);
    }

    const AddButton = () => (
        <IconButton
            sx={{margin: "0", padding: "0", borderColor: "success.main", borderWidth: "10px"}}
            onClick={handleClickAdd}
        >
          <AddBoxIcon color="success" />
        </IconButton>
        )

    return (
        <TextField
            id="add-crafting"
            label="Quantity"
            value={quantity}
            onChange={handleChangeQuantity}
            type="number"
            InputProps={{endAdornment: <AddButton />}}
            size={small ? "small" : "medium"}
            sx={{maxWidth: "100px"}}
        />
    )
})