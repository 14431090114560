import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, Switch, Grid, TextField, FormControl, Select, MenuItem, SelectChangeEvent, InputLabel, Typography, TableSortLabel, Box } from "@mui/material";

import { useStore } from "../../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { StoreItem } from "../../../../app/models/Stores/Store";
import { visuallyHidden } from '@mui/utils';

export default observer(function StoreList() {
    const { storeStore, storeTypeStore } = useStore();
    const { loading, pagingParams, totalCount, storeItems, loadStoreList } = storeStore;
    const { storeTypeItems, loadStoreTypeList} = storeTypeStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [storeTypeId, setStoreTypeId] = useState(0);
    const [searchString, setSearchString] = useState('');
    
    useEffect(() => {
        loadStoreList(storeTypeId, searchString);

    }, [loadStoreList])

    useEffect(() => {
        loadStoreTypeList();
    }, [loadStoreTypeList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadStoreList(storeTypeId, searchString);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadStoreList(storeTypeId, searchString);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadStoreList(storeTypeId, searchString);
    }

    const handleSearch = (change : string) => {
        setSearchString(change);
    }

    const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            loadStoreList(storeTypeId, searchString);
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setStoreTypeId(parseInt(event.target.value));
        loadStoreList(parseInt(event.target.value), searchString);
    }

    if (loading) return <LoadingComponent />

    return (
            <>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="storeType-select">Store Type</InputLabel>
                            <Select
                                id="storeType-select"
                                value={storeTypeId.toString()}
                                onChange={handleChange}
                                label="Store Type"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {storeTypeItems.map(map => 
                                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                                )} 
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField id="name" label="Search" variant="outlined" onChange={event => handleSearch(event.target.value)} onKeyDown={handleCompleteSearch} value={searchString}  />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Store table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Store
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "slug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("slug")}
                                    >
                                        Slug
                                        {sortColumn === "slug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "storeType"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("storeType")}
                                    >
                                        Store Type
                                        {sortColumn === "storeType" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "isAlien"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isAlien")}
                                    >
                                        Alien
                                        {sortColumn === "isAlien" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "isCamp"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isCamp")}
                                    >
                                        Camp
                                        {sortColumn === "isCamp" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {storeItems ? storeItems.map((item: StoreItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/stores/${item.id}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{item.slug}</StyledTableCell>
                                    <StyledTableCell>{item.storeType}</StyledTableCell>
                                    <StyledTableCell><Switch checked={item.isAlien} disabled color="success" /></StyledTableCell>
                                    <StyledTableCell><Switch checked={item.isCamp} disabled color="success" /></StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={6}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No stores found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

