import { TextField } from '@mui/material';
import { useField } from 'formik';

interface Props {
    placeholder: string;
    name: string;
    type?: string;
    label?: string;
    help?: string;
}

export default function MyTextInput(props: Props) {
    const [field, meta] = useField(props.name); 
    return (
        <TextField 
            error={meta.touched && !!meta.error} 
            label={props.label} 
            variant="outlined" 
            id={props.name}
            {...field} {...props} 
            helperText={props.help}
            style={{'display':'flex', 'margin': '10px 5px', 'marginRight': '-5px'}}
        >
        </TextField>
    )
}