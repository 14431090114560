import Moment from "react-moment";

interface Props {
    date: Date
}

export default function DisplayDateWithTime({ date }: Props) {
    return (
        <Moment format="DD/MM/YYYY HH:mm">{date}</Moment>
    )
}