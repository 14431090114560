import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import MyResourcesUploadModal from "../../MyResources/Components/MyResourcesUploadModal";

interface Props {
    handleOnUpload: () => void;
}

export default observer(function SchematicUploadResourcesDetail({ handleOnUpload }: Props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        handleOnUpload();
    }
    
    return (
        <>
            <Grid container>
                <Grid item>
                    <Button onClick={handleOpen} variant="contained" color="primary">Upload</Button>
                </Grid>
            </Grid>
            <MyResourcesUploadModal open={open} handleClose={handleClose} />
        </>
    )
})