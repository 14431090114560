import { Autocomplete, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    categoryId: number;
    includeModules: boolean;
    includeArtifacts: boolean;
    setSchematicId: (schematicId: number, schematicName: string) => void;
}

export default observer(function SchematicFilter({ categoryId, includeModules, includeArtifacts, setSchematicId }: Props) {
    const { schematicLiteStore } = useStore();
    const { schematicLiteItems, loadSchematicLite, pagingParams } = schematicLiteStore;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        pagingParams.skip = 0;
        pagingParams.take = -1;
        pagingParams.sort = "name";
    }, [])

    useEffect(() => {
        setInputValue('');
    }, [categoryId])

    useEffect(() => {
        loadSchematicLite(categoryId, '', includeModules, includeArtifacts);
    }, [loadSchematicLite, categoryId, includeArtifacts, includeModules])

    const handleChangeSchematic = (schematic: string | null) => {
        if (schematic !== null) {
            var selectedId = schematicLiteItems.find(e => e.name === schematic);

            if(selectedId === undefined) {
                setSchematicId(0, schematic);
            } else {
                setSchematicId(selectedId.id, schematic);
            }
        }
    }

    return (
        <FormControl>
            <Autocomplete
                id="schematics-select"
                onChange={(event: any, newValue) => {
                    handleChangeSchematic(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={schematicLiteItems.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Schematics" />}
            />
        </FormControl>
    )
})