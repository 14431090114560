import { observer } from "mobx-react-lite";

import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import MyResourcesIcon from "@mui/icons-material/NaturePeople";

import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import MyResourcesComponent from "./Components/MyResourcesComponent";

export default observer(function PlayerResourcesPage() {

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "My Resources", "pageurl": "/resources/cs", "active": true, icon: <MyResourcesIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    return (
            <PageContainer pagetitle="My Resources" breadcrumbs={breadcrumbs}>
                <MyResourcesComponent userId="" />
            </PageContainer>
    )
})
