import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    lootTypeId: number;
    setLootTypeId: (loottypeId: number) => void;
}

export default observer(function LootTypeFilter({ lootTypeId, setLootTypeId }: Props) {

    const handleChangeType = (event: SelectChangeEvent) => {
        setLootTypeId(parseInt(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id="lootType-select">Loot Type</InputLabel>
            <Select
                id="lootType-select"
                value={lootTypeId.toString()}
                onChange={handleChangeType}
                label="Loot Type"
                sx={{minWidth: "250px"}}
            >
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={1}>combat</MenuItem>
                <MenuItem value={2}>Derelict Exploring</MenuItem>
                <MenuItem value={4}>Mining</MenuItem>
                <MenuItem value={8}>Nebula</MenuItem>
                <MenuItem value={16}>Exploration</MenuItem>
                <MenuItem value={32}>Wreck Salvaging</MenuItem>
                <MenuItem value={64}>Extracting</MenuItem>
                <MenuItem value={128}>Settlement Combat</MenuItem>
                <MenuItem value={256}>Corporation</MenuItem>
            </Select>
        </FormControl>
    )
})
