import { observer } from "mobx-react-lite";
import { Grid, Typography } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import StatComponent from "./StatComponent";

interface Props {
    userId: string;
    updating: boolean;
}

export default observer(function MyGlobalResourceStats({ userId, updating } : Props) {
    const { myResourceStatsStore } = useStore();
    const { globalStats, loadGlobalStats } = myResourceStatsStore;

    useEffect(() => {
        loadGlobalStats(userId);
    }, [loadGlobalStats, userId, updating])
   

    return (
        <>
            <Typography variant="body1">
                Global stats (Based on all your resources)
            </Typography>
            {
                globalStats && 
                <Grid container>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Total Value
                                <br />
                                {globalStats.totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Total Value (GBM)
                                <br />
                                {globalStats.totalValueGbm.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Highest Value:
                                <br />
                                {globalStats.topValueName}
                                <br />
                                {globalStats.topValueValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Highest Quantity:
                                <br />
                                {globalStats.topQuantityName}
                                <br /> 
                                {globalStats.topQuantityValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Highest Value (GBM):
                                <br />
                                {globalStats.topGbmName}
                                <br />
                                {globalStats.topGbmValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                            </Typography>
                        </Grid>
                    </StatComponent>
                    <StatComponent>
                        <Grid item columns={2}>
                            <Typography variant="body1" component="p">
                                Most used Location
                                <br />
                                {globalStats.topLocationName}
                                <br />
                                {globalStats.topLocationValue} Items
                            </Typography>
                        </Grid>
                    </StatComponent>
                </Grid>
            }
        </>
    )
})

