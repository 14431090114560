import { FactoryCalcType } from "../../utils/globalConstants";


export class HeavyFuelRequestModel {
    quantity: number = 0;
    calcType: FactoryCalcType = 1;
    locationId: number = 0;

    constructor(form?: HeavyFuelRequestModel) {
        if (form) {
            this.quantity = form.quantity;
            this.calcType = form.calcType;
            this.locationId = form.locationId;
        }
    }
}

export interface HeavyFuelResponseModel {
    item: string;
    quantity: number;
    hours: number;
    calcType: FactoryCalcType;
    originalQty: number;
    requirements: HeavyFuelRequirementModel[];
}

export interface HeavyFuelRequirementModel {
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}