import { Autocomplete, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    setCategoryId: (categoryId: number) => void;
}

export default observer(function CategoryFilter({ setCategoryId }: Props) {
    const { categoryStore } = useStore();
    const { categoryItems, loadCategoryList } = categoryStore;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        loadCategoryList(false, 1);
    }, [loadCategoryList])

    const handleChangeCategory = (category: string | null) => {
        if (category === null) {
            setCategoryId(0);            
        } else {
            var selectId = categoryItems.find(e => e.name === category);

            if(selectId === undefined) {
                setCategoryId(0);
            } else {
                setCategoryId(selectId.id);
            }
        }
    }

    return (
        <FormControl>
            <Autocomplete
                id="categories-select"
                onChange={(event: any, newValue) => {
                    handleChangeCategory(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={categoryItems.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Categories" />}
            />
        </FormControl>
    )
})