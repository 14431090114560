import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import MyItemsUploadModal from "../../MyItems/Components/MyItemsUploadModal";

interface Props {
    handleOnUpload: () => void;
}

export default observer(function ItemsUploadComponent({ handleOnUpload }: Props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        handleOnUpload();
    }
    
    return (
        <>
            <Button onClick={handleOpen} variant="contained" color="primary" sx={{ width: "250px", marginLeft: '-1px'}}>Upload</Button>
            <MyItemsUploadModal open={open} handleClose={handleClose} />
        </>
    )
})