import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";

export default observer(function HomePage() {
    const { arcticleStore } = useStore();
    const { loading, loadArticle, article } = arcticleStore;
    const { id } = useParams<{ id: string }>();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": `${article?.title}`, "pageurl": `/article/${id}`, "active": true, icon: <ArticleIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
        loadArticle(id!);
    }, [loadArticle])

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle={article?.title!} breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item sm={12} md={6}>
        
                </Grid>
                <Grid item sm={12} md={6}>
                    <Typography variant="body2" component="div" align="right">
                        {`written By: ${article?.authorName!}, `}
                        <Moment format="DD MMM YYYY">{article?.publishedDate!}</Moment>
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <ReactMarkdown>
                        {article?.contents!}
                    </ReactMarkdown>
                </Grid>
                {/* TODO add previous and next options */}
            </Grid>
        </PageContainer>
    )
})