import { FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    boost: number;
    setBoost: (boost: number) => void;
}

export default observer(function FactoryBoostSelect({ boost, setBoost }: Props) {

    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        try {
            const input = parseInt(event.target.value) ?? 0;
            input < 0 ? setBoost(0) : setBoost(input);
        }
        catch {
            setBoost(0);
        }
        
    }

    return (
        <FormControl>
            <FormControl>
                <TextField 
                    id="factory-boost-input"
                    label="Factory Boost"
                    variant="outlined"
                    type="number"
                    value={boost}
                    onChange={handleChangeQuantity}
                    sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                />
            </FormControl>
        </FormControl>
    )
})