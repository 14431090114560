import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { StoreLocationItem } from "../../models/Stores/StoreLocation";

export default class StoreLocationStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "storeName");
    storeLocationItems: StoreLocationItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadStoreLocationList = async (storeId: number = 0, locationId: number = 0, storeName: string = '', locationName: string = '') => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (storeId !== 0) {
                axiosParams.append('storeId', storeId.toString());
            }

            if (locationId !== 0) {
                axiosParams.append('locationId', locationId.toString());
            }

            axiosParams.append('storeName', storeName);

            axiosParams.append('locationName', locationName);

            const result = await agent.StoreLocationApi.getList(axiosParams);

            runInAction(() => {
                this.storeLocationItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}