import { observer } from "mobx-react-lite";
import {  Box, Grid, Table, TableBody, TableContainer, TableHead, TablePagination, TableSortLabel, Typography } from "@mui/material";
import Moment from "react-moment";

import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import LoadingComponent from "../../../app/common/page/LoadingComponent";
import { UserAudit } from "../../../app/models/Audit/UserAudit";
import { visuallyHidden } from '@mui/utils';

interface Props {
    userId: string
}

export default observer(function ActivityLog({ userId } : Props) {
    const { userAuditStore } = useStore();
    const { userActivityLoading, pagingParams, totalCount, userAuditList, loadAuditList } = userAuditStore;
    const [page, setPage] = useState(0);
    const [sortColumn, setSortColumn] = useState("createdAt");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);

    useEffect(() => {
        loadAuditList(userId);
    }, [loadAuditList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadAuditList(userId);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadAuditList(userId);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadAuditList(userId);
    }

    if (userActivityLoading) return <LoadingComponent />

    return (
        <Grid container>
            <TableContainer>
                <Table sx={{minWidth: 650}} aria-label="user activity table">
                    <TableHead>
                        <StyledTableCell>
                            <TableSortLabel
                                        active={sortColumn === "createdAt"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("createdAt")}
                                    >
                                        Date
                                        {sortColumn === "createdAt" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell>
                            <TableSortLabel
                                        active={sortColumn === "activityType"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("activityType")}
                                    >
                                        Type
                                        {sortColumn === "activityType" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell>
                            <TableSortLabel
                                        active={sortColumn === "description"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("description")}
                                    >
                                        Activity
                                        {sortColumn === "description" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                        </StyledTableCell>
                    </TableHead>
                    <TableBody>
                        {userAuditList ? userAuditList.map((item: UserAudit) => (
                            <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                <StyledTableCell>
                                    <Moment format="DD/MM/YYYY hh:mm:ss a">{item.createdAt}</Moment>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {item.auditType}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {item.description}
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : 
                        <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <StyledTableCell colSpan={3}>
                                { userActivityLoading ? <LoadingComponent /> : <Typography variant="body2" component="div">No user activity found</Typography> }
                            </StyledTableCell>
                        </StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={pageRows}
                component="div"
                count={totalCount}
                page={page}
                rowsPerPage={pagingParams.take}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Grid>
    )    
})