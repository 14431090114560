import { observer } from "mobx-react-lite";
import {  Alert, AlertTitle, Grid, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import { StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import { ResourceStoreListItem } from "../../../app/models/Resources/Resource";
import LoadingComponent from "../../../app/common/page/LoadingComponent";

interface Props {
    id: string
}


export default observer(function ResourceStoreTab({ id }: Props) {
    const  { resourceStoreStore } = useStore();
    const { resourceStores, loading, loadResourceList } = resourceStoreStore;
    
    useEffect(() => {
        loadResourceList(id);
    }, [loadResourceList])

    if (loading) return <LoadingComponent />

    return (
        <Grid container>
            <Grid item md={12}>
                <Alert severity="info" variant="filled">
                    <AlertTitle>Info</AlertTitle>
                    There are many more places where you can buy and sell resources, like GBM, Emporium etc. however, Project Eagle has no knowledge about the data for those places, and are therefore not listed.
                </Alert>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                    <Typography variant="h5" component="h5">
                        Available from
                    </Typography>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="available from table">
                            <TableHead>
                                <StyledTableCell>
                                    Store
                                </StyledTableCell>
                                <StyledTableCell>
                                    Type
                                </StyledTableCell>
                                <StyledTableCell>
                                    Price
                                </StyledTableCell>
                            </TableHead>
                            <TableBody>
                                {resourceStores ? resourceStores.filter(e => !e.isSold).map((item: ResourceStoreListItem) => (
                                    <StyledTableRow key={item.storeName} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                        <StyledTableCell>
                                            {item.storeName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.storeType}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {item.currencyCode}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : 
                                <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell colSpan={3}>
                                        { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No stores found</Typography> }
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item md={6} sm={12}>
                    <Typography variant="h5" component="h5">
                        Sold at
                    </Typography>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="sold at table">
                            <TableHead>
                                <StyledTableCell>
                                    Store
                                </StyledTableCell>
                                <StyledTableCell>
                                    Type
                                </StyledTableCell>
                                <StyledTableCell>
                                    Price
                                </StyledTableCell>
                            </TableHead>
                            <TableBody>
                                {resourceStores.filter(e => e.isSold) ? resourceStores.filter(e => e.isSold).map((item: ResourceStoreListItem) => (
                                    <StyledTableRow key={item.storeName} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                        <StyledTableCell>
                                            {item.storeName}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.storeType}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {item.currencyCode}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : 
                                <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell colSpan={3}>
                                        { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No stores found</Typography> }
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    )    
})
