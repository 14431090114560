import { observer } from "mobx-react-lite";
import {  Grid, TextField } from "@mui/material";

import { useStore } from "../../../app/stores/store";

export default observer(function ProfileTab() {
    const { currentUserStore } = useStore();
    const { currentUser } = currentUserStore;

    return (
        <Grid container>
            {
                currentUser &&
                <>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="userName"
                            label="Username"
                            defaultValue={currentUser.userName}
                            variant="outlined"
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="emailAddress"
                            label="Email"
                            defaultValue={currentUser.emailAddress}
                            variant="outlined"
                            type="text"
                            disabled
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography component="div" variant="body1">
                            Last login date: <Moment format="DD/MM/YYYY">{currentUser.lastLoginDate}</Moment>
                        </Typography>
                    </Grid> */}
                </>
            }
        </Grid>
    )    
})