import {  FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    factoryId: number;
    factoryProductId: number;
    setFactoryProductionId: (factoryProductId: number) => void;
}

export default observer(function FactoryProductionFilter({ factoryId, factoryProductId, setFactoryProductionId }: Props) {
    const { factoryProductStore } = useStore();
    const { loading, loadFactoryList, factoryItems} = factoryProductStore;

    useEffect(() => {
        loadFactoryList(factoryId);
    }, [factoryId])

    const handleChangeType = (event: SelectChangeEvent) => {
        setFactoryProductionId(parseInt(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id='factory-production-select'>Factory Products</InputLabel>
            <Select
                id={`factory-production-select`}
                value={factoryProductId.toString()}
                onChange={handleChangeType}
                label="Factory Products"
                sx={{minWidth: "250px"}}
            >
                {factoryItems && 
                    factoryItems.map(map => 
                        <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
})