import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { ArticleListItem } from "../../app/models/Blog/Article";
import ArticleSummaryComponent from "../articles/components/ArticleSummaryComponent";
import { observer } from "mobx-react-lite";

export default observer(function HomePage() {
    const { arcticleStore } = useStore();
    const { loading, loadArticleList, articleList } = arcticleStore;

    useEffect(() => {
        loadArticleList();
    }, [loadArticleList])

    if (loading) return <LoadingComponent />

    return (
        <PageContainer>
            { articleList && 
                <Grid container spacing={2}>
                    {articleList.map((item: ArticleListItem) => (
                        <Grid item sm={12} md={4} key={item.slug}>
                            <ArticleSummaryComponent article={item} />
                        </Grid>
                    ))}
                </Grid>
            }
        </PageContainer>
        
    )
})