import { Typography } from "@mui/material";
import PageContainer from "../../app/common/page/PageContainer";


export default function NotLoggedIn() {
    return (
        <PageContainer pagetitle="Unauthorized">
            <Typography component="h1" variant="h6">
                Your session has expired and you are no longer logged in. Please logout and login again. 
            </Typography>
        </PageContainer>
    )
}