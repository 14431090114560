import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { AdvRefineryRequestItem, AdvRefineryResponse, advRefineryRequest } from "../../models/Factory/AdvRefinery";

export default class AdvRefineryStore {
    loading = false;
    locationId: number = JSON.parse(localStorage.getItem('locationId') as string) || 0;
    industryCertLevel: number = JSON.parse(localStorage.getItem('industryCertLevel') as string) || 0;
    metallurgyCertLevel: number = JSON.parse(localStorage.getItem('metallurgyCertLevel') as string) || 0;
    refineryCertLevel: number = JSON.parse(localStorage.getItem('refineryCertLevel') as string) || 0;
    requestList: AdvRefineryRequestItem[] | null = JSON.parse(localStorage.getItem('advRefineryList') || '[]');
    response: AdvRefineryResponse | null = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.locationId,
            locationId => {
                if(locationId) {
                    localStorage.setItem('locationId', locationId.toString());
                } else {
                    localStorage.setItem('locationId', "0");
                }
            }
        )

        reaction(
            () => this.industryCertLevel,
            industryCertLevel => {
                if(industryCertLevel) {
                    localStorage.setItem('industryCertLevel', industryCertLevel.toString());
                } else {
                    localStorage.setItem('industryCertLevel', "0");
                }
            }
        )

        reaction(
            () => this.metallurgyCertLevel,
            metallurgyCertLevel => {
                if(metallurgyCertLevel) {
                    localStorage.setItem('metallurgyCertLevel', metallurgyCertLevel.toString());
                } else {
                    localStorage.setItem('metallurgyCertLevel', "0");
                }
            }
        )

        reaction(
            () => this.refineryCertLevel,
            refineryCertLevel => {
                if(refineryCertLevel) {
                    localStorage.setItem('refineryCertLevel', refineryCertLevel.toString());
                } else {
                    localStorage.setItem('refineryCertLevel', "0");
                }
            }
        )

        reaction(
            () => this.requestList,
            requestList => {
                if(requestList) {
                    localStorage.setItem('advRefineryList', JSON.stringify(requestList));
                } else {
                    localStorage.setItem('advRefineryList', JSON.stringify([]));
                }
            }
        )
    }

    setLocationId = (locationId: number) => {
        this.locationId = locationId;
    }

    setIndustryCertLevel = (industryCertLevel: number) => {
        this.industryCertLevel = industryCertLevel;
    }

    setMetallurgyCertLevel = (metallurgyCertLevel: number) => {
        this.metallurgyCertLevel = metallurgyCertLevel;
    }

    setRefineryCertLevel = (refineryCertLevel: number) => {
        this.refineryCertLevel = refineryCertLevel;
    }

    addUpdate = (smr: AdvRefineryRequestItem) => {
        const smrs :  AdvRefineryRequestItem[] = JSON.parse(localStorage.getItem('advRefineryList') || '[]');

        if (smr.facilityId !== 0) {
            const existing = smrs.find(e => e.facilityId === smr.facilityId);

            if (existing !== undefined) {
                const index = smrs?.indexOf(existing);
                smrs.splice(index, 1); // Remove exisitng record
            }

            smrs.push(smr);            
        
            this.requestList = smrs;
        }
    }

    deleteItem = (id: number) => {
        const smrs :  AdvRefineryRequestItem[] = JSON.parse(localStorage.getItem('advRefineryList') || '[]');

        this.requestList = smrs.filter((smr) => smr.facilityId !== id);
    }

    resetList = () => {
        this.requestList = [];
    }
    
    loadList = async () => {
        this.loading = true;

        try {
            const requestDataList :  AdvRefineryRequestItem[] = JSON.parse(localStorage.getItem('advRefineryList') || '[]');


            const data = await agent.FactoryApi.getAdvRefinery(new advRefineryRequest({
                locationId: this.locationId,
                facilities: requestDataList,
                industryCertLevel: this.industryCertLevel,
                metallurgyCertLevel: this.metallurgyCertLevel,
                refiningCertLevel: this.refineryCertLevel
            }));

            runInAction(() => {
                this.response = data;
                this.loading = false;
            })

        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}