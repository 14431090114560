import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { FoundryCalculationPattern, FoundryRequestItem } from "../../../app/models/Foundry/Foundry";
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable";
import TableLink from "../../common/tables/TableLink";
import { useState } from "react";
import { SortingType, pageRows } from "../../../app/utils/globalConstants";
import _ from "lodash";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from "@mui/material";
import EditQuantityModal from "./EditQuantityModal";

export default observer(function PatternListComponent() {
    const { foundryCalculationStore } = useStore();
    const { loading, foundryCalculations, foundryRequestList, savePatterns } = foundryCalculationStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const [open, setOpen] = useState(false);
    const [patternId, setPatternId] = useState(0);
    const [pattern, setPattern] = useState('');
    const [patternQuantity, setPatternQuantity] = useState(0);

    const columns: IColumnType<FoundryCalculationPattern>[] = [
        {
            key: "name",
            title: "Pattern",
            sortLabel: "name",
            isSortable: true,
            render: (_, { slug, name}) => (
                <TableLink url={`/resources/${slug}`} name={name} />
            )
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "actions",
            title: "Actions",
            sortLabel: "actions",
            isSortable: false,
            align: "right",
            render: (_, { id, name, quantity }) => (
                <>
                    <Button variant="outlined" color="primary" startIcon={<EditIcon />} sx={{marginTop: "-3px", marginBottom: "-3px"}} onClick={() => handleEditPattern(id, name, quantity)} >
                        Edit
                    </Button>
                    <Button variant="outlined" color="error" startIcon={<DeleteForeverIcon />} sx={{marginTop: "-3px", marginBottom: "-3px"}} onClick={() => handleDeletePattern(id)} >
                        Delete
                    </Button>

                </>
            )
        }
    ]

    const handleEditPattern = (id: number, name: string, quantity: number) => {
        setPatternId(id);
        setPattern(name);
        setPatternQuantity(quantity);
        setOpen(true);
    }

    const handleClose = (id: number, quantity: number) => {
        setOpen(false);
        const updateData = new FoundryRequestItem({id: id, quantity: quantity});
        const requestList: FoundryRequestItem[] = JSON.parse(localStorage.getItem('foundryList') || '[]');

        savePatterns(requestList.map((item) => (item.id === id ? { ...item, ...updateData } : item)));

        setPatternId(0);
        setPattern('');
        setPatternQuantity(0);
    }

    const handleDeletePattern = (id: number) => {
       savePatterns(foundryRequestList?.filter((foundryRequestList) => foundryRequestList.id !== id));
    }

    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: foundryCalculations?.patterns?.length  ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    return (
        <>
            <CustomTable
                        data={_.orderBy(foundryCalculations?.patterns, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                        columns={columns}
                        pagination={pagination}
                        tableName="Foundry Patterns"
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        onSortChange={handleSortChange}
                        loading={loading}
                        emptyTableText="No Patterns Found"
                    />
            <EditQuantityModal id={patternId} pattern={pattern} quantity={patternQuantity} open={open} handleClose={handleClose} />
        </>
    )

})