import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { ModuleUploadForm, MyModuleItem } from "../../models/Schematics/Schematic";
import { store } from "../store";

export default class MySchematicsStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "name");
    myModules: MyModuleItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadMyModules = async (name: string, category: number, userId: string) => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (name != '') {
                axiosParams.append('name', name);
            }

            if (category != 0) {
                axiosParams.append('categoryId', category.toString());
            }

            if (userId !== '') {
                axiosParams.append('userId', userId);
            }

            const result = await agent.SchematicsApi.getMyModules(axiosParams);

            runInAction(() => {
                this.myModules = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })

        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    uploadModules = async(form: ModuleUploadForm) => {
        try {
            this.loading = true;
            await agent.SchematicsApi.upload(form);

            runInAction(() => {
                store.myBattleshipModulesStore.loadMyBsModules();
                this.loading = false;
            })
        } catch(error) {
            this.loading = false;
            throw error;
        }
    }
}