import { observer } from "mobx-react-lite"
import { useState } from "react"
import { FactoryCalcType, SortingType, pageRows } from "../../../../app/utils/globalConstants"
import { CustomTable, IColumnType, IPagination } from "../../../common/tables/CustomTable"
import { Button, Grid } from "@mui/material"
import _ from "lodash"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useStore } from "../../../../app/stores/store"
import EditFactoryFacilityModal from "./EditFactoryFacilityModal"
import { FactoryFacilityResponse } from "../../../../app/models/Factory/Factory"

interface Props {
    responseList: FactoryFacilityResponse[];
    loading: boolean;
}

export default observer(function FactoryRefineryFacilityList({ responseList, loading }: Props) {
    const { factoryCalcStore } = useStore();
    const { deleteItem } = factoryCalcStore;
    const [sortColumn, setSortColumn] = useState("facility");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const [open, setOpen] = useState(false);
    const [facilityId, setFacilityId] = useState(0);
    const [productionId, setProductionId] = useState(0);
    const [facility, setFacility]  = useState('');
    const [quantity, setQuantity] = useState(0);
    const [prodQuantity, setProdQuantity] = useState(0);
    const [prodType, setProdType] = useState(FactoryCalcType.Units);


    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: responseList?.length ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const handleClose = () => {
        setOpen(false);
    }

    const columns: IColumnType<FactoryFacilityResponse>[] = [
        {
            key: "facility",
            title: "Facility",
            sortLabel: "facility",
            isSortable: true,
            
        },
        {
            key: "productionName",
            title: "Product",
            sortLabel: "productionName",
            isSortable: true,
        },
        {
            key: "quantity",
            title: "Facility Count",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantityFacilities}) => (
                <>{quantityFacilities.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "productionQuantity",
            title: "Quantity",
            sortLabel: "productionQuantity",
            isSortable: true,
            align: "right",
            render: (_, { productionQuantity}) => (
                <>{productionQuantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "productionType",
            title: "Production Type",
            sortLabel: "productionType",
            isSortable: true,
            align: "right",
            render: (_, { productionType}) => (
                FactoryCalcType[productionType]
            )
        },
        {
            key: "actions",
            title: "Actions",
            sortLabel: "actions",
            isSortable: false,
            align: "right",
            render: (_, { facilityId, productionId, facility, quantityFacilities, productionQuantity, productionType }) => (
                <>
                    <Button variant="outlined" color="primary" startIcon={<EditIcon />} sx={{marginTop: "-3px", marginBottom: "-3px"}} onClick={() => handleEditFacility(facilityId, productionId, facility, quantityFacilities, productionQuantity, productionType)} >
                        Edit
                    </Button>
                    <Button variant="outlined" color="error" startIcon={<DeleteForeverIcon />} sx={{marginTop: "-3px", marginBottom: "-3px"}} onClick={() => handleDeleteFacility(facilityId, productionId)} >
                        Delete
                    </Button>

                </>
            )
        }
    ]

    const handleEditFacility = (facilityId: number, productionId: number, facility: string, quantity: number, productionQuantity: number, productionType: number) => {
        setFacilityId(facilityId);
        setProductionId(productionId);
        setFacility(facility);
        setQuantity(quantity);
        setProdQuantity(productionQuantity);
        setProdType(productionType);

        setOpen(true);
    }

    const handleDeleteFacility = (id: number, productId: number) => {
        deleteItem(id, productId);
    }

    return (
        <Grid container>
            <Grid item md={12} sm={12}>
            <CustomTable
                    data={_.orderBy(responseList, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    pagination={pagination}
                    tableName="Factory Facilities"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Factory Facilities found"
                />

            <EditFactoryFacilityModal facilityId={facilityId} productionId={productionId} facility={facility} quantity={quantity} prodQuantity={prodQuantity} prodType={prodType} open={open} handleClose={handleClose} />
            </Grid>
        </Grid>
    )    
})