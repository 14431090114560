import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";

import { useStore } from "../../../app/stores/store";
import { FoundryCubeForm } from "../../../app/models/Users/currentUser";
import { foundryCubeOptions } from "../../../app/utils/globalConstants";

interface Props {
    foundryId: string;
    setFoundryId: (reductionmatrixId: string) => void;
}

export default observer(function FoundryCubeFilter({ foundryId, setFoundryId }: Props) {
    const { isAuthenticated, user } = useAuth0();
    const { currentUserStore } = useStore();
    const { updateFoundryCube } = currentUserStore;

    const handleFoundryChange = (event: SelectChangeEvent) => {
        if (isAuthenticated) {
            const form : FoundryCubeForm = { foundryCube: event.target.value as string, userId: user?.id }
            updateFoundryCube(form)
        }
        setFoundryId(event.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="foundry-select">Locutus Cube</InputLabel>
            <Select
                id="foundry-select"
                value={foundryId!}
                onChange={handleFoundryChange}
                sx={{minWidth: "250px"}}
                label="Locutus Cube"
            >
                {foundryCubeOptions.map(map => 
                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                )}  
            </Select>
        </FormControl>
    )
})