import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';

import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Auth0ProviderWithConfig } from './app/utils/auth0-provider-with-config';
import { SnackbarProvider } from 'notistack';

import App from './app/App';
import './index.css';
import { store, StoreContext } from './app/stores/store';
import ThemeProvider from './app/utils/ThemeProvider';

export const history = createBrowserHistory();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StoreContext.Provider value={store}>
    <BrowserRouter>
      <Auth0ProviderWithConfig>
        <ThemeProvider>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <App />
            </SnackbarProvider>
          </Box>
        </ThemeProvider>
      </Auth0ProviderWithConfig>
    </BrowserRouter>
  </StoreContext.Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
