import { useAuth0 } from "@auth0/auth0-react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReductionMatrixForm } from "../../app/models/Users/currentUser";
import { useStore } from "../../app/stores/store";
import { reductionMatrixOptionList } from "../../app/utils/globalConstants";

interface Props {
    redMat: string;
    setRedMat: (redMat: string) => void;
}

export default observer(function ReductionMatrixListSelector({ redMat, setRedMat }: Props) {
    const { isAuthenticated, user } = useAuth0();
    const { currentUserStore } = useStore();
    const { updateReductionMatrix } = currentUserStore;


    const handleChangeRedMat = (event: SelectChangeEvent) => {
        if (isAuthenticated) {
            const form : ReductionMatrixForm = { reductionMatrix: event.target.value as string, userId: user?.id }
            updateReductionMatrix(form)
        }

        setRedMat(event.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="reductionMatrix-select">Reduction Matrix</InputLabel>
            <Select
                id="reductionMatrix-select"
                value={redMat}
                onChange={handleChangeRedMat}
                label="Reduction Matrix"
                sx={{minWidth: "250px"}}
            >
                {reductionMatrixOptionList.map(map => 
                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                )}   
            </Select>
        </FormControl>
    )
})