import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ItemListItem } from "../../../app/models/Items/Item";
import SelectShipComponent from "./SelectShipComponent";

interface Props {
    title: string;
    isRequired: boolean;
    components: ItemListItem[];
    selectedItem: number;
    type: string;
    setComponent: (type: string, id: number) => void;
}

export default observer(function ShipComponentSelector({ title, isRequired, components, selectedItem, setComponent, type }: Props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
      };

    const handleClose = (id: number = 0) => {
        if (!Number.isInteger(id)) {
            id = selectedItem;
        }

        setComponent(type, id);
        setOpen(false);
    };

    function getSelectedItem() {
        return components.find(e => e.id === selectedItem);
    }

    return (
       <Card sx={{ margin: "5px", borderRadius: "0", border: 1, borderColor: selectedItem === 0 ? isRequired ? "error.main" : "primary.main" : "success.main" }}>
        <CardHeader title={title} />
        <CardContent>
            <Typography variant="h5" component="div">
                {selectedItem === 0 ? `Please select ${title}` : getSelectedItem()?.name}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small" onClick={handleOpen}>{selectedItem === 0 ? "Select" : "Change"}</Button>
            { selectedItem !== 0 && 
                <Button size="small" onClick={() => setComponent(type, 0)}>Remove</Button>
            }
            <SelectShipComponent title={title} items={components} open={open} handleClose={handleClose} />
        </CardActions>
       </Card>
    )
})