import { createContext, useContext } from "react";
import ArticleAdminStore from "./Blog/articleAdminStore";
import CommonStore from "./commonStore"
import CurrentUserStore from "./User/currentUserStore";
import RegistrationStore from "./User/registrationStore";
import UserAdminStore from "./User/userAdminStore";
import UserAuditStore from "./User/userAuditStore";
import FaqAdminStore from "./Blog/faqAdminStore";
import TimelineAdminStore from "./Blog/timelineAdminStore";
import UserLiteStore from "./User/userLiteStore";
import ArticleStore from "./Blog/articleStore";
import FaqStore from "./Blog/faqStore";
import TimelineStore from "./Blog/timelineStore";
import StoreStore from "./Stores/storeStore";
import GalaxyStore from "./Locations/GalaxyStore";
import GalaxyLiteStore from "./Locations/GalaxyLiteStore";
import LocationStore from "./Locations/LocationStore";
import LocationLiteStore from "./Locations/LocationLiteStore";
import SolarSystemStore from "./Locations/SolarSystemStore";
import SolarSystemLiteStore from "./Locations/SolarSystemLiteStore";
import StoreTypeStore from "./Stores/StoreTypeStore";
import StoreLocationStore from "./Stores/StoreLocationStore";
import StoreLiteStore from "./Stores/StoreLiteStore";
import ResourceStore from "./Resources/ResourceStore";
import ResourceStoreStore from "./Resources/ResourceStoreStore";
import ResourceGbmStore from "./Resources/ResourceGbmStore";
import MyResourceStore from "./Resources/MyResourcesStore";
import SchematicStore from "./Schematics/SchematicStore";
import CategoryStore from "./Categories/CategoriesStore";
import ResourceSchematicsStore from "./Resources/ResourceSchematicsStore";
import SchematicParentsStore from "./Schematics/SchematicParentsStore";
import SchematicChildrenStore from "./Schematics/SchematicChildrenStore";
import CraftingStore from "./crafting/CraftingStore";
import ItemStore from "./Items/ItemStore";
import ShipBuilderStore from "./ShipBuilder/ShipBuilderStore";
import StarbaseBuilderStore from "./StarbaseBuilder/StarbaseBuilderStore";
import BlueprintStore from "./Blueprints/BlueprintStore";
import BlueprintParentsStore from "./Blueprints/BlueprintParentsStore";
import ResourceBlueprintsStore from "./Resources/ResourceBlueprintsStore";
import SchematicLiteStore from "./Schematics/SchematicLiteStore";
import ResourcesLiteStore from "./Resources/ResourcesLiteStore";
import MyResourceStatsStore from "./Resources/MyResourceStatsStore";
import MySchematicsStore from "./Schematics/MySchematicsStore";
import MyBattleshipModuleStore from "./Schematics/MyBattleshipModuleStore";
import FoundryPatternStore from "./Foundry/FoundryPatternStore";
import FoundryResourcesStore from "./Foundry/FoundryResourcesStore";
import FoundryCalculationStore from "./Foundry/FoundryCalculationStore";
import MyItemsStore from "./Items/MyItemsStore";
import HeavyFuelStore from "./Factories/HeavyFuelStore";
import FactoryStore from "./Factories/FactoryStore";
import SmrStore from "./Factories/SmrStore";
import AdvRefineryStore from "./Factories/AdvRefineryStore";
import FactoryByResourceStore from "./Factories/FactoryByResourceStore";
import RefineryStore from "./Factories/RefinertyStore";
import FactoryCalcStore from "./Factories/FactoryCalcStore";
import FactoryProductStore from "./Factories/FactoryProductStore";

interface Store {
    commonStore: CommonStore;

    currentUserStore: CurrentUserStore;
    registrationStore: RegistrationStore;

    userAuditStore: UserAuditStore;
    userAdminStore: UserAdminStore;
    userLiteStore: UserLiteStore;

    articleAdminStore: ArticleAdminStore;
    arcticleStore: ArticleStore;

    faqAdminStore: FaqAdminStore;
    faqStore: FaqStore;

    timelineAdminStore: TimelineAdminStore;
    timelineStore: TimelineStore;

    storeStore: StoreStore;
    storeLiteStore: StoreLiteStore;
    storeTypeStore: StoreTypeStore;
    storeLocationStore: StoreLocationStore;

    galaxyStore: GalaxyStore;
    galaxyLiteStore: GalaxyLiteStore;

    locationStore: LocationStore;
    locationLiteStore: LocationLiteStore;

    solarSystemStore: SolarSystemStore;
    solarSystemLiteStore: SolarSystemLiteStore;

    resourcesStore: ResourceStore;
    resourcesLiteStore: ResourcesLiteStore;
    resourceStoreStore: ResourceStoreStore;
    resourceGbmStore: ResourceGbmStore;
    myResourcesStore: MyResourceStore;
    myResourceStatsStore: MyResourceStatsStore;
    resourceSchematicsStore: ResourceSchematicsStore;
    resourceBlueprintsStore: ResourceBlueprintsStore;

    schematicStore: SchematicStore;
    schematicParentsStore: SchematicParentsStore;
    schematicChildrenStore: SchematicChildrenStore;
    schematicLiteStore: SchematicLiteStore;
    mySchematicsStore: MySchematicsStore;
    myBattleshipModulesStore: MyBattleshipModuleStore;

    craftingStore: CraftingStore;

    categoryStore: CategoryStore;

    itemStore: ItemStore;
    myItemsStore: MyItemsStore;

    shipBuilderStore: ShipBuilderStore;

    starbaseBuilderStore: StarbaseBuilderStore;

    blueprintStore: BlueprintStore;
    blueprintParentStore: BlueprintParentsStore;

    foundryPatternStore: FoundryPatternStore;
    foundryResourcesStore: FoundryResourcesStore;
    foundryCalculationStore: FoundryCalculationStore;

    factoryStore: FactoryStore;
    factoryByResourceStore: FactoryByResourceStore;
    factoryProductStore: FactoryProductStore;
    heavyFuelStore: HeavyFuelStore;
    smrStore: SmrStore;
    advRefineryStore: AdvRefineryStore;
    refineryStore: RefineryStore;
    factoryCalcStore: FactoryCalcStore;
}

export const store: Store = {
    commonStore: new CommonStore(),

    currentUserStore: new CurrentUserStore(),
    registrationStore: new RegistrationStore(),

    userAuditStore: new UserAuditStore(),
    userAdminStore: new UserAdminStore(),
    userLiteStore: new UserLiteStore(),

    articleAdminStore: new ArticleAdminStore(),
    arcticleStore: new ArticleStore(),

    faqAdminStore: new FaqAdminStore(),
    faqStore: new FaqStore(),

    timelineAdminStore: new TimelineAdminStore(),
    timelineStore: new TimelineStore(),

    storeStore: new StoreStore(),
    storeLiteStore: new StoreLiteStore(),
    storeTypeStore: new StoreTypeStore(),
    storeLocationStore: new StoreLocationStore(),

    galaxyStore: new GalaxyStore(),
    galaxyLiteStore: new GalaxyLiteStore(),

    locationStore: new LocationStore(),
    locationLiteStore: new LocationLiteStore(),

    solarSystemStore: new SolarSystemStore(),
    solarSystemLiteStore: new SolarSystemLiteStore(),

    resourcesStore: new ResourceStore(),
    resourcesLiteStore: new ResourcesLiteStore(),
    resourceStoreStore: new ResourceStoreStore(),
    resourceGbmStore: new ResourceGbmStore(),
    myResourcesStore: new MyResourceStore(),
    myResourceStatsStore: new MyResourceStatsStore(),
    resourceSchematicsStore: new ResourceSchematicsStore(),
    resourceBlueprintsStore: new ResourceBlueprintsStore(),

    schematicStore: new SchematicStore(),
    schematicParentsStore: new SchematicParentsStore(),
    schematicChildrenStore: new SchematicChildrenStore(),
    schematicLiteStore: new SchematicLiteStore(),
    mySchematicsStore: new MySchematicsStore(),
    myBattleshipModulesStore: new MyBattleshipModuleStore(),

    craftingStore: new CraftingStore(),

    categoryStore: new CategoryStore(),

    itemStore: new ItemStore(),
    myItemsStore: new MyItemsStore(),

    shipBuilderStore: new ShipBuilderStore(),

    starbaseBuilderStore: new StarbaseBuilderStore(),

    blueprintStore: new BlueprintStore(),
    blueprintParentStore: new BlueprintParentsStore(),

    foundryPatternStore: new FoundryPatternStore(),
    foundryResourcesStore: new FoundryResourcesStore(),
    foundryCalculationStore: new FoundryCalculationStore(),

    factoryStore: new FactoryStore(),
    factoryByResourceStore: new FactoryByResourceStore(),
    factoryProductStore: new FactoryProductStore(),
    heavyFuelStore: new HeavyFuelStore(),
    smrStore: new SmrStore(),
    advRefineryStore: new AdvRefineryStore(),
    refineryStore: new RefineryStore(),
    factoryCalcStore: new FactoryCalcStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}