import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { ResourceItem, ResourceLiteListItem } from "../../models/Resources/Resource";

export default class ResourceStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(-1, 0, "name");
    resourceItems: ResourceLiteListItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadResourceList = async (type: number = 0, name: string) => {
        this.loading = true;

        try {
            var axiosParams = this.axiosParams;

            if (type !== 0) {
                axiosParams.append('type', type.toString());
            }

            if (name !== "") {
                axiosParams.append('name', name);
            }

            const result = await agent.ResourcesApi.getLite(axiosParams);

            runInAction(() => {
                this.resourceItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}