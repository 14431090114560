import { observer } from "mobx-react-lite";
import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";
import HomeIcon from "@mui/icons-material/Home";
import MyShipsIcon from "@mui/icons-material/Rocket";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import { Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography, Link as MuiLink, Grid, Button, IconButton, TableSortLabel, Box } from "@mui/material";
import { ShipDesign } from "../../app/models/ShipBuilder/ShipBuilder";
import * as _ from "lodash";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { Link } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { visuallyHidden } from '@mui/utils';
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function MyShips() {
    const { shipBuilderStore } = useStore();
    const { loading, loadShipDesigns, shipDesigns, deleteShipDesign } = shipBuilderStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);   
    const { isAuthenticated, loginWithRedirect} = useAuth0();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Ship Builder", "pageurl": "/shipbuilder", "active": true, icon: <MyShipsIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]
    
    useEffect(() => {
        if (!isAuthenticated) {
            handleLogin();
        }
    })

    const handleLogin = async () => {
        await loginWithRedirect();
    }

    useEffect(() => {
        loadShipDesigns();
    }, [loadShipDesigns])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        setPage(newPage);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
            } else {
                setSortOrder(SortingType.asc);
            }
            setSortColumn(column);
        }
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTake(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleDeleteShip(id: number) {
        deleteShipDesign(id).then(loadShipDesigns);
    }

    return (
        <PageContainer pagetitle="My Ships" breadcrumbs={breadcrumbs}>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="primary" component={Link} variant="contained" to='/shipbuilder/build'>Design Ship</Button>
                </Grid>
            </Grid>
            <TableContainer>
                        <Table aria-label="Starbase component select table">
                        <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "name"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("name")}
                                        >
                                            Ship
                                            {sortColumn === "name" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.armour"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.armour")}
                                        >
                                            Armour
                                            {sortColumn === "summary.armour" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.hull"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.hull")}
                                        >
                                            Hull
                                            {sortColumn === "summary.hull" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.energy"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.energy")}
                                        >
                                            Energy
                                            {sortColumn === "summary.energy" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.cargo"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.cargo")}
                                        >
                                            Cargo
                                            {sortColumn === "summary.cargo" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.speed"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.speed")}
                                        >
                                            Speed
                                            {sortColumn === "summary.speed" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.specials"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.specials")}
                                        >
                                            Specials
                                            {sortColumn === "summary.specials" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.pods"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.pods")}
                                        >
                                            Pods
                                            {sortColumn === "summary.pods" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.level"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.level")}
                                        >
                                            Level
                                            {sortColumn === "summary.level" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.skillBonus"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.skillBonus")}
                                        >
                                            Skill+
                                            {sortColumn === "summary.skillBonus" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.buildTime"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.buildTime")}
                                        >
                                            Build Time
                                            {sortColumn === "summary.buildTime" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { shipDesigns ?  _.orderBy(shipDesigns, [sortColumn], [sortOrder == SortingType.asc ? 'asc' : 'desc']).slice(page * take, page * take + take).map((item: ShipDesign) => (
                                    <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell>
                                            <MuiLink
                                                component={Link}
                                                to={`/shipbuilder/build/${item.id}`}
                                                color="inherit"
                                            >
                                                {item.name}
                                            </MuiLink>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.armour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.hull.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.energy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.cargo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.speed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.specials.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.pods.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.level.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.skillBonus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{`${item.summary.buildTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hrs`}</StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton aria-label="delete" color="error" onClick={() => handleDeleteShip(item.id)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell colSpan={12}>
                                            { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No ships found</Typography> }
                                        </StyledTableCell>
                                    </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={shipDesigns?.length || 0}
                        page={page}
                        rowsPerPage={take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
        </PageContainer>
    )
})