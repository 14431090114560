import React from "react";
import { Table as MuiTable, TableBody as MuiTableBody, TableContainer, TableHead as MuiTableHead, TablePagination, Typography } from "@mui/material";

import { SortingType, StyledTableCell, StyledTableRow, pageRows } from "../../../app/utils/globalConstants";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import LoadingComponent from "../../../app/common/page/LoadingComponent";

export interface IColumnType<T> {
    key: string;
    title: string;
    sortLabel: string;
    isSortable: boolean;
    align?: string;
    width?: number;
    render?: (column: IColumnType<T>, item: T) => void;
}

export interface IPagination {
    rowsPerPage: number;
    count: number;
    page: number;
    onPageChange: (page: number) => void;
    onRowsPerPageChange: (rows: number) => void;
}

interface Props<T> {
    data: T[];
    columns: IColumnType<T>[];
    pagination: IPagination;
    tableName: string;
    sortColumn: string;
    sortOrder: SortingType;
    loading: boolean;
    emptyTableText: string;
    onSortChange: (column: string, sortOrder: SortingType) => void;
}

export function CustomTable<T>({ data, columns, pagination, tableName, sortColumn, sortOrder, loading, emptyTableText, onSortChange }: Props<T>): JSX.Element {

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagination.onPageChange(newPage);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagination.onRowsPerPageChange(parseInt(event.target.value, 10))
    }

    return (
        <>
            <TableContainer>
                <MuiTable aria-label={tableName}>
                    <MuiTableHead>
                        <TableHeader 
                            columns={columns} 
                            sortColumn={sortColumn}
                            sortOrder={sortOrder}
                            onSortChange={onSortChange}
                        />
                    </MuiTableHead>
                    <MuiTableBody>

                        {data ? 
                            <TableRow data={data} columns={columns} /> :
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={columns.length}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">{emptyTableText}</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>
                        }
                    </MuiTableBody>
                </MuiTable>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={pageRows}
                component="div"
                count={pagination.count}
                page={pagination.page}
                rowsPerPage={pagination.rowsPerPage}
                showFirstButton={true}
                showLastButton={true}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}