import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { FaqForm, FaqItem } from "../../models/Blog/Faq";
import { PublishForm } from "../../models/common";
import { PagingParams } from "../../models/pagination";

export default class FaqAdminStore {
    faq: FaqItem | null = null;
    faqList: FaqItem[] = [];

    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "-changedDateTime");

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadFaqList = async () => {
        this.loading = true;

        try {
            const result = await agent.FaqApi.getList(this.axiosParams);

            runInAction(() => {
                this.faqList = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadFaq = async (id: string) => {
        this.loading = true;

        try {
            const faq = await agent.FaqApi.get(id);

            runInAction(() => {
                this.faq = faq;
                this.loading = false;
            })
            return faq;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    createFaq = async(faq: FaqForm) => {
        try {
            await agent.FaqApi.create(faq);
        } catch(error) {
            throw error;
        }
    }

    updateFaq = async(faq: FaqForm) => {
        try {
            await agent.FaqApi.update(faq);
        } catch(error) {
            throw error;
        }
    }

    deleteFaq = async(id: number) => {
        try {
            await agent.FaqApi.delete(id);
        } catch(error) {
            throw error;
        }
    }

    publishFaq = async(publish: PublishForm) => {
        try {
            await agent.FaqApi.publish(publish);
        } catch(error) {
            throw error;
        }
    }
}