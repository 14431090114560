import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Box, Button, FormControl, Grid, Paper, Tab, Tabs, TextField } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import FoundryIcon from '@mui/icons-material/Factory';

import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import FoundryResourceFilter from "../common/filters/FoundryResourceFilter";
import FoundryCubeFilter from "../common/filters/FoundryCubeFilter";
import { useStore } from "../../app/stores/store";
import { FoundryRequest, FoundryRequestItem } from "../../app/models/Foundry/Foundry";
import { TabPanel, a11yProps } from "../../app/utils/TabUtils";
import PageHeader from "../../app/common/page/PageHeader";
import PatternListComponent from "./Components/PatternListComponent";
import NanoPatternListComponent from "./Components/NanoPatternListComponent";
import UaPatternListComponent from "./Components/UaPatternListComponent";
import ItemsUploadComponent from "./Components/ItemsUploadComponent";
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function FoundryCalculatorPage() {
    const { commonStore, foundryCalculationStore } = useStore();
    const { foundry, setFoundry } = commonStore;
    const { loadPatternList, savePatterns, foundryRequestList, resetPatterns } = foundryCalculationStore;
    const [selectedId, setSelectedId] = useState(0);
    const [value, setValue] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const { isAuthenticated } = useAuth0();
    
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Foundry Calculator", "pageurl": "/foundry/calculator", "active": true, icon: <FoundryIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        loadPatternList(new FoundryRequest({
            foundryCube: foundry!,
            patterns: foundryRequestList!
        }));
    }, [loadPatternList, foundry])

    const handleAdd = () => {
        const exists = foundryRequestList?.find(e => e.id == selectedId);

        if (selectedId !== 0 &&  !exists) {
            const requestList = JSON.parse(localStorage.getItem('foundryList') || '[]');
            
            requestList.push(new FoundryRequestItem({
                id: selectedId,
                quantity: quantity
            }));

            savePatterns(requestList);
        }
    }

    const handleReset = () => {
        resetPatterns();
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const handleOnUpload = () => {
        loadPatternList(new FoundryRequest({
            foundryCube: foundry!,
            patterns: foundryRequestList!
        }));
    }

    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);
        input < 1 ? setQuantity(1) : setQuantity(input);
    }

    return (
            <PageContainer pagetitle="Foundry Calculator" breadcrumbs={breadcrumbs}>
                <Grid container>
                    <Grid item lg={2} md={3} sm={12} minWidth="270px">
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                m: 1,
                                width: 270,
                                padding: '10px',
                                marginLeft: '-5px'
                                },
                            }}
                        >
                            <Paper elevation={1}>
                                <Button
                                    id="btn-reset"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    sx={{width: "250px", marginLeft: '-1px'}}
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>  
                            </Paper>
                            <Paper>
                                <FoundryCubeFilter foundryId={foundry!} setFoundryId={setFoundry} />
                            </Paper>
                            <Paper>
                                <FoundryResourceFilter setPatternId={setSelectedId} />
                                <FormControl>
                                    <TextField 
                                        id="Quantity-input"
                                        label="Quantity"
                                        variant="outlined"
                                        type="number"
                                        value={quantity}
                                        onChange={handleChangeQuantity}
                                        sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                                    />
                                </FormControl> 
                                <Button
                                    id="btn-add"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    sx={{width: "250px", marginLeft: '-1px', marginTop: '10px'}}
                                    onClick={handleAdd}
                                >
                                    Add
                                </Button>  
                            </Paper>
                            { isAuthenticated &&
                                <Paper>
                                    <ItemsUploadComponent handleOnUpload={handleOnUpload} />
                                </Paper>
                            }
                        </Box>
                    </Grid>
                    <Grid item lg={9} md={8} sm={12}>
                        <Grid>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="foundry tabs"
                                variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Patterns" {...a11yProps(0)} />
                                <Tab label="Nano Metals" {...a11yProps(1)} />
                                <Tab label="U.A Metals/Minerals" {...a11yProps(2)} />
                            </Tabs>
                        </Grid>
                        <Grid>
                            <TabPanel value={value} index={0}>
                                <PageHeader pagetitle="Patterns" />
                                <PatternListComponent />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <PageHeader pagetitle="Nano Metals" />
                                <NanoPatternListComponent />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <PageHeader pagetitle="U.A Metals/Minerals" />
                                <UaPatternListComponent />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Grid>
            </PageContainer>
    )
})

