import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    resourceGroupById: string;
    setResourceGroupById: (resourcegroupbyId: string) => void;
}

export default observer(function ResourceGroupByFilter({ resourceGroupById, setResourceGroupById }: Props) {

    const handleChangeGroup = (event: SelectChangeEvent) => {
        setResourceGroupById(event.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="resourceGroupBy-select">Group By</InputLabel>
            <Select
                id="resourceGroupBy-select"
                value={resourceGroupById}
                onChange={handleChangeGroup}
                label="Group By"
                sx={{minWidth: "250px"}}
            >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="location">Location</MenuItem> 
            </Select>
        </FormControl>
    )
})