import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, SelectChangeEvent, Grid, FormControl, Select, MenuItem, InputLabel, TableSortLabel, Box, Typography } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { LocationItem } from '../../../../app/models/Locations/location'
import LocationType from "./LocationType";
import { visuallyHidden } from '@mui/utils';

export default observer(function LocationList() {
    const { locationStore, galaxyLiteStore, solarSystemLiteStore } = useStore();
    const { loadingLocations: loadingGalaxies, pagingParamsLocation, totalLocationCount, locationItems, loadLocationList } = locationStore;
    const { galaxyLiteItems, loadGalaxyList} = galaxyLiteStore;
    const { solarSystemLiteItems, loadSolarSystemList} = solarSystemLiteStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [galaxyId, setGalaxyId] = useState(0);
    const [solarSystemId, setSolarSystemId] = useState(0);
    
    useEffect(() => {
        loadLocationList(galaxyId, solarSystemId);

    }, [loadLocationList])

    useEffect(() => {
        loadGalaxyList();
    }, [loadGalaxyList])

    useEffect(() => {
        loadSolarSystemList(galaxyId);
    }, [loadSolarSystemList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParamsLocation.skip = newPage * pagingParamsLocation.take;
        setPage(newPage);
        loadLocationList(galaxyId, solarSystemId);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParamsLocation.take = parseInt(event.target.value, 10);
        pagingParamsLocation.skip = 0;
        loadLocationList(galaxyId, solarSystemId);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParamsLocation.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParamsLocation.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParamsLocation.sort = column;
            }
            setSortColumn(column);
        }
        loadLocationList(galaxyId, solarSystemId);
    }

    const handleGalaxyChange = (event: SelectChangeEvent) => {
        setGalaxyId(parseInt(event.target.value));
        setSolarSystemId(0);
        loadLocationList(parseInt(event.target.value), 0);
        loadSolarSystemList(parseInt(event.target.value));
        
    }

    const handleSolarSystemChange = (event: SelectChangeEvent) => {
        setSolarSystemId(parseInt(event.target.value));
        loadLocationList(galaxyId, parseInt(event.target.value));
    }

    if (loadingGalaxies) return <LoadingComponent />

    return (
            <>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="galaxy-select">Galaxy</InputLabel>
                            <Select
                                id="galaxy-select"
                                value={galaxyId.toString()}
                                onChange={handleGalaxyChange}
                                label="Galaxy"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {galaxyLiteItems.map(map => 
                                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                                )} 
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="solarSystem-select">Solar System</InputLabel>
                            <Select
                                id="solarSystem-select"
                                value={solarSystemId.toString()}
                                onChange={handleSolarSystemChange}
                                label="Solar System"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {solarSystemLiteItems.map(map => 
                                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                                )} 
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Locations table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Location
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "type"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("type")}
                                    >
                                        Type
                                        {sortColumn === "type" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "owner"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("owner")}
                                    >
                                        Owner
                                        {sortColumn === "owner" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "slug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("slug")}
                                    >
                                        Slug
                                        {sortColumn === "slug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "solarSystem"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("solarSystem")}
                                    >
                                        Solar System
                                        {sortColumn === "solarSystem" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "galaxy"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("galaxy")}
                                    >
                                        Galaxy
                                        {sortColumn === "galaxy" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "law"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("law")}
                                    >
                                        Law
                                        {sortColumn === "law" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "tech"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("tech")}
                                    >
                                        Tech
                                        {sortColumn === "tech" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {locationItems ? locationItems.map((item: LocationItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/locations/${item.id}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell><LocationType locType={item.type} /></StyledTableCell>
                                    <StyledTableCell>{item.owner}</StyledTableCell>
                                    <StyledTableCell>{item.slug}</StyledTableCell>
                                    <StyledTableCell>{item.solarSystemName}</StyledTableCell>
                                    <StyledTableCell>{item.galaxyName}</StyledTableCell>
                                    <StyledTableCell>{item.law}</StyledTableCell>
                                    <StyledTableCell>{item.tech}</StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))  : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={9}>
                                    { loadingGalaxies ? <LoadingComponent /> : <Typography variant="body2" component="div">No locations found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalLocationCount}
                    page={page}
                    rowsPerPage={pagingParamsLocation.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

