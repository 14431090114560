import { FactoryCalcType } from "../../utils/globalConstants";

export interface FactoryLiteItem {
    id: number;
    name: string;
}

export interface FactoryByResourceItem {
    id: number;
    itemName: string;
    category: string;
    subCategory: string;
    quantity: number;
    duration: number;
}

export class FactoryRequest {
    facilities: FactoryRequestItem[] = [];
    locationId: number = 0;
    boost: number = 0;

    constructor(form?: FactoryRequest) {
        if (form) {
            this.facilities = form.facilities;
            this.locationId = form.locationId;
            this.boost = form.boost;
        }
    }
}

export class FactoryRequestItem {
    facilityId: number = 0;
    productionId: number = 0;
    quantityFacilities: number = 0;
    productionQuantity: number = 0;
    calcType: FactoryCalcType = 1;

    constructor(form?: FactoryRequestItem) {
        if (form){
            this.facilityId = form.facilityId;
            this.productionId = form.productionId;
            this.quantityFacilities = form.quantityFacilities;
            this.productionQuantity = form.productionQuantity;
            this.calcType = form.calcType;
        }
    }
}

export interface FactoryResponse {
    facilities: FactoryFacilityResponse[];
    production: FactoryProductionResponse[];
    requirements: FactoryRequirementResponse[];
}

export interface FactoryFacilityResponse {
    facilityId: number;
    facility: string;
    productionId: number;
    productionName: string;
    quantityFacilities: number;
    productionQuantity: number;
    productionType: FactoryCalcType;
}

export interface FactoryProductionResponse {
    productionId: number;
    productionSlug: string;
    productionName: string;
    isItem: boolean;
    quantity: number;
    duration: number;
    productionType: FactoryCalcType;
}

export interface FactoryRequirementResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}