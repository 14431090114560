import { useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumb } from "../../../app/models/common";
import LocationIcon from "@mui/icons-material/Public";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import { history } from "../../..";
import PageContainer from "../../../app/common/page/PageContainer";
import GalaxiesList from "./galaxies/GalaxiesList";
import SolarSystemList from "./solarSystems/SolarSystemList";
import LocationList from "./locations/LocationList";
import PageHeader from "../../../app/common/page/PageHeader";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default function LocationPage() {
    const { user } = useAuth0();
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Locations", "pageurl": "/admin/locations", "active": true, icon: <LocationIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return (
        <PageContainer breadcrumbs={breadcrumbs}>
            <Grid>
                <Grid item>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="locations Menu"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Galaxies" {...a11yProps(0)} />
                        <Tab label="Solar Systems" {...a11yProps(1)} />
                        <Tab label="Locations" {...a11yProps(2)} />
                    </Tabs>
                </Grid>
                <Grid item>
                    <TabPanel value={value} index={0}>
                        <PageHeader pagetitle="Galaxies" />
                        <GalaxiesList />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PageHeader pagetitle="Solar Systems" />
                        <SolarSystemList />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PageHeader pagetitle="Locations" />
                        <LocationList />
                    </TabPanel>
                </Grid>
            </Grid>
        </PageContainer>
        
    )
}