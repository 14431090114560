import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, TableSortLabel, Box, Typography } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { GalaxyItem } from '../../../../app/models/Locations/galaxy'
import { visuallyHidden } from '@mui/utils';

export default observer(function GalaxyList() {
    const { galaxyStore } = useStore();
    const { loadingGalaxies, pagingParamsGalaxy, totalGalaxyCount, galaxyItems, loadGalaxyList } = galaxyStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    
    useEffect(() => {
        loadGalaxyList();

    }, [loadGalaxyList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParamsGalaxy.skip = newPage * pagingParamsGalaxy.take;
        setPage(newPage);
        loadGalaxyList();
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParamsGalaxy.take = parseInt(event.target.value, 10);
        pagingParamsGalaxy.skip = 0;
        loadGalaxyList();
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParamsGalaxy.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParamsGalaxy.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParamsGalaxy.sort = column;
            }
            setSortColumn(column);
        }
        loadGalaxyList();
    }

    if (loadingGalaxies) return <LoadingComponent />

    return (
            <>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Galaxies table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Galaxy
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "slug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("slug")}
                                    >
                                        Slug
                                        {sortColumn === "slug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "currencyCode"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("currencyCode")}
                                    >
                                        Currency Code
                                        {sortColumn === "currencyCode" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {galaxyItems ? galaxyItems.map((item: GalaxyItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/galaxies/${item.id}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{item.slug}</StyledTableCell>
                                    <StyledTableCell>{item.currencyCode}</StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={4}>
                                    { loadingGalaxies ? <LoadingComponent /> : <Typography variant="body2" component="div">No galaxies found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalGalaxyCount}
                    page={page}
                    rowsPerPage={pagingParamsGalaxy.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

