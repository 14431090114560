import { Autocomplete, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    setResourceId: (resourceId: number) => void;
}

export default observer(function ResourceFilter({ setResourceId }: Props) {
    const { resourcesLiteStore } = useStore();
    const { resourceItems, loadResourceList } = resourcesLiteStore;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        loadResourceList(0, '');
    }, [loadResourceList])

    const handleChangeResource = (resource: string | null) => {
        if (resource === null) {
            setResourceId(0);            
        } else {
            var selectId = resourceItems.find(e => e.name === resource);

            if(selectId === undefined) {
                setResourceId(0);
            } else {
                setResourceId(selectId.id);
            }
        }
    }

    return (
        <FormControl>
            <Autocomplete
                id="resources-select"
                onChange={(event: any, newValue) => {
                    handleChangeResource(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={resourceItems.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Resources" />}
            />
        </FormControl>
    )
})