import { observer } from "mobx-react-lite";
import { Alert, Button, ListItem, Modal, Paper, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";

import { useStore } from "../../../app/stores/store";
import MyTextAreaInput from "../../../app/common/form/MyTextAreaInput";
import LoadingComponent from "../../../app/common/page/LoadingComponent";
import { ItemUploadForm } from "../../../app/models/Items/Item";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export default observer(function MyItemsUploadModal({open, handleClose} : Props) {
    const { myItemsStore } = useStore();
    const { uploadItems, loading } = myItemsStore;

    function handleFormSubmit(form: ItemUploadForm) {
        uploadItems(form).then(() => handleClose());
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={3} sx={{ maxWidth: "90%", padding: "10px", paddingRight: "25px", border: "1px solid #000", position: "absolute", left: "5%", right: "5%", top: "2%"}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Upload my Misc Items
                </Typography>
                <Typography id="modal-model-description">

                    <Alert severity="info" variant="filled" sx={{ marginRight: "-5px"}}>
                        <Typography variant="body2" component="div">
                            Please copy and past everything (The entire page content - [CTRL] + A, then [CTRL] + C) from one of the following places: 
                                <ListItem>Secure Storage Misc (Secure Store --&gt; Misc)</ListItem>
                        </Typography>
                    </Alert>
                </Typography>

                    { loading ? <LoadingComponent /> :
                        <Formik
                            initialValues={{myItems: "", error: null}}
                            onSubmit={values => handleFormSubmit(values)}
                        >
                            {({handleSubmit, isSubmitting, isValid, dirty, errors, values}) => (
                                <Form className="form" onSubmit={handleSubmit} autoComplete='off'>
                                    <MyTextAreaInput name="myItems" placeholder="My Items" label="Items data" help={errors.myItems} rows={15} />
                                    <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                                                    
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!isValid || !dirty || isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        type="submit"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    }
                
            </Paper>
        </Modal>    
    )
})

