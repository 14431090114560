import {Link as RouterLink } from 'react-router-dom';
import { Badge, Button, IconButton, Link, Toolbar, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CraftingIcon from '@mui/icons-material/Construction';
import LoginMenu from './LoginMenu';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme, open }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(33,33,33)' : '#3a3f44',
  borderBottom: theme.palette.mode === 'dark' ? 'solid #444 1px' : 'solid #fff 1px', 
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "100%",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface MainMenuProps {
  open: boolean;
  toggleDrawer: () => void;
}

export default observer(function MainMenu({open, toggleDrawer}: MainMenuProps) {
  const { craftingStore } = useStore();
  const { schematicList } = craftingStore;


  return (
    <AppBar 
          position="fixed"
          open={open}
          elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Open menu"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
            }}
          >
            <MenuIcon />
          </IconButton>
          
            <Typography
              component="h1"
              variant="h6"
              color="inferit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <Link component={RouterLink} to='/' color="inherit">
                Project Eagle
              </Link>
              
            </Typography>

            <Badge color="success" badgeContent={schematicList?.length} variant="standard" showZero>
              <Button color="inherit" component={RouterLink} startIcon={<CraftingIcon />} to='/crafting' sx={{marginTop: "-3px", marginBottom: "-3px"}}>
                Crafting List
              </Button>
            </Badge>
            
            <LoginMenu />
        </Toolbar>
      </AppBar>
  );
})