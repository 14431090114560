import { Alert, Button, FormGroup, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as Yup from 'yup';
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { CheckboxWithLabel } from "formik-material-ui";
import ReactMarkdown from "react-markdown";
import { useStore } from "../../app/stores/store";
import { ArticleForm } from "../../app/models/Blog/Article";
import { Breadcrumb } from "../../app/models/common";
import { history } from "../..";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import PageContainer from "../../app/common/page/PageContainer";
import MyTextInput from "../../app/common/form/MyTextInput";
import MySelectInput from "../../app/common/form/MySelectInput";
import MyTextAreaInput from "../../app/common/form/MyTextAreaInput";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function ArticleFormPage() {
    const { user } = useAuth0();
    const { articleAdminStore: articleStore, userLiteStore } = useStore();
    const { createArticle, updateArticle, loadArticle, loading } = articleStore;
    const { UserLiteItems, loadUserList, loadingUsers } = userLiteStore;
    const [localArticle, setArticle] = React.useState<ArticleForm>(new ArticleForm());
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        title: Yup.string().required().max(256),
        authorId: Yup.string().required(),
        summary: Yup.string().required().max(1024),
        contents: Yup.string().required()
    })

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        if (id) {
            loadArticle(id).then((article) => setArticle(new ArticleForm(article)))
        }
    }, [id, loadArticle]);

    useEffect(() => {
        loadUserList();
    }, [loadUserList])

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Articles", "pageurl": "/admin/articles", "active": true, icon: <ArticleIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    function handleFormSubmit(article: ArticleForm) {
        if (!id) {
            createArticle(article).then(() => navigate("/admin/articles"))
        } else {
            updateArticle(article).then(() => navigate("/admin/articles"))
        }
    }

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle="Create Article" breadcrumbs={breadcrumbs}>
            
                    <Formik
                        initialValues={localArticle}
                        onSubmit={values => handleFormSubmit(values)}
                        validationSchema={validationSchema}
                    >
                        {({handleSubmit, isSubmitting, isValid, dirty, errors, values}) => (
                            <Form className="form" onSubmit={handleSubmit} autoComplete='off'>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <MyTextInput name="title" placeholder="Title" label="Title" help={errors.title} />
                                        { UserLiteItems && 
                                            <MySelectInput
                                                name="authorId"
                                                label="Authors"
                                                options={UserLiteItems}
                                            />
                                        }
                                        <MyTextAreaInput name="summary" placeholder="Sumamry" label="Summary" help={errors.summary} rows={5} />
                                        <MyTextAreaInput name="contents" placeholder="Contents" label="Contents" help={errors.contents} rows={25} />
                                        <FormGroup sx={{paddingLeft: '5px'}}>
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="isSticky"
                                                Label={{ label: "Sticky" }}
                                            />
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="isPage"
                                                Label={{ label: "Page" }}
                                            />
                                            <Field
                                                component={CheckboxWithLabel}
                                                type="checkbox"
                                                name="isPublished"
                                                Label={{ label: "Publish" }}
                                            />
                                        </FormGroup>
                                        
                                        <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={!isValid || !dirty || isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            component={Link}
                                            to="/articles"
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{paddingLeft: '20px'}}>
                                        <ReactMarkdown>
                                            {values.contents}
                                        </ReactMarkdown>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
        </PageContainer>
    )
})

// Markdown useful site: https://commonmark.org/help/