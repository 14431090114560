import { observer } from "mobx-react-lite";
import { Alert, Button, ListItem, Modal, Paper, Typography } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";

import { useStore } from "../../../app/stores/store";
import { ResourceUploadForm } from "../../../app/models/Resources/Resource";
import MyTextAreaInput from "../../../app/common/form/MyTextAreaInput";
import LoadingComponent from "../../../app/common/page/LoadingComponent";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export default observer(function MyResourcesUploadModal({open, handleClose} : Props) {
    const { myResourcesStore } = useStore();
    const { uploadResources, loading } = myResourcesStore;

    function handleFormSubmit(form: ResourceUploadForm) {

        uploadResources(form).then(() => handleClose());
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={3} sx={{ maxWidth: "90%", padding: "10px", paddingRight: "25px", border: "1px solid #000", position: "absolute", left: "5%", right: "5%", top: "2%"}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Upload my Resources
                </Typography>
                <Typography id="modal-model-description">

                    <Alert severity="info" variant="filled" sx={{ marginRight: "-5px"}}>
                        <Typography variant="body2" component="div">
                            Please copy and past everything (The entire page content - [CTRL] + A, then [CTRL] + C) from one of the following places: 
                                <ListItem>Global CS (View Assets --&gt; View Commercial Stores)</ListItem>
                                <ListItem>CS All Content (Commercial Storage --&gt; View All Store Content)</ListItem>
                                <ListItem>Local CS (Commercial Storage --&gt; Enter Commercial Storage --&gt; View All Items)</ListItem>
                                <ListItem>Guild Office (Guild Office --&gt; Guild Store)</ListItem>
                                <ListItem>Advanced Ore Store (Ore Refinery --&gt; Enter Advanced Refinery Management --&gt; Advanced Ore Store)</ListItem>
                        </Typography>
                    </Alert>
                </Typography>

                    { loading ? <LoadingComponent /> :
                        <Formik
                            initialValues={{myRes: "", error: null}}
                            onSubmit={values => handleFormSubmit(values)}
                        >
                            {({handleSubmit, isSubmitting, isValid, dirty, errors, values}) => (
                                <Form className="form" onSubmit={handleSubmit} autoComplete='off'>
                                    <MyTextAreaInput name="myRes" placeholder="My Resources" label="Resources data" help={errors.myRes} rows={15} />
                                    <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                                                    
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!isValid || !dirty || isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        type="submit"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    }
                
            </Paper>
        </Modal>    
    )
})

