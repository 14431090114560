import { Typography } from "@mui/material";

interface Props {
    locType: number;
}

export default function LocationType({ locType } : Props) {

    const getType = () => {
        switch(locType) {
            case 0:
                return 'NPC';
            case 1: 
                return 'Settlement';
            case 2: 
                return 'Starbase';
            case 3: 
                return 'Asteroid';
            case 4: 
                return 'Refinery';
            case 5:
                return 'Nebula';
            default:
                return 'NPC';
        }
    }

    return (
        <Typography variant="body2" component="span">
            {getType()}
        </Typography>
    )
}

