import axios, {AxiosError, AxiosResponse } from "axios";
import { store } from '../stores/store';
import { history } from '../..';
import { PaginatedResult } from "../models/pagination";
import { URLSearchParams } from "url";
import { CurrentUser, FoundryCubeForm, ReductionMatrixForm, ThemeForm } from "../models/Users/currentUser";
import { CompleteRegistrationForm, RegisterForm } from "../models/Users/register";
import { UserAudit } from "../models/Audit/UserAudit";
import { UserForm, UserItem, UserListItem, UserLiteItem } from "../models/Users/users";
import { ArticleForm, ArticleItem, ArticleListItem } from "../models/Blog/Article";
import { TimelineForm, TimelineItem } from "../models/Blog/Timeline";
import { PublishForm } from "../models/common";
import { FaqForm, FaqItem } from "../models/Blog/Faq";
import { StoreItem, StoreItemLite, StoreUploadForm } from "../models/Stores/Store";
import { GalaxyItem, GalaxyLiteItem } from "../models/Locations/galaxy";
import { SolarSystemItem, SolarSystemLiteItem } from "../models/Locations/solarSystem";
import { LocationItem, LocationLiteItem } from "../models/Locations/location";
import { StoreTypeItem } from "../models/Stores/StoreType";
import { StoreLocationItem } from "../models/Stores/StoreLocation";
import { MyResourceItem, ResourceGbmItem, ResourceItem, ResourceListItem, ResourceLiteListItem, ResourceStatsItem, ResourceStoreListItem, ResourceUploadForm, ShipModifierListItem, StarbaseModifierListItem } from "../models/Resources/Resource";
import { ModuleUploadForm, MyBattleshipModuleItem, MyModuleItem, SchematicListItem, SchematicRelatedItem, SchematicRequest, SchematicSummary } from "../models/Schematics/Schematic";
import { CategoryListItem } from "../models/Categories/Category";
import { ItemListItem, ItemUploadForm } from "../models/Items/Item";
import { UserDesignCreateForm, UserDesignItem, UserDesignUpdateForm } from "../models/UserDesigns/UserDesigns";
import { BlueprintListItem, BlueprintRelatedItem, BlueprintRequest, BlueprintSummary } from "../models/Blueprints/Blueprint";
import { FoundryCalculationResult, FoundryPattern, FoundryRequest, FoundryResource } from "../models/Foundry/Foundry";
import { HeavyFuelRequestModel, HeavyFuelResponseModel } from "../models/Factory/HeavyFuel";
import { FactoryByResourceItem, FactoryLiteItem, FactoryRequest, FactoryResponse } from "../models/Factory/Factory";
import { SmrRequest, SmrResponse } from "../models/Factory/Smr";
import { AdvRefineryResponse, advRefineryRequest } from "../models/Factory/AdvRefinery";
import { RefineryRequest, RefineryResponse } from "../models/Factory/Refinery";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(async response => {
    const pagination = response.data.totalCount;
    if (pagination) {
        response.data = new PaginatedResult(response.data.items, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    const { data, status, config, headers }: any = error.response!;
    

    switch(status) {
        case 400: 
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                // toast.error(data);
            }
            break;
        case 401:
            history.push('/login-error');
            break;
        case 404:
            history.push('/not-found');
            break;
        default:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
})


const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const CurrentUserApi = {
    get: () => requests.get<CurrentUser>('/auth/currentUser'),
    setTheme: (form: ThemeForm) => requests.post('/auth/theme', form),
    setReductionMatrix: (form: ReductionMatrixForm) => requests.post('/auth/reductionMatrix', form),
    setFoundryCube: (form: FoundryCubeForm) => requests.post(`/auth/foundryCube`, form)
}

const RegistrationApi = {
    register: (form: RegisterForm) => requests.post('/auth/register', form),
    comleteRegistration: (form: CompleteRegistrationForm) => requests.post('/auth/completeRegistration', form)
}

const ActivityApi = {
    getUserActivity: (params: URLSearchParams) => axios.get<PaginatedResult<UserAudit[]>>('/user/userActivity', {params}).then(responseBody)
}

const UserApi = {
    get: (id: string) => requests.get<UserItem>(`/user/${id}`),
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<UserListItem[]>>('/user', {params}).then(responseBody),
    update: (form: UserForm) => requests.put(`/user/${form.id}`, form),
    delete: (id: string) => requests.del(`/user/${id}`),
    lock: (id: string) => requests.post(`/user/${id}/lock`, ""),
    unlock: (id: string) => requests.post(`/user/${id}/unlock`, ""),
    setTheme: (form: ThemeForm) => requests.post(`/user/${form.userId}/theme`, form),
    setReductionMatrix: (form: ReductionMatrixForm) => requests.post(`/user/${form.userId}/reductionmatrix`, form),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<UserLiteItem>>('/user/lite', {params}).then(responseBody)
}

const UserLiteApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<UserLiteItem[]>>('/user/lite', {params}).then(responseBody),
}

const ArticleApi = {
    get: (slug: string) => requests.get<ArticleItem>(`/article/${slug}`),
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<ArticleListItem[]>>('/article', {params}).then(responseBody),
    getPublished: (params: URLSearchParams) => axios.get<PaginatedResult<ArticleListItem[]>>('/article/published', {params}).then(responseBody),
    create: (article: ArticleForm) => requests.post('/article', article),
    update: (article: ArticleForm) => requests.put(`/article/${article.id}`, article),
    delete: (id: number) => requests.del(`/article/${id}`),
    publish: (form: PublishForm) => requests.post(`/article/${form.id}/publish`, form)
}

const TimelineApi = {
    get: (id: string) => requests.get<TimelineItem>(`/timeline/${id}`),
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<TimelineItem[]>>('/timeline', {params}).then(responseBody),
    getPublished: (params: URLSearchParams) => axios.get<PaginatedResult<TimelineItem[]>>('/timeline/published', {params}).then(responseBody),
    create: (timeline: TimelineForm) => requests.post('/timeline', timeline),
    update: (timeline: TimelineForm) => requests.put(`/timeline/${timeline.id}`, timeline),
    delete: (id: number) => requests.del(`/timeline/${id}`),
    publish: (form: PublishForm) => requests.post(`/timeline/${form.id}/publish`, form)
}

const FaqApi = {
    get: (id: string) => requests.get<FaqItem>(`/faq/${id}`),
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<FaqItem[]>>('/faq', {params}).then(responseBody),
    getPublished: (params: URLSearchParams) => axios.get<PaginatedResult<FaqItem[]>>('/faq/published', {params}).then(responseBody),
    create: (faq: FaqForm) => requests.post('/faq', faq),
    update: (faq: FaqForm) => requests.put(`/faq/${faq.id}`, faq),
    delete: (id: number) => requests.del(`/faq/${id}`),
    publish: (form: PublishForm) => requests.post(`/faq/${form.id}/publish`, form)
}

const StoresApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<StoreItem[]>>(`/store`, {params}).then(responseBody),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<StoreItemLite[]>>(`/store/lite`, {params}).then(responseBody),
    upload: (form: StoreUploadForm) => requests.post(`/store/process`, form)
}

const StoreTypeApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<StoreTypeItem[]>>(`/storetype`, {params}).then(responseBody)
}

const StoreLocationApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<StoreLocationItem[]>>(`/storelocation`, {params}).then(responseBody)
}

const GalaxyApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<GalaxyItem[]>>('/galaxy', {params}).then(responseBody),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<GalaxyLiteItem[]>>('/galaxy/lite', {params}).then(responseBody)
}

const SolarSystemApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<SolarSystemItem[]>>('/solarsystem', {params}).then(responseBody),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<SolarSystemLiteItem[]>>('/solarsystem/lite', {params}).then(responseBody)
}

const LocationApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<LocationItem[]>>('/location', {params}).then(responseBody),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<LocationLiteItem[]>>('/location/lite', {params}).then(responseBody)
}

const ResourcesApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<ResourceListItem[]>>(`/resource`, {params}).then(responseBody),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<ResourceLiteListItem[]>>(`/resource/lite`, {params}).then(responseBody),
    get: (slug: string) => requests.get<ResourceItem>(`/resource/${slug}`),
    getStores: (params: URLSearchParams) => axios.get<PaginatedResult<ResourceStoreListItem[]>>(`/resource/${params.get("slug")}/stores`, {params}).then(responseBody),
    getGbmHistory: (params: URLSearchParams) => axios.get<PaginatedResult<ResourceGbmItem[]>>(`/resource/gbm`, {params}).then(responseBody),
    process: (form: FormData) => requests.post(`/resource/process`, form),
    upload: (form: ResourceUploadForm) => requests.post(`/resource/upload`, form),
    getMyResources: (params: URLSearchParams) => axios.get<PaginatedResult<MyResourceItem[]>>(`/resource/myresources`, {params}).then(responseBody),
    getMyResourceStats: (params: URLSearchParams) => axios.get<ResourceStatsItem>(`/resource/myResourceStats`, {params}).then(responseBody),
    getSchematics: (params: URLSearchParams) => axios.get<PaginatedResult<SchematicRelatedItem[]>>(`/resource/${params.get("slug")}/schematics`, {params}).then(responseBody),
    getBlueprints: (params: URLSearchParams) => axios.get<PaginatedResult<BlueprintRelatedItem[]>>(`/resource/${params.get("slug")}/blueprints`, {params}).then(responseBody),
    getShipModifiers: () => axios.get<PaginatedResult<ShipModifierListItem[]>>(`/resource/shipModifiers`).then(responseBody),
    getStarbaseModifiers: () => axios.get<PaginatedResult<StarbaseModifierListItem[]>>(`/resource/starbaseModifiers`).then(responseBody)
}

const SchematicsApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<SchematicListItem[]>>(`/schematic`, {params}).then(responseBody),
    get: (slug: string, redMat: string, locationId: number) => requests.get<SchematicSummary>(`/schematic/${slug}?redMat=${redMat}&locationId=${locationId}`),
    getQuery: (form: SchematicRequest) => requests.post<SchematicSummary>(`/schematic/list`, form),
    process: (form: FormData) => requests.post(`/schematic/process`, form),
    processSmr: (form: FormData) => requests.post(`/schematic/processsmr`, form),
    processCorp: (form: FormData) => requests.post(`/schematic/processcorp`, form),
    getParents: (params: URLSearchParams) => axios.get<PaginatedResult<SchematicRelatedItem[]>>(`/schematic/${params.get("slug")}/parents`, {params}).then(responseBody),
    getChildren: (params: URLSearchParams) => axios.get<PaginatedResult<SchematicRelatedItem[]>>(`/schematic/${params.get("slug")}/children`, {params}).then(responseBody),
    getLite: (params: URLSearchParams) => axios.get<PaginatedResult<SchematicListItem[]>>(`/schematic/lite`, {params}).then(responseBody),
    upload: (form: ModuleUploadForm) => requests.post(`/schematic/upload`, form),
    getMyModules: (params: URLSearchParams) => axios.get<PaginatedResult<MyModuleItem[]>>(`/schematic/mymodules`, {params}).then(responseBody),
    getMyBattleshipModules: (params: URLSearchParams) => axios.get<PaginatedResult<MyBattleshipModuleItem[]>>(`/schematic/mybattleshipmodules`, {params}).then(responseBody)
}

const CategoriesApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<CategoryListItem[]>>(`/category`, {params}).then(responseBody)
}

const ItemApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<ItemListItem[]>>(`/item`, {params}).then(responseBody),
    upload: (form: ItemUploadForm) => requests.post(`/item/upload`, form)
}

const UserDesignApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<UserDesignItem[]>>(`/userDesign`, {params}).then(responseBody),
    get: (id: number) => requests.get<UserDesignItem>(`/userDesign/${id}`),
    create: (form: UserDesignCreateForm) => requests.post<number>(`/userDesign`, form),
    update: (form: UserDesignUpdateForm) => requests.put(`/userDesign/${form.id}`, form),
    delete: (id: number) => requests.del(`/userDesign/${id}`)
}

const BlueprintApi = {
    process: (form: FormData) => requests.post(`/blueprint/process`, form),
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<BlueprintListItem[]>>(`/blueprint`, {params}).then(responseBody),
    get: (slug: string) => requests.get<BlueprintSummary>(`/blueprint/${slug}`),
    getQuery: (form: BlueprintRequest) => requests.post<BlueprintSummary>(`/blueprint/list`, form),
    getParents: (params: URLSearchParams) => axios.get<PaginatedResult<BlueprintRelatedItem[]>>(`/blueprint/${params.get("slug")}/parents`, {params}).then(responseBody),
    getChildren: (params: URLSearchParams) => axios.get<PaginatedResult<BlueprintRelatedItem[]>>(`/blueprint/${params.get("slug")}/childen`, {params}).then(responseBody)
}

const FoundryApi = {
    getPattern: (slug: string) => requests.get<FoundryPattern>(`/foundry/${slug}`),
    getFoundryResources: (params: URLSearchParams) => axios.get<PaginatedResult<FoundryResource[]>>(`/foundry/resources`, { params }).then(responseBody),
    getFoundryCalculation: (form: FoundryRequest) => requests.post<FoundryCalculationResult>(`/foundry/patterns`, form)
}

const FactoryApi = {
    getList: (params: URLSearchParams) => axios.get<PaginatedResult<FactoryLiteItem[]>>(`/factory`, { params }).then(responseBody),
    getByResource: (params: URLSearchParams) => axios.get<PaginatedResult<FactoryByResourceItem[]>>(`/factory/${params.get("resourceSlug")}`, { params }).then(responseBody),
    getFactoryProducts: (id: number) => axios.get<PaginatedResult<FactoryLiteItem[]>>(`/factory/factoryProducts/${id}`).then(responseBody),
    getHeavyFuel: (form: HeavyFuelRequestModel) => requests.post<HeavyFuelResponseModel>(`/factory/heavyFuel`, form),
    getSmr: (form: SmrRequest) => requests.post<SmrResponse>(`/factory/smr`, form),
    getAdvRefinery: (form: advRefineryRequest) => requests.post<AdvRefineryResponse>(`/factory/advRefinery`, form),
    getRefinery: (form: RefineryRequest) => requests.post<RefineryResponse>(`/factory/refinery`, form),
    getFactory: (form: FactoryRequest) => requests.post<FactoryResponse>(`/factory/factory`, form)
}

const agent = {
    CurrentUserApi,
    RegistrationApi,
    ActivityApi,
    UserApi,
    UserLiteApi,
    ArticleApi,
    TimelineApi,
    FaqApi,
    StoresApi,
    StoreTypeApi,
    StoreLocationApi,
    GalaxyApi,
    SolarSystemApi,
    LocationApi,
    ResourcesApi,
    SchematicsApi,
    CategoriesApi,
    ItemApi,
    UserDesignApi,
    BlueprintApi,
    FoundryApi,
    FactoryApi
}

export default agent;