import { Divider, Grid, Link, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import './FooterBar.css';
import {Link as RouterLink } from 'react-router-dom';

const Item = styled(List)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
  }));

export default function FooterBar() {
    return (
        <>
            <Divider />
            <div className='docs-footer'>
                <Grid container>
                    <Grid item xs={6} sm={3}>
                        <Item 
                            sx={{
                                    typography: (theme) => theme.typography.overline, 
                                    letterSpacing: '.4rem',
                                    marginTop: '-3px',
                                }}
                        >
                            Project Eagle
                        </Item>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Item sx={{ fontWeight: (theme) => theme.typography.fontWeightBold}}>Project</Item>
                        <Item>
                            <ListItem>
                                <Link component={RouterLink} to='/faq'>Faq</Link>
                            </ListItem>
                            <ListItem>
                                <Link component={RouterLink} to='/timeline'>Timeline</Link>
                            </ListItem>
                            <ListItem>
                                <Link component={RouterLink} to='/article/about'>About</Link>
                            </ListItem>
                        </Item>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Item sx={{ fontWeight: (theme) => theme.typography.fontWeightBold}}>Contact</Item>
                        <Item>
                            <ListItem>
                                <Link href='https://community.core-exiles.com/viewtopic.php?f=2&t=3514' target="_blank">Forum</Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://discord.gg/w3pAcXNJug" target="_blank">Discord</Link>
                            </ListItem>
                        </Item>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Item sx={{ fontWeight: (theme) => theme.typography.fontWeightBold}}>Core Exiles</Item>
                        <Item>
                            <ListItem>
                                <Link href='https://core-exiles.com/' target="_blank">Core Exiles</Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://news.core-exiles.com" target="_blank">News</Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://community.core-exiles.com/index.php" target="_blank">Forum</Link>
                            </ListItem>
                        </Item>
                    </Grid>
                </Grid>
                <Typography variant='body2' align="center"> 
                    {'All game data is copyright of '}
                    <Link href="https://core-exiles.com" target="_blank">Core Exiles</Link>
                    {'.'}
                </Typography>
                <Typography variant='body2' align="center">
                    Site author: The Wolf
                </Typography>
            </div>
        </>
    )
}