import { observer } from "mobx-react-lite";
import { Box, Button, FormControl, Grid, Paper, Tab, Tabs } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import RefineryIcon from '@mui/icons-material/ControlCamera';

import { Breadcrumb } from "../../../app/models/common";
import PageContainer from "../../../app/common/page/PageContainer";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import ResourceLocationFilter from "../../common/filters/ResourceLocationFilter";
import { useAuth0 } from "@auth0/auth0-react";
import { TabPanel, a11yProps } from "../../../app/utils/TabUtils"
import PageHeader from "../../../app/common/page/PageHeader";
import { FactoryCalcType } from "../../../app/utils/globalConstants";
import ResourceUploadComponent from "../Components/ResourceUploadComponent";
import { RefineryRequestItem } from "../../../app/models/Factory/Refinery";
import RefinerySelectComponent from "./Components/RefinerySelectComponent";
import RefineryFacilityList from "./Components/RefineryFacilityList";
import RefineryProductionList from "./Components/RefineryProductionList";
import RefineryRequirementList from "./Components/RefineryRequirementList";
import RefineryArchimedesFilter from "../Components/RefineryArchimedesFilter";


export default observer(function RefineryPage() {
    const { refineryStore } = useStore();
    const { response, loadList, setLocationId, setArchimedes, archimedes, locationId, requestList, addUpdate, resetList, loading } = refineryStore;
    const { isAuthenticated } = useAuth0();
    const [value, setValue] = useState(0);

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Refinery Calculator", "pageurl": "/refinery/refinery", "active": true, icon: <RefineryIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
       handleLoad();
    }, [locationId, requestList, archimedes])

    function handleAdd(facilityId: number, quantity: number,  prodQuantity: number, prodType: FactoryCalcType) {
        const form : RefineryRequestItem = {
            facilityId: facilityId,
            quantityFacilities: quantity,
            productionQuantity: prodQuantity,
            calcType: prodType
        };

        addUpdate(form);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const handleLoad = () => {
        loadList();
    }

    const handleOnUpload = () => {
        handleLoad();
    }

    return(
        <PageContainer breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item lg={2} md={3} sm={12} minWidth="270px">
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              width: 270,
                              padding: '10px',
                              marginLeft: '-5px'
                            },
                          }}
                    >
                        <Paper elevation={1}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={resetList}
                                sx={{width: "250px", marginLeft: '-1px'}}
                            >
                                Reset
                            </Button>
                        </Paper>
                        <Paper>
                            <RefinerySelectComponent handleAdd={handleAdd} />
                        </Paper>

                        <Paper>
                            <FormControl>
                                <RefineryArchimedesFilter archimedes={archimedes} setArchimedes={setArchimedes} />
                            </FormControl>
                        </Paper>
                        { isAuthenticated && 
                            <Paper>
                                <ResourceLocationFilter setLocationId={setLocationId} locationId={locationId} />
                            </Paper>
                        }
                        { isAuthenticated &&
                            <Paper>
                                <ResourceUploadComponent handleOnUpload={handleOnUpload} />
                            </Paper>
                        }
                        
                    </Box>
                </Grid>
                <Grid item lg={9} md={8} sm={12}>
                    <Grid>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="refinery menu"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            sx={{ borderBottom: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Facilities" {...a11yProps(0)} />
                            <Tab label="Production" {...a11yProps(1)} />
                            <Tab label="Requirements" {...a11yProps(2)} />
                        </Tabs>
                    </Grid>
                    <Grid>
                        <TabPanel value={value} index={0}>
                            <PageHeader pagetitle="Facilities" />
                            <RefineryFacilityList responseList={response?.facilities!} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PageHeader pagetitle="Production" />
                            <RefineryProductionList responseList={response?.production!} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <PageHeader pagetitle="Requirements" />
                            <RefineryRequirementList responseList={response?.requirements!} loading={loading} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    )
})