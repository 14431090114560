import { observer } from "mobx-react-lite";
import {  Box, Grid, Table, TableBody, TableContainer, TableHead, TablePagination, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import { ResourceGbmItem } from "../../../app/models/Resources/Resource";
import LoadingComponent from "../../../app/common/page/LoadingComponent";
import Moment from "react-moment";
import { visuallyHidden } from '@mui/utils';

interface Props {
    id: string
}

export default observer(function ResourceGbmTab({ id }: Props) {
    const  { resourceGbmStore } = useStore();
    const { resourceGbmItems, loading, loadResourceGbms, pagingParams, totalCount } = resourceGbmStore;
    const [sortColumn, setSortColumn] = useState("date");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    
    useEffect(() => {
        loadResourceGbms(id, null);
    }, [loadResourceGbms])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadResourceGbms(id, null);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadResourceGbms(id, null);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadResourceGbms(id, null);
    }

    if (loading) return <LoadingComponent />

    return (
        <Grid container>
                <Grid item md={12} sm={12}>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="sold at table">
                            <TableHead>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "date"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("date")}
                                    >
                                        Date
                                        {sortColumn === "date" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "quantity"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("quantity")}
                                    >
                                        Quantity
                                        {sortColumn === "quantity" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "cpu"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("cpu")}
                                    >
                                        CPU
                                        {sortColumn === "cpu" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "player"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("player")}
                                    >
                                        Player
                                        {sortColumn === "player" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                            </TableHead>
                            <TableBody>
                                {resourceGbmItems ? resourceGbmItems.map((item: ResourceGbmItem) => (
                                    <StyledTableRow key={item.date.toString()} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                        <StyledTableCell>
                                            <Moment format="DD/MM/YYYY hh:mm:ss a">{item.date}</Moment>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.cpu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.player}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : 
                                <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell colSpan={4}>
                                        { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No gbm activity found</Typography> }
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={totalCount}
                        page={page}
                        rowsPerPage={pagingParams.take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </Grid>
        </Grid>
    )    
})
