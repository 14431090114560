export interface FaqItem {
    id: number;
    question: string;
    answer: string;
    order: number;
    isPublished: boolean;
    publishedDate: Date | null;
}

export interface IFaqForm {
    id: number;
    question: string;
    answer: string;
    order: number;
    isPublished: boolean;
    publishedDate: Date | null;
}

export class FaqForm {
    id: number = 0;
    question: string = '';
    answer: string = '';
    isPublished: boolean = false;
    publishedDate: Date | null = null;

    constructor(faq?: IFaqForm) {
        if (faq) {
            this.id = faq.id;
            this.question = faq.question;
            this.answer = faq.answer;
            this.isPublished = faq.isPublished;
            this.publishedDate = faq.publishedDate;
        }
    }
}
