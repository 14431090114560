import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, Grid, FormControl, Select, TextField, MenuItem, SelectChangeEvent, InputLabel, Typography, TableSortLabel, Box } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { StoreLocationItem } from "../../../../app/models/Stores/StoreLocation";
import { visuallyHidden } from '@mui/utils';

export default observer(function StoreLocationList() {
    const { storeLocationStore, storeLiteStore, locationLiteStore } = useStore();
    const { loading, pagingParams, totalCount, storeLocationItems, loadStoreLocationList } = storeLocationStore;
    const { storeItems, loadStoreList } = storeLiteStore;
    const { locationLiteItems, loadLocationLiteList: loadLocationList} = locationLiteStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [storeId, setStoreId] = useState(0);
    const [locationId, setLocationId] = useState(0);
    const [storeSearch, setStoreSearch] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    
    useEffect(() => {
        loadStoreLocationList(storeId, locationId, storeSearch, locationSearch);

    }, [loadStoreLocationList])

    useEffect(() => {
        loadStoreList();
    }, [loadStoreList])

    useEffect(() => {
        loadLocationList();
    }, [loadLocationList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadStoreLocationList(storeId, locationId, storeSearch, locationSearch);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadStoreLocationList(storeId, locationId, storeSearch, locationSearch);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadStoreLocationList(storeId, locationId, storeSearch, locationSearch);
    }

    const handleSearchStore = (change : string) => {
        setStoreSearch(change);
    }

    const handleSearchLocation = (change : string) => {
        setLocationSearch(change);
    }

    const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            loadStoreLocationList(storeId, locationId, storeSearch, locationSearch);
        }
    }

    const handleStoreChange = (event: SelectChangeEvent) => {
        setStoreId(parseInt(event.target.value));
        loadStoreLocationList(parseInt(event.target.value), locationId, storeSearch, locationSearch);
    }

    const handleLocationChange = (event: SelectChangeEvent) => {
        setLocationId(parseInt(event.target.value));
        loadStoreLocationList(storeId, parseInt(event.target.value), storeSearch, locationSearch);
    }

    if (loading) return <LoadingComponent />

    return (
            <>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="store-select">Store</InputLabel>
                            <Select
                                id="store-select"
                                value={storeId.toString()}
                                onChange={handleStoreChange}
                                label="Store"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {storeItems.map(map => 
                                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                                )} 
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="location-select">Location</InputLabel>
                            <Select
                                id="location-select"
                                value={locationId.toString()}
                                onChange={handleLocationChange}
                                label="Location"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {locationLiteItems.map(map => 
                                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                                )} 
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <TextField id="name" label="Store" variant="outlined" onChange={event => handleSearchStore(event.target.value)} onKeyDown={handleCompleteSearch} value={storeSearch}  />
                    </Grid>
                    <Grid item>
                        <TextField id="name" label="Location" variant="outlined" onChange={event => handleSearchLocation(event.target.value)} onKeyDown={handleCompleteSearch} value={locationSearch}  />
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Store Location table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "storeName"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("storeName")}
                                    >
                                        Store
                                        {sortColumn === "storeName" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "storeSlug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("storeSlug")}
                                    >
                                        Store Slug
                                        {sortColumn === "storeSlug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "locationName"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("locationName")}
                                    >
                                        Location
                                        {sortColumn === "locationName" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "locationSlug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("locationSlug")}
                                    >
                                        Location Slug
                                        {sortColumn === "locationSlug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {storeLocationItems ? storeLocationItems.map((item: StoreLocationItem) => (
                                <StyledTableRow key={`${item.storeId}${item.locationId}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>{item.storeName}</StyledTableCell>
                                    <StyledTableCell>{item.storeSlug}</StyledTableCell>
                                    <StyledTableCell>{item.locationName}</StyledTableCell>
                                    <StyledTableCell>{item.locationSlug}</StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={5}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No store locations found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

