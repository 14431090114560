import { styled, TableCell, tableCellClasses, TableRow } from "@mui/material";

export let pageRows: Array<number> = [5, 10, 25, 50, 100]

export let reductionMatrixOptionList: Array<KeyValueOption> = [
  { "id": "none", "name": "None" },
  { "id": "redMat1", "name": "Reduction Matrix 1" },
  { "id": "redMat2", "name": "Reduction Matrix 2" },
  { "id": "redMat3", "name": "Reduction Matrix 3" },
  { "id": "redMat4", "name": "Reduction Matrix 4" },
  { "id": "halo1", "name": "Reduction Matrix Halo 1" },
  { "id": "halo2", "name": "Reduction Matrix Halo 2" }
]

export let themeOptionList: Array<KeyValueOption> = [
  { "id": "lightTheme", "name": "Light" },
  { "id": "darkTheme", "name": "Dark" }
]


export let foundryCubeOptions: Array<KeyValueOption> = [
  { "id": "none", "name": "none" },
  { "id": "loc1", "name": "Locutus Cube 1" },
  { "id": "loc2", "name": "Locutus Cube 2" },
  { "id": "loc3", "name": "Locutus Cube 3" },
]

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.paper,
    },
  }));
  
  export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

interface KeyValueOption {
    id: string;
    name: string;
}

export enum SortingType {
  asc,
  desc
}

export enum ResourceType {
    None,
    Resource,
    Commodity,
    Expanse,
    Alien,
    Settlement,
    Nebula,
    SpecialResource,
}

export enum LootType {
    None = 0,
    Combat = 1 << 0,
    DerelictExploring = 1 << 1,
    Mining = 1 << 2,
    Nebula = 1 << 3,
    Exploration = 1 << 4,
    WreckSalvage = 1 << 5,
    Planet = 1 << 6,
    Settlement = 1 << 7,
    Corporation = 1 << 8,
}

export enum CategoryType {
  None = 0,
  Schematics = 1 << 0,
  Blueprints = 1 << 1,
  ShipParts = 1 << 2,
  StarbaseParts = 1 << 3,
  Items = 1 << 4
}

export enum FactoryCalcType {
  Units = 1,
  Hours = 2,
  Days = 3
}
