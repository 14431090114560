import { useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import ProfileIcon from '@mui/icons-material/Person';

import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";
import PageHeader from "../../app/common/page/PageHeader";
import ProfileTab from "./components/ProfileTab";
import ThemeTab from "./components/ThemeTab";
import ReductionMatrixTab from "./components/ReductionMatrixTab";
import ActivityLogTab from "./components/ActivityLogTab";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default function ProfilePage() {
    const [value, setValue] = useState(0);
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Profile", "pageurl": "/profile", "active": true, icon: <ProfileIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }


    return (
        <PageContainer pagetitle="Profile" breadcrumbs={breadcrumbs}>
            <Grid>
                <Grid item>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="profile menu"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Profile" {...a11yProps(0)} />
                        <Tab label="Theme" {...a11yProps(1)} />
                        <Tab label="Reduction Matrix" {...a11yProps(2)} />
                        <Tab label="Activity Log" {...a11yProps(3)} />
                    </Tabs>
                </Grid>
                <Grid item>
                    <TabPanel value={value} index={0}>
                        <PageHeader pagetext="Profile information." />
                        <ProfileTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PageHeader pagetext="Edit your theme settings." />
                        <ThemeTab />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PageHeader pagetext="Edit your Reduction Matrix settings." />
                        <ReductionMatrixTab />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <PageHeader pagetext="Review your Project Eagle account activity" />
                        <ActivityLogTab />
                    </TabPanel>
                </Grid>
            </Grid>
        </PageContainer>
        
    )
}