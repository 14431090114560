import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
    palette: {
      mode: 'light',
        primary: {
          main: '#0078d4',
        },
        secondary: {
          main: '#a2a2a2',
        },
        success: {
            main: '#107c10',
        },
        info : {
            main: '#8661c5',
        },
        warning: {
            main: '#ffb900',
        },
        error: {
            main: '#a80000',
        },
      },

      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: 'none',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              margin: '5px 5px',
              textTransform: 'none',
            }
          },
        },
        MuiContainer: {
          styleOverrides: {
            root: {
              marginBottom: '20px',
            },
          },
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              marginBottom: '10px',
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              marginBottom: '20px',
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              border: '1px solid rgba(224, 224, 224, 1)',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              border: '1px solid rgba(224, 224, 224, 1) !important',
              padding: '7px',
            },
          },
        },
        MuiAlertTitle: {
          styleOverrides: {
            root: {
              fontWeight: 'bold',
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              width: '250px'
            },
          },
        },
      },
});

export default lightTheme;
