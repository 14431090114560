import { FormControl, FormControlLabel, Switch } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    archimedes: boolean;
    setArchimedes: (archimedes: boolean) => void;
}

export default observer(function RefineryArchimedesFilter({ archimedes, setArchimedes }: Props) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setArchimedes(event.target.checked);
    }

    return (
        <FormControl>
            <FormControlLabel control={<Switch checked={archimedes} color="success" onChange={handleChange} />} label="Archimedes" />
        </FormControl>
    )
})