import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, Switch, IconButton, TableSortLabel, Box, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from '@mui/icons-material/Dashboard';
import UserIcon from "@mui/icons-material/Groups";
import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb } from "../../app/models/common";
import { useStore } from "../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import PageContainer from "../../app/common/page/PageContainer";
import { UserListItem } from "../../app/models/Users/users";
import Moment from "react-moment";
import { history } from "../..";
import UserLockComponent from "./components/UserLockComponent";
import ThemeToggler from "./components/ThemeToggler";
import ReductionMatrixToggler from "./components/ReductionMatrixToggler";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { visuallyHidden } from '@mui/utils';

export default observer(function UserListPage() {
    const { user } = useAuth0();
    const { userAdminStore } = useStore();
    const { loading, pagingParams, totalCount, userList, loadUserList, deleteUser } = userAdminStore;
    const [sortColumn, setSortColumn] = useState("lastLoginDate");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();
    
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Users", "pageurl": "/admin/users", "active": true, icon: <UserIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        loadUserList();

    }, [loadUserList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadUserList();
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadUserList();
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadUserList();
    }

    const handleDeleteUser = (
        userId: string
    ) => {
        deleteUser(userId).then(() => loadUserList());
    }

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle="Users" breadcrumbs={breadcrumbs}>
            <>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Users table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "userName"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("userName")}
                                    >
                                        Username
                                        {sortColumn === "userName" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "emailAddress"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("emailAddress")}
                                    >
                                        Email Address
                                        {sortColumn === "emailAddress" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "theme"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("theme")}
                                    >
                                        Theme
                                        {sortColumn === "theme" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "reductionMatrix"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("reductionMatrix")}
                                    >
                                        Reduction Matrix
                                        {sortColumn === "reductionMatrix" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "isLocked"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("isLocked")}
                                    >
                                        Locked
                                        {sortColumn === "isLocked" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "lastLoginDate"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("lastLoginDate")}
                                    >
                                        Last Login
                                        {sortColumn === "lastLoginDate" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "registrationComplete"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("registrationComplete")}
                                    >
                                        Registration Complete
                                        {sortColumn === "registrationComplete" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userList ? userList.map((item: UserListItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/users/${item.id}`}
                                            color="inherit"
                                        >
                                            {item.userName}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{item.emailAddress}</StyledTableCell>
                                    <StyledTableCell>
                                        <ThemeToggler theme={item.theme} userId={item.id} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <ReductionMatrixToggler redMat={item.reductionMatrix} userId={item.id} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <UserLockComponent isLocked={item.isLocked} userId={item.id} />
                                    </StyledTableCell>
                                    <StyledTableCell><Moment format="DD/MM/YYYY hh:mm:ss a">{item.lastLoginDate}</Moment></StyledTableCell>
                                    <StyledTableCell><Switch checked={item.registrationComplete} color="success" disabled /></StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteUser(item.id)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={8}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No users found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        </PageContainer>
    )
})

