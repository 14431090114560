export class PaginatedResult<T> {
    data: T;
    totalCount: number;

    constructor(data: T, totalCount: number) {
        this.data = data;
        this.totalCount = totalCount;
    }
}

export class PagingParams {
    take: number;
    skip: number;
    sort: string;

    constructor(take = 10, skip = 0, sort = '') {
        this.take = take;
        this.skip = skip;
        this.sort = sort;
    }
}