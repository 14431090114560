import _ from "lodash";

import { StyledTableCell } from "../../../app/utils/globalConstants";
import { IColumnType } from "./CustomTable";

interface Props<T> {
    item: T;
    column: IColumnType<T>;
}

export function TableRowCell<T>({ item, column }: Props<T>): JSX.Element {
    const value = _.get(item, column.key);
    const align = column?.align ? "right" : "left";

    return (
        <StyledTableCell align={align}>
            {column.render ? column.render(column, item) : value}
        </StyledTableCell>
    )
}