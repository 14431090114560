import { IconButton, ListItem, ListItemText } from "@mui/material";
import { observer } from "mobx-react-lite";

import DeleteIcon from '@mui/icons-material/DeleteForever';

import { SchematicQuery } from "../../../app/models/Schematics/Schematic";

interface Props {
    schematic: SchematicQuery;
    deleteSchematic: (id: number) => void;
}

export default observer(function SchematicListComponent({ schematic, deleteSchematic }: Props) {

    return (
       <ListItem sx={{marginTop: "-10px"}} key={schematic.id}>
            <ListItemText primary={`${schematic.name} x${schematic.quantity}`} />
            <IconButton edge="end" aria-label="delete" onClick={() => deleteSchematic(schematic.id)}>
                <DeleteIcon color="error" />
            </IconButton>
       </ListItem>
    )
})