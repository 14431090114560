import { observer } from "mobx-react-lite";

import HomeIcon from "@mui/icons-material/Home";
import SchematicIcon from "@mui/icons-material/Apartment";
import MySchematicIcon from '@mui/icons-material/Widgets';

import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import MyModulesComponent from "./Components/MyModulesComponent";

export default observer(function PlayerResourcesPage() {

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Schematics", "pageurl": "/schematics", "active": false, icon: <SchematicIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "My Crafting Modules", "pageurl": "/schematics/modules", "active": true, icon: <MySchematicIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    return (
            <PageContainer pagetitle="My Crafting Modules" breadcrumbs={breadcrumbs}>
                <MyModulesComponent userId="" />
            </PageContainer>
    )
})
