import { useEffect } from "react";
import { useStore } from "../../../app/stores/store"
import LoadingComponent from "../../../app/common/page/LoadingComponent";
import { Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StyledTableCell } from "../../../app/utils/globalConstants";

interface Props {
    id: string
}

export default observer(function FoundryPattern({ id }: Props) {
    const { foundryPatternStore} = useStore();
    const { loadPattern, loading, foundryPattern } = foundryPatternStore;


    useEffect(() => {
        loadPattern(id);
    }, [loadPattern, id])

    if (loading) return <LoadingComponent />

    return (
        <>
            {
                foundryPattern ? 
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={3} align="center">
                                    { `${foundryPattern.resource} - (${foundryPattern.quantity})` }
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <StyledTableCell>
                                    {foundryPattern.requirements[0].item}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {foundryPattern.requirements[1].item}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {foundryPattern.requirements[2].item}
                                </StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table> :
                    <Typography variant="body2" component="p"> No Foundry Pattern found</Typography>
            }
        </>
    )
})