import { Autocomplete, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    locationId: number;
    setLocationId: (locId: number) => void;
}

export default observer(function ResourceLocationFilter({ setLocationId, locationId }: Props) {
    const { locationLiteStore } = useStore();
    const { locationLiteItems, loadLocationLiteList} = locationLiteStore;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        loadLocationLiteList(0,0,'', true);
    }, [loadLocationLiteList, locationId])

    const handleChangeResourceLocation = (resourceLocation: string | null) => {
        if (resourceLocation === null) {
            setLocationId(0);            
        } else {
            var selectId = locationLiteItems.find(e => e.name === resourceLocation);

            if(selectId === undefined) {
                setLocationId(0);
            } else {
                setLocationId(selectId.id);
            }
        }
    }

    return (
        <FormControl>
            {locationLiteItems &&
                <Autocomplete
                    id="location-select"
                    onChange={(event: any, newValue) => {
                        handleChangeResourceLocation(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={locationLiteItems.map((option) => option.name)}
                    renderInput={(params) => <TextField {...params} label="Locations" />}
                />
            }
            
        </FormControl>
    )
})