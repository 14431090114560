import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { FactoryLiteItem } from "../../models/Factory/Factory";

export default class FactoryProductStore {
    loading = false;
    totalCount: number = 0;
    factoryItems: FactoryLiteItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    loadFactoryList = async (id: number = 0) => {
        this.loading = true;

        try {
            const result = await agent.FactoryApi.getFactoryProducts(id);

            runInAction(() => {
                this.factoryItems = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}