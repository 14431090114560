import { Alert, Button, FormGroup, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";
import * as Yup from 'yup';
import HomeIcon from "@mui/icons-material/Home";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { ErrorMessage, Field, Form, Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { history } from "../.."
import { Link } from "react-router-dom";
import MyTextAreaInput from "../../app/common/form/MyTextAreaInput";
import { CheckboxWithLabel } from "formik-material-ui";
import ReactMarkdown from "react-markdown";
import { useStore } from "../../app/stores/store";
import { FaqForm } from "../../app/models/Blog/Faq";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function FaqFormPage() {
    const { user } = useAuth0();
    const { faqAdminStore: faqStore } = useStore();
    const { createFaq, updateFaq, loadFaq, loading } = faqStore;
    const [localFaq, setFaq] = React.useState<FaqForm>(new FaqForm());
    const { id } = useParams<{ id: string}>();
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        question: Yup.string().required().max(256),
        answer: Yup.string().required()
    })

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        if(id) {
            loadFaq(id).then((faq) => setFaq(new FaqForm(faq)))
        }
    }, [id, loadFaq])

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Faqs", "pageurl": "/admin/faqs", "active": true, icon: <QuestionAnswerIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    function handleFormSubmit(faq: FaqForm) {
        if (!id) {
            createFaq(faq).then(() => navigate("/admin/faqs"))
        } else {
            updateFaq(faq).then(() => navigate("/admin/faqs"))
        }
    }

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle="Create Faq" breadcrumbs={breadcrumbs}>
            <Formik
                initialValues={localFaq}
                onSubmit={values => handleFormSubmit(values)}
                validationSchema={validationSchema}
            >
                {({handleSubmit, isSubmitting, isValid, dirty, errors, values}) => (
                    <Form className="form" onSubmit={handleSubmit} autoComplete='off'>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <MyTextInput name="question" placeholder="Question" label="Question" help={errors.question} />
                                <MyTextAreaInput name="answer" placeholder="Answer" label="Answer" help={errors.answer} rows={5} />
                                <FormGroup sx={{paddingLeft: '5px'}}>
                                    <Field
                                        component={CheckboxWithLabel}
                                        type="checkbox"
                                        name="isPublished"
                                        Label={{ label: "Publish" }}
                                    />
                                </FormGroup>
                                <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!isValid || !dirty || isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        component={Link}
                                        to="/admin/faqs"
                                    >
                                        Cancel
                                    </Button>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{paddingLeft: '20px'}}>
                                <ReactMarkdown>
                                    {values.answer}
                                </ReactMarkdown>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    )

})
