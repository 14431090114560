import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, Grid, TextField, Typography, TableSortLabel, Box } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { StoreTypeItem } from "../../../../app/models/Stores/StoreType";
import _ from "lodash";
import { visuallyHidden } from '@mui/utils';

export default observer(function StoreTypeList() {
    const { storeTypeStore } = useStore();
    const { loading, pagingParams, totalCount, storeTypeItems, loadStoreTypeList } = storeTypeStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    
    useEffect(() => {
        if (pagingParams.take === -1) {
            pagingParams.take = 10;
        }

        loadStoreTypeList(search);

    }, [loadStoreTypeList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadStoreTypeList(search);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadStoreTypeList(search);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadStoreTypeList(search);
    }

    const handleSearch = (change : string) => {
        setSearch(change);
    }

    const handleCompleteSearch = (
        event: any
    ) => {
        if (event.key === 'Enter') {
            loadStoreTypeList(search);
        }
    }
    
    if (loading) return <LoadingComponent />

    return (
            <>
                <Grid container justifyContent="flex-end">
                    <TextField id="name" label="Search" variant="outlined" onChange={event => handleSearch(event.target.value)} onKeyDown={handleCompleteSearch} value={search}  />
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Store Type table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Store Type
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "slug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("slug")}
                                    >
                                        Slug
                                        {sortColumn === "slug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {storeTypeItems ? storeTypeItems.map((item: StoreTypeItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/storetypes/${item.id}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{item.slug}</StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={3}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No store types found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

