import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { observer } from "mobx-react-lite";

import { SchematicSummary } from "../../../app/models/Schematics/Schematic"

interface Props {
    schematic: SchematicSummary;
}

export default observer(function SchematicSummaryComponent({ schematic  }: Props) {
    return (
        <Grid>
            <Grid container>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Manufacture: ${schematic === null ? 0 : schematic?.manu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Production: ${schematic === null ? 0 : schematic?.prod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total Schematic Cost: ${schematic === null ? 0 : schematic?.schematicCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total Resource Cost: ${schematic === null ? 0 : schematic?.resourcesCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total XP: ${schematic === null ? 0 : schematic?.totalXP?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
})