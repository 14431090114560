import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, TableBody, TableContainer, TableHead, TableRow, Link as MuiLink, TablePagination, Typography, SelectChangeEvent, Grid, FormControl, Select, MenuItem, InputLabel, TableSortLabel, Box } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../../app/utils/globalConstants";
import LoadingComponent from "../../../../app/common/page/LoadingComponent";
import { SolarSystemItem } from '../../../../app/models/Locations/solarSystem'
import { visuallyHidden } from '@mui/utils';

export default observer(function SolarSystemList() {
    const { solarSystemStore, galaxyLiteStore } = useStore();
    const { loadingSolarSystems, pagingParamsSolarSystem, totalSolarSystemCount, solarSystemItems, loadSolarSystemList } = solarSystemStore;
    const { galaxyLiteItems, loadGalaxyList} = galaxyLiteStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [galaxyId, setGalaxyId] = useState(0);
    
    useEffect(() => {
        loadSolarSystemList(galaxyId);

    }, [loadSolarSystemList])

    useEffect(() => {
        loadGalaxyList();
    }, [loadGalaxyList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParamsSolarSystem.skip = newPage * pagingParamsSolarSystem.take;
        setPage(newPage);
        loadSolarSystemList(galaxyId);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParamsSolarSystem.take = parseInt(event.target.value, 10);
        pagingParamsSolarSystem.skip = 0;
        loadSolarSystemList(galaxyId);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParamsSolarSystem.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParamsSolarSystem.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParamsSolarSystem.sort = column;
            }
            setSortColumn(column);
        }
        loadSolarSystemList(galaxyId);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setGalaxyId(parseInt(event.target.value));
        loadSolarSystemList(parseInt(event.target.value));
    }

    if (loadingSolarSystems) return <LoadingComponent />

    return (
            <>
                <Grid container justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="galaxy-select">Galaxy</InputLabel>
                            <Select
                                id="galaxy-select"
                                value={galaxyId.toString()}
                                onChange={handleChange}
                                label="Galaxy"
                                sx={{minWidth: "250px"}}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                {galaxyLiteItems.map(map => 
                                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                                )} 
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="SolarSystem table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "name"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("name")}
                                    >
                                        Solar System
                                        {sortColumn === "name" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "slug"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("slug")}
                                    >
                                        Slug
                                        {sortColumn === "slug" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={sortColumn === "galaxy"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("galaxy")}
                                    >
                                        Galaxy
                                        {sortColumn === "galaxy" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {solarSystemItems ? solarSystemItems.map((item: SolarSystemItem) => (
                                <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/solarsystems/${item.id}`}
                                            color="inherit"
                                        >
                                            {item.name}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>{item.slug}</StyledTableCell>
                                    <StyledTableCell>{item.galaxyName}</StyledTableCell>
                                    <StyledTableCell>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={4}>
                                    { loadingSolarSystems ? <LoadingComponent /> : <Typography variant="body2" component="div">No solar systems found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalSolarSystemCount}
                    page={page}
                    rowsPerPage={pagingParamsSolarSystem.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    )
})

