import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { getThemeByName } from './ThemeBase';
import { useStore } from '../stores/store';
import { ThemeForm } from '../models/Users/currentUser';

export const ThemeContext = React.createContext((themeName: string): void => {});

interface Props {
    children: React.ReactNode;
  }

const ThemeProvider: React.FC<Props> = observer(({children}) => {
    const { commonStore, currentUserStore } = useStore();
    const { updateTheme, currentUser } = currentUserStore;

    const setThemeName = (themeName: string): void => {
        commonStore.setTheme(themeName);

        const form : ThemeForm = { theme: themeName, userId: currentUser?.id! }
        updateTheme(form);
    }

    const storedTheme = commonStore.theme ?? "lightTheme";

    const theme = getThemeByName(storedTheme);

    return (
        <ThemeContext.Provider value={setThemeName}>
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
        
    );
})

export default ThemeProvider;