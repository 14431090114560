import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useStore } from "../../../app/stores/store"
import { SortingType, pageRows } from "../../../app/utils/globalConstants"
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable"
import TableLink from "../../common/tables/TableLink"
import SchematicListUploadModuleDetail from "../../Crafting/Components/SchematicListUploadModuleDetail"
import { MyBattleshipModuleItem } from "../../../app/models/Schematics/Schematic"

export default observer(function MyBattleshipModulesComponent() {
    const { myBattleshipModulesStore } = useStore();
    const { loading, pagingParams, totalCount, myBsModules, loadMyBsModules } = myBattleshipModulesStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);

    useEffect(() => {
        handleLoad();
     }, [page, sortColumn, sortOrder, rowsPerPage])

    const handleLoad = () => {
        loadMyBsModules();
    }

    const handlePageChange = (page:number) => {
        pagingParams.skip = page * pagingParams.take;
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        pagingParams.take = rows;
        pagingParams.skip = 0;

        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        if (sortOrder === SortingType.asc) {
            pagingParams.sort = column;
        } else {
            pagingParams.sort = `-${column}`;
        }

        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: totalCount,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<MyBattleshipModuleItem>[] = [
        {
            key: "name",
            title: "Module",
            sortLabel: "name",
            isSortable: true,
            render: (_, { slug, name}) => (
                <TableLink url={`/schematics/${slug}`} name={name} />
            )
        },
        {
            key: "store",
            title: "Store",
            sortLabel: "store",
            isSortable: true
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
    ]

    return (
        <>
            <SchematicListUploadModuleDetail handleOnUpload={handleLoad} />
            <CustomTable
                    data={myBsModules}
                    columns={columns}
                    pagination={pagination}
                    tableName="My Battleship Modules"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Battleship Modules found"
                />
        </>
    )
})