import { IColumnType } from "./CustomTable";
import { TableRowCell } from "./TableRowCell";
import { StyledTableRow } from "../../../app/utils/globalConstants";

interface Props<T> {
    data: T[];
    columns: IColumnType<T>[];
}

export function TableRow<T>({ data, columns }: Props<T>): JSX.Element {
    return (
        <>
            {data.map((item, itemIndex) => (
                <StyledTableRow key={`table-body-${itemIndex}`} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                    {columns.map((column, columnIndex) => (
                        <TableRowCell
                            key={`table-row-cell-${columnIndex}`}
                            item={item}
                            column={column}
                        />
                    ))}
                </StyledTableRow>
            ))}
        </>
    )
}