import { Button, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FactoryCalcType } from "../../../../app/utils/globalConstants";
import FactoryFilter from "../../Components/FactoryFilter";
import FactoryCalcTypeFilter from "../../Components/FactoryCalcTypeFilter";

interface Props {
    handleAdd: (facilityId: number, quantity: number, prodQuantity: number, prodType: FactoryCalcType) => void;
}

export default observer(function RefinerySelectComponent({ handleAdd }: Props) {
    const [facilityId, setFacilityId] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [prodQuantity, setProdQuantity] = useState(0);
    const [prodType, setProdType] = useState(FactoryCalcType.Units);

    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);
        input < 1 ? setQuantity(1) : setQuantity(input);
    }

    const handleChangeProduction = (event:  React.ChangeEvent<HTMLInputElement>) => {
        const input = parseInt(event.target.value);
        input < 1 ? setProdQuantity(1) : setProdQuantity(input);
    }


    function handleClickAdd() {
        if (facilityId !== 0) {
            handleAdd(facilityId, quantity, prodQuantity, prodType);
        }
    }

    return (
        <>
           <FactoryFilter factoryCategory="refinery" factoryId={facilityId} setFactoryId={setFacilityId} />
            <FormControl>
                <TextField 
                    id="Quantity-input"
                    label="Number of Facilities"
                    variant="outlined"
                    type="number"
                    value={quantity}
                    onChange={handleChangeQuantity}
                    sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                />
            </FormControl> 
            <FormControl>
                <TextField 
                    id="production-input"
                    label="Quantity"
                    variant="outlined"
                    type="number"
                    value={prodQuantity}
                    onChange={handleChangeProduction}
                    sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                />
            </FormControl>
            <FactoryCalcTypeFilter factoryCalcTypeId={prodType} setFactoryCalcTypeId={setProdType} />
            <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{marginBottom: '15px', width: "250px", marginLeft: '-1px'}}
                onClick={handleClickAdd}
                disabled={facilityId === 0}
            >
                Add
            </Button>         
        </>
      
    )
})