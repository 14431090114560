import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { FactoryRequest, FactoryRequestItem, FactoryResponse } from "../../models/Factory/Factory";

export default class FactoryCalcStore {
    loading = false;
    locationId: number = JSON.parse(localStorage.getItem('locationId') as string) || 0;
    boost: number = JSON.parse(localStorage.getItem('factoryBoost') as string) || 0;
    requestList: FactoryRequestItem[] | null = JSON.parse(localStorage.getItem('factoryList') || '[]');
    response: FactoryResponse | null = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.locationId,
            locationId => {
                if(locationId) {
                    localStorage.setItem('locationId', locationId.toString());
                } else {
                    localStorage.setItem('locationId', "0");
                }
            }
        )

        reaction(
            () => this.boost,
            boost => {
                if(boost) {
                    localStorage.setItem('factoryBoost', boost.toString());
                } else {
                    localStorage.setItem('factoryBoost', "0");
                }
            }
        )

        reaction(
            () => this.requestList,
            requestList => {
                if(requestList) {
                    localStorage.setItem('factoryList', JSON.stringify(requestList));
                } else {
                    localStorage.setItem('factoryList', JSON.stringify([]));
                }
            }
        )
    }

    setLocationId = (locationId: number) => {
        this.locationId = locationId;
    }

    setBoost = (boost: number) => {
        this.boost = boost;
    }

    addUpdate = (smr: FactoryRequestItem) => {
        const smrs :  FactoryRequestItem[] = JSON.parse(localStorage.getItem('factoryList') || '[]');

        if (smr.facilityId !== 0) {
            const existing = smrs.find(e => e.facilityId === smr.facilityId && e.productionId == smr.productionId);

            if (existing !== undefined) {
                const index = smrs?.indexOf(existing);
                smrs.splice(index, 1); // Remove exisitng record
            }

            smrs.push(smr);            
        
            this.requestList = smrs;
        }
    }

    deleteItem = (id: number, productId: number) => {
        const smrs :  FactoryRequestItem[] = JSON.parse(localStorage.getItem('factoryList') || '[]');

        const existing = smrs.find(e => e.facilityId === id && e.productionId == productId);

        this.requestList = smrs.filter((smr) => smr != existing);
    }

    resetList = () => {
        this.requestList = [];
    }
    
    loadList = async () => {
        this.loading = true;

        try {
            const requestDataList :  FactoryRequestItem[] = JSON.parse(localStorage.getItem('factoryList') || '[]');

            const data = await agent.FactoryApi.getFactory(new FactoryRequest({
                locationId: this.locationId,
                facilities: requestDataList,
                boost: this.boost
            }));

            runInAction(() => {
                this.response = data;
                this.loading = false;
            })

        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }
}