import { observer } from "mobx-react-lite";
import { Box, Button, Grid, Paper, Tab, Tabs } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import SmrIcon from '@mui/icons-material/PrecisionManufacturing';

import { Breadcrumb } from "../../../app/models/common";
import PageContainer from "../../../app/common/page/PageContainer";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import ResourceLocationFilter from "../../common/filters/ResourceLocationFilter";
import { useAuth0 } from "@auth0/auth0-react";
import { TabPanel, a11yProps } from "../../../app/utils/TabUtils"
import PageHeader from "../../../app/common/page/PageHeader";
import { SmrRequestItem } from "../../../app/models/Factory/Smr";
import { FactoryCalcType } from "../../../app/utils/globalConstants";
import ResourceUploadComponent from "../Components/ResourceUploadComponent";
import SmrSelectComponent from "./Components/SmrSelectComponent";
import SmrFacilityList from "./Components/SmrFacilityList";
import SmrProductionList from "./Components/SmrProductionList";
import SmrRequirementList from "./Components/SmrRequirementList";



export default observer(function SmrPage() {
    const { smrStore } = useStore();
    const { smrResponse, loadSmrList, setLocationId, locationId, requestList, addUpdateSmr, resetSmr, loading } = smrStore;
    const { isAuthenticated } = useAuth0();
    const [value, setValue] = useState(0);

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "SMR Production Calculator", "pageurl": "/factory/smr", "active": true, icon: <SmrIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
       handleLoad();
    }, [locationId, requestList])

    function handleAddSmr(facilityId: number, quantity: number, researchLevel: number, prodQuantity: number, prodType: FactoryCalcType) {
        const form : SmrRequestItem = {
            facilityId: facilityId,
            quantity: quantity,
            researchLevel: researchLevel,
            productionQuantity: prodQuantity,
            calcType: prodType
        };

        addUpdateSmr(form);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const handleLoad = () => {
        loadSmrList();
    }

    const handleOnUpload = () => {
        handleLoad();
    }

    return(
        <PageContainer breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item lg={2} md={3} sm={12} minWidth="270px">
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              width: 270,
                              padding: '10px',
                              marginLeft: '-5px'
                            },
                          }}
                    >
                        <Paper elevation={1}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={resetSmr}
                                sx={{width: "250px", marginLeft: '-1px'}}
                            >
                                Reset
                            </Button>
                        </Paper>
                        <Paper>
                            <SmrSelectComponent handleAddSmr={handleAddSmr} />
                        </Paper>
                        { isAuthenticated && 
                            <Paper>
                                <ResourceLocationFilter setLocationId={setLocationId} locationId={locationId} />
                            </Paper>
                        }
                        { isAuthenticated &&
                            <Paper>
                                <ResourceUploadComponent handleOnUpload={handleOnUpload} />
                            </Paper>
                        }
                        
                    </Box>
                </Grid>
                <Grid item lg={9} md={8} sm={12}>
                    <Grid>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="smr menu"
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            sx={{ borderBottom: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Facilities" {...a11yProps(0)} />
                            <Tab label="Production" {...a11yProps(1)} />
                            <Tab label="Requirements" {...a11yProps(2)} />
                        </Tabs>
                    </Grid>
                    <Grid>
                        <TabPanel value={value} index={0}>
                            <PageHeader pagetitle="Facilities" />
                            <SmrFacilityList smrList={smrResponse?.facilities!} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PageHeader pagetitle="Production" />
                            <SmrProductionList smrList={smrResponse?.production!} loading={loading} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <PageHeader pagetitle="Requirements" />
                            <SmrRequirementList smrList={smrResponse?.requirements!} loading={loading} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>
        </PageContainer>
    )
})