export interface FoundryPattern  {
    resource: string;
    quantity: number;
    requirements: FoundryPatternRequirements[];
}

export interface FoundryPatternRequirements {
    item: string;
    quantity: number;
}

export interface FoundryResource {
    id: number;
    name: string;
}

export class FoundryRequest {
    foundryCube: string = '';
    patterns: FoundryRequestItem[] = [];

    constructor(form?: FoundryRequest) {
        if(form) {
            this.foundryCube = form.foundryCube;
            this.patterns = form.patterns;
        }
    }
}

export class FoundryRequestItem {
    id: number = 0;
    quantity: number = 0

    constructor(form?: FoundryRequestItem) {
        if (form) {
            this.id = form.id;
            this.quantity = form.quantity;
        }
    }
}

export interface FoundryCalculationResult {
    patterns: FoundryCalculationPattern[];
    nanos: FoundryCalcuationNanos[];
    uas: FoundryCalcuationUas[];
}

export interface FoundryCalculationPattern {
    id: number;
    name: string;
    slug: string;
    quantity: number;
}

export interface FoundryCalcuationNanos {
    itemId: number;
    name: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}

export interface FoundryCalcuationUas {
    itemId: number;
    name: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}