import { Theme } from "@mui/material";
import lightTheme from '../layout/lightTheme';
import darkTheme from '../layout/darkTheme';

export function getThemeByName(theme: string) : Theme {
    return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
    lightTheme,
    darkTheme
}

