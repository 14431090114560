import { observer } from "mobx-react-lite";
import PageContainer from "../../app/common/page/PageContainer";
import { Breadcrumb } from "../../app/models/common";
import HomeIcon from "@mui/icons-material/Home";
import StarbaseIcon from "@mui/icons-material/AccountBalance";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import { Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography, Link as MuiLink, Grid, Button, IconButton, TableSortLabel, Box } from "@mui/material";
import { StarbaseDesign } from "../../app/models/StarbaseBuilder/StarbaseBuilder";
import * as _ from "lodash";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { Link } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { visuallyHidden } from '@mui/utils';
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function MyStarbases() {
    const { starbaseBuilderStore } = useStore();
    const { loading, loadStarbaseDesigns, starbaseDesigns, deleteStarbaseDesign } = starbaseBuilderStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);   
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Starbase Builder", "pageurl": "/starbasebuilder", "active": true, icon: <StarbaseIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
        if (!isAuthenticated) {
            handleLogin();
        }
    })

    const handleLogin = async () => {
        await loginWithRedirect();
    }

    useEffect(() => {
        loadStarbaseDesigns();
    }, [loadStarbaseDesigns])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        setPage(newPage);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
            } else {
                setSortOrder(SortingType.asc);
            }
            setSortColumn(column);
        }
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTake(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleDeleteStarbase(id: number) {
        deleteStarbaseDesign(id).then(loadStarbaseDesigns);
    }

    return (
        <PageContainer pagetitle="My Starbases" breadcrumbs={breadcrumbs}>
            <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                    <Button color="primary" component={Link} variant="contained" to='/starbasebuilder/build'>Design Starbase</Button>
                </Grid>
            </Grid>
            <TableContainer>
                        <Table aria-label="Starbase component select table">
                        <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "name"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("name")}
                                        >
                                            Starbase
                                            {sortColumn === "name" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.armour"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.armour")}
                                        >
                                            Armour
                                            {sortColumn === "summary.armour" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.hull"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.hull")}
                                        >
                                            Hull
                                            {sortColumn === "summary.hull" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.energy"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.energy")}
                                        >
                                            Energy
                                            {sortColumn === "summary.energy" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.recycling"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.recycling")}
                                        >
                                            Recycling Efficincy
                                            {sortColumn === "summary.recycling" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.eco"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.eco")}
                                        >
                                            Ecosystem Efficiency
                                            {sortColumn === "summary.eco" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.science"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.science")}
                                        >
                                            Scientific Efficiency
                                            {sortColumn === "summary.science" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.population"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.population")}
                                        >
                                            Population Management
                                            {sortColumn === "summary.population" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={sortColumn === "summary.buildTime"}
                                            direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                            onClick={ () => handleChangeSort("summary.buildTime")}
                                        >
                                            Build Time
                                            {sortColumn === "summary.buildTime" ? (
                                                <Box 
                                                    component="span" 
                                                    sx={visuallyHidden}
                                                >
                                                    {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { starbaseDesigns ?  _.orderBy(starbaseDesigns, [sortColumn], [sortOrder == SortingType.asc ? 'asc' : 'desc']).slice(page * take, page * take + take).map((item: StarbaseDesign) => (
                                    <StyledTableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell>
                                            <MuiLink
                                                component={Link}
                                                to={`/starbasebuilder/build/${item.id}`}
                                                color="inherit"
                                            >
                                                {item.name}
                                            </MuiLink>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.armour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.hull.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.energy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.recycling.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.eco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.science.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{item.summary.population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                                        <StyledTableCell align="right">{`${item.summary.buildTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hrs`}</StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton aria-label="delete" color="error" onClick={() => handleDeleteStarbase(item.id)}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell colSpan={12}>
                                            { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No starbases found</Typography> }
                                        </StyledTableCell>
                                    </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={starbaseDesigns?.length || 0}
                        page={page}
                        rowsPerPage={take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
        </PageContainer>
    )
})