import { Grid, Paper } from "@mui/material";

interface Props {
    children: JSX.Element | JSX.Element[];
  }

export default function StatComponent({ children }: Props) {
   
    return (
        <Paper variant="outlined" sx={{ "padding": "10px", "margin": "5px", "width": "185px", "textAlign": "center", "backgroundColor": "info.main"}}>
            {children}    
        </Paper>
        
    )
}