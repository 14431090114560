import { TableRow } from "@mui/material";

import { IColumnType } from "./CustomTable";
import { TableHeaderCell } from "./TableHeaderCell";
import { SortingType } from "../../../app/utils/globalConstants";

interface Props<T> {
    columns: IColumnType<T>[];
    sortColumn: string;
    sortOrder: SortingType;
    onSortChange: (column: string, sortOrder: SortingType) => void;
}

export function TableHeader<T>({ columns, sortColumn, sortOrder, onSortChange }: Props<T>): JSX.Element {

    return (
        <TableRow>
            {columns.map((column, columnIndex) => (
                <TableHeaderCell
                    key={`table-head-cell-${columnIndex}`}
                    column={column}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                />
            ))}
        </TableRow>
    )
}