import { Autocomplete, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    setPatternId: (resourceId: number) => void;
}

export default observer(function FoundryResourceFilter({ setPatternId }: Props) {
    const { foundryResourcesStore } = useStore();
    const { foundryResources, loadFoundryResourceList } = foundryResourcesStore;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        loadFoundryResourceList();
    }, [loadFoundryResourceList])

    const handleChangePattern = (pattern: string | null) => {
        if (pattern === null) {
            setPatternId(0);            
        } else {
            var selectId = foundryResources.find(e => e.name === pattern);

            if(selectId === undefined) {
                setPatternId(0);
            } else {
                setPatternId(selectId.id);
            }
        }
    }

    return (
        <FormControl>
            <Autocomplete
                id="foundry-patterns-select"
                onChange={(event: any, newValue) => {
                    handleChangePattern(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={foundryResources.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Patterns" />}
            />
        </FormControl>
    )
})