import { observer } from "mobx-react-lite";
import { Button } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { history } from "../../../..";
import { ChangeEvent } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";


export default observer(function CorpSchematicUpload() {
    const { schematicStore } = useStore();
    const { uploadCorpSchematics } = schematicStore;
    const navigate = useNavigate();
    
    const handleFileUpload = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("formFile", file);


        uploadCorpSchematics(formData);

        navigate('/admin/schematics')
    }

    return (
        <Button
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{ marginRight: "1rem" }}
        >
            Upload Corp
            <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
      </Button>
    )
})