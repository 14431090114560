import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { UserLiteItem } from "../../models/Users/users";


export default class UserLiteStore {
    loadingUsers = false;
    totalUserLiteCount: number = 0;
    pagingParamsUserLite = new PagingParams(-1, 0, "userName");
    UserLiteItems: UserLiteItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsUserLite = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalUserLiteCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsUserLite.take.toString());
        params.append('skip', this.pagingParamsUserLite.skip.toString());
        params.append('sort', this.pagingParamsUserLite.sort);

        return params;
    }

    loadUserList = async () => {
        this.loadingUsers = true;

        try {
            const result = await agent.UserLiteApi.getList(this.axiosParams);

            runInAction(() => {
                this.UserLiteItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingUsers = false;
            })
        } catch(error) {
            runInAction(() => this.loadingUsers = false);
            throw error;
        }
    }
}