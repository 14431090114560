import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { ReductionMatrixForm, ThemeForm } from "../../models/Users/currentUser";
import { UserListItem, UserForm, UserItem } from "../../models/Users/users"

export default class UserAdminStore {
    userList: UserListItem[] = [];
    peUser: UserItem | null = null;
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "-lastLoginDate");

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadUserList = async () => {
        this.loading = true;

        try {
            const result = await agent.UserApi.getList(this.axiosParams);

            runInAction(() => {
                this.userList = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    loadUser = async (id: string) => {
        this.loading = true;

        try {
            const result = await agent.UserApi.get(id);

            runInAction(() => {
                this.peUser = result;
                this.loading = false;
            });

            return result;
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    updateUser = async(form: UserForm) => {
        try {
            await agent.UserApi.update(form);
        } catch(error) {
            throw error;
        }
    }

    deleteUser = async(id: string) => {
        try {
            await agent.UserApi.delete(id);
        } catch(error) {
            throw error;
        }
    }

    lockUser = async(id: string) => {
        try {
            await agent.UserApi.lock(id);
        } catch(error) {
            throw error;
        }
    }

    unlockUser = async(id: string) => {
        try {
            await agent.UserApi.unlock(id);
        } catch(error) {
            throw error;
        }
    }

    updateTheme = async (form: ThemeForm) => {
        try {
            await agent.UserApi.setTheme(form);
        } catch(error) {
            throw error;
        }
    }
    
    updateReductionMatrix = async (form: ReductionMatrixForm) => {
        try {
            await agent.UserApi.setReductionMatrix(form);
        } catch(error) {
            throw error;
        }
    }
}


