import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ReductionMatrixForm } from "../../../app/models/Users/currentUser";
import { useStore } from "../../../app/stores/store";
import { reductionMatrixOptionList } from "../../../app/utils/globalConstants";

interface Props {
    redMat: string;
    userId: string
}

export default observer(function ReductionMatrixToggler({redMat, userId} : Props) {
    const { userAdminStore } = useStore();
    const { updateReductionMatrix } = userAdminStore;
    const [selected, setReductionMatrix] = useState(redMat);

    const handleChange = (event: SelectChangeEvent) => {
        const form : ReductionMatrixForm = { reductionMatrix: event.target.value as string, userId: userId }
        
        updateReductionMatrix(form);

        setReductionMatrix(event.target.value);
    }

    return (
        <FormControl fullWidth>
            <Select
                id="redMat-select"
                value={selected}
                onChange={handleChange}
            >
                {reductionMatrixOptionList.map(map => 
                    <MenuItem value={map.id} key={map.id}>{map.name}</MenuItem>
                )}    
            </Select>
        </FormControl>
    )
})