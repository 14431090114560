import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Alert, Box, Button, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import FactoryIcon from '@mui/icons-material/LocalGasStation';

import { Breadcrumb } from "../../../app/models/common";
import PageContainer from "../../../app/common/page/PageContainer";
import { useStore } from "../../../app/stores/store";
import FactoryCalcTypeFilter from "../Components/FactoryCalcTypeFilter";
import { HeavyFuelRequestModel, HeavyFuelRequirementModel } from "../../../app/models/Factory/HeavyFuel";
import LoadingComponent from "../../../app/common/page/LoadingComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable";
import { FactoryCalcType, SortingType, pageRows } from "../../../app/utils/globalConstants";
import _ from "lodash";
import TableLink from "../../common/tables/TableLink";
import ResourceLocationFilter from "../../common/filters/ResourceLocationFilter";

export default observer(function HeavyFuelCalcPage() {
    const { heavyFuelStore } = useStore();
    const { loading, calculateFuel, heavyFuelModel } = heavyFuelStore;
    const [quantity, setQuantity] = useState(0);
    const [factoryCalcTypeId, setFactoryCalcTypeId] = useState(1);
    const { isAuthenticated } = useAuth0();
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);
    const [sortColumn, setSortColumn] = useState("resourceName");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [locationId, setLocalLocationId] = useState(0);
    
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Heavy Fuel Calculator", "pageurl": "/factory/heavyfuel", "active": true, icon: <FactoryIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        handleCalculate();
    }, [locationId])

    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        try {
            const input = parseInt(event.target.value);

            if (input < 1) {
                setQuantity(1);
            } else  {
                setQuantity(input);
            }
        } catch {
            setQuantity(1);
        }
    }

    const handleCalculate = () => {
        calculateFuel(new HeavyFuelRequestModel({
            quantity: quantity,
            calcType: factoryCalcTypeId,
            locationId: locationId
        }));
    }

    const handleSetLocationId = (input: number) => {
        setLocalLocationId(input);
    }

    const columns: IColumnType<HeavyFuelRequirementModel>[] = 
        isAuthenticated 
        ? [
        {
            key: "resourceName",
            title: "Resource",
            sortLabel: "resourceName",
            isSortable: true,
            render: (_, { resourceName, resourceSlug }) => (
                <TableLink url={`/resources/${resourceSlug}`} name={resourceName} />
            )
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity }) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "quantityHave",
            title: "Quantity Have",
            sortLabel: "quantityHave",
            isSortable: true,
            align: "right",
            render: (_, { quantityHave }) => (
                <>{quantityHave.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "quantityNeed",
            title: "Quantity Need",
            sortLabel: "quantityNeed",
            isSortable: true,
            align: "right",
            render: (_, { quantityNeed }) => (
                <Typography component="span" variant="body2" color={quantityNeed > 0 ? "error.main" : "success.main"}>
                    {quantityNeed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
            )
        }]
        : [
            {
                key: "resourceName",
                title: "Resource",
                sortLabel: "resourceName",
                isSortable: true,
                render: (_, { resourceName, resourceSlug }) => (
                    <TableLink url={`/resources/${resourceSlug}`} name={resourceName} />
                )
            },
            {
                key: "quantity",
                title: "Quantity",
                sortLabel: "quantity",
                isSortable: true,
                align: "right",
                render: (_, { quantity }) => (
                    <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
                )
            }];

            const handlePageChange = (page:number) => {
                setPage(page);
            }
        
            const handleRowPerPageChange = (rows: number) => {
                setRowsPerPage(rows);
            }
        
            const handleSortChange = (column: string, sortOrder: SortingType) => {
                setSortColumn(column);
                setSortOrder(sortOrder);
            }
        
            const pagination: IPagination = {
                rowsPerPage: rowsPerPage,
                count: heavyFuelModel?.requirements?.length  ?? 0,
                page: page,
                onPageChange: handlePageChange,
                onRowsPerPageChange: handleRowPerPageChange
            }

    const getQuantity = () => {
        if (heavyFuelModel?.calcType === 1) {
            return (
                <Alert severity="info" variant="filled" sx={{ marginBottom: "10px" }}>
                    { heavyFuelModel?.originalQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } { FactoryCalcType[heavyFuelModel?.calcType!] } takes { heavyFuelModel?.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } Hours to produce
                </Alert>
            )
        } else if (factoryCalcTypeId === 2) {
            return (
                <Alert severity="info" variant="filled" sx={{ marginBottom: "10px" }}>
                    { heavyFuelModel?.originalQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } { FactoryCalcType[heavyFuelModel?.calcType!] } produces { heavyFuelModel?.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } Units
                </Alert>
            )
        } else {
            return (
                <Alert severity="info" variant="filled" sx={{ marginBottom: "10px" }}>
                    { heavyFuelModel?.originalQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } { FactoryCalcType[heavyFuelModel?.calcType!] } produces { heavyFuelModel?.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } Units
                </Alert>
            )
        }
    }

    return (
            <PageContainer pagetitle="Heavy Fuel Calculator" breadcrumbs={breadcrumbs}>
                <Grid container>
                    <Grid item lg={2} md={3} sm={12} minWidth="270px">
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                m: 1,
                                width: 270,
                                padding: '10px',
                                marginLeft: '-5px'
                                },
                            }}
                        >
                            <Paper>
                               <FormControl> 
                                    <TextField 
                                        id="Quantity-input"
                                        label="Quantity"
                                        variant="outlined"
                                        type="number"
                                        value={quantity}
                                        onChange={handleChangeQuantity}
                                        sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                                    />
                                </FormControl> 
                                <FactoryCalcTypeFilter factoryCalcTypeId={factoryCalcTypeId} setFactoryCalcTypeId={setFactoryCalcTypeId} />
                                <Button
                                    id="btn-calculate"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    sx={{width: "250px", marginLeft: '-1px', marginTop: '10px'}}
                                    onClick={handleCalculate}
                                >
                                    Calculate
                                </Button>  
                            </Paper>
                            { isAuthenticated && 
                            <Paper>
                                <ResourceLocationFilter setLocationId={handleSetLocationId} locationId={locationId} />
                            </Paper>
                        }
                        </Box>
                    </Grid>
                    <Grid item lg={9} md={8} sm={12}>
                        {
                            loading 
                                ? <LoadingComponent />
                                : <>
                                    {getQuantity()}
                                    <CustomTable
                                        data={_.orderBy(heavyFuelModel?.requirements, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                                        columns={columns}
                                        pagination={pagination}
                                        tableName="Heavy Fuel Requirements"
                                        sortColumn={sortColumn}
                                        sortOrder={sortOrder}
                                        onSortChange={handleSortChange}
                                        loading={loading}
                                        emptyTableText="No Heavy Fuel set"
                                    />
                                </>
                        }
                    </Grid>
                </Grid>
            </PageContainer>
    )
})

