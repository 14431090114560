import { useAuth0 } from "@auth0/auth0-react";
import { Button, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ShipSummary } from "../../../app/models/ShipBuilder/ShipBuilder";
import { StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";

interface Props {
    summary: ShipSummary;
}

export default observer(function ShipSummaryComponent({ summary }: Props) {
    const { isAuthenticated } = useAuth0();


    return (
        <>
            <Table aria-label="Ship summary table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Armour</StyledTableCell>
                        <StyledTableCell>Hull</StyledTableCell>
                        <StyledTableCell>Energy</StyledTableCell>
                        <StyledTableCell>Cargo</StyledTableCell>
                        <StyledTableCell>Speed</StyledTableCell>
                        <StyledTableCell>Specials</StyledTableCell>
                        <StyledTableCell>Pods</StyledTableCell>
                        <StyledTableCell>Level</StyledTableCell>
                        <StyledTableCell>Skill+</StyledTableCell>
                        <StyledTableCell>Build Time</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell align="right">{summary.armour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.hull.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.energy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.cargo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.speed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.specials.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.pods.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.level.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.skillBonus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{`${summary.buildTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hrs`}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            {isAuthenticated ?? 
                <>
                    <Button size="small">Save</Button>
                </>
            }
        </>
    )
})