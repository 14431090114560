import { observer } from "mobx-react-lite";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import ShipIcon from "@mui/icons-material/RocketLaunch";
import MyShipsIcon from "@mui/icons-material/Rocket";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid, Alert, AlertTitle, TextField } from "@mui/material";
import ShipComponent from "./components/ShipComponent";
import ShipArmourComponent from "./components/ShipArmourComponent";
import { ShipDesign, ShipDetail, ShipSummary } from "../../app/models/ShipBuilder/ShipBuilder";
import ShipSummaryComponent from "./components/ShipSummaryComponent";
import { SchematicQuery } from "../../app/models/Schematics/Schematic";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export default observer(function BuildShipPage() {
    const { shipBuilderStore, itemStore, craftingStore } = useStore();
    const { shipModifiers, loadModifiers, addShipDesign, updateShipDesign, loadShipDesign, shipDesign } = shipBuilderStore;
    const { loadItemList, pagingParams, itemItems } = itemStore;
    const { addSchematics } = craftingStore; 
    const { isAuthenticated } = useAuth0();
    const [summary, setSummary] = useState(new ShipSummary);
    const [details, setDetails] = useState(new ShipDetail);
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const { id } = useParams<{ id: string }>();
    
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Ship Builder", "pageurl": "/shipbuilder", "active": !isAuthenticated, icon: <MyShipsIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Build", "pageurl": "/shipbuilder/build", "active": true, icon: <ShipIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        pagingParams.skip = 0;
        pagingParams.take = -1;
        pagingParams.sort = "name";

        loadItemList('ships');
    }, [loadItemList])

    useEffect(() => {
        loadModifiers();
        calculateSummary();
    }, [loadModifiers])

    useEffect(() => {
        if (id !== undefined) {
            loadShipDesign(parseInt(id!));
        } else {
            handleReset();
        }
    }, [id])

    useEffect(() => {
        if (shipDesign !== null) {
            setSummary(shipDesign?.summary!);
            setDetails(shipDesign?.details!);
            setName(shipDesign?.name!);
        }
    }, [shipDesign])

    function calculateSummary() {
        let form : ShipSummary = {
            armour: 0,
            hull: 0,
            energy: 0,
            cargo: 0,
            speed: 0,
            specials: 0,
            pods: 0,
            level: 0,
            skillBonus: 0,
            buildTime: 0
        };

        if (details.chassis !== 0) {
            form = addComponent(form, details.chassis);
        }

        if (details.frame !== 0) {
            form = addComponent(form, details.frame);
        }

        if (details.module1 !== 0) {
            form = addComponent(form, details.module1);
        }

        if (details.module2 !== 0) {
            form = addComponent(form, details.module2);
        }

        if (details.module3 !== 0) {
            form = addComponent(form, details.module3);
        }

        if (details.module4 !== 0) {
            form = addComponent(form, details.module4);
        }

        if (details.module5 !== 0) {
            form = addComponent(form, details.module5);
        }

        if (details.modifier !== 0) {
            const selectedArmour = shipModifiers.find(e => e.id == details.modifier)!;

            form.armour = Math.ceil((form.armour * selectedArmour.shipModifier / 100) + form.armour);
            form.hull = Math.ceil((form.hull * selectedArmour.shipModifier / 100) + form.hull);
        }

        setSummary(form);
    }

    function addComponent(summary: ShipSummary, id: number) {
        const item = itemItems.find(e => e.id === id)!;

        if (item !== undefined) {
            summary.armour += parseInt(item.attributes.find(e => e.key === "Armour")?.value!);
            summary.hull += parseInt(item.attributes.find(e => e.key === "Hull")?.value!);
            summary.energy += parseInt(item.attributes.find(e => e.key === "Energy")?.value!);
            summary.cargo += parseInt(item.attributes.find(e => e.key === "Cargo")?.value!);
            summary.speed += parseInt(item.attributes.find(e => e.key === "Speed")?.value!);
            summary.specials += parseInt(item.attributes.find(e => e.key === "Specials")?.value!);
            summary.pods += parseInt(item.attributes.find(e => e.key === "Pods")?.value!);
            summary.level += parseInt(item.attributes.find(e => e.key === "Level")?.value!);
            summary.skillBonus += parseInt(item.attributes.find(e => e.key === "SkillBonus")?.value!);
            summary.buildTime += parseInt(item.attributes.find(e => e.key === "BuildTime")?.value!);

            if (summary.specials > 10) {
                summary.specials = 10;
            }

            if (summary.pods > 10) {
                summary.pods = 10;
            }
        }
        return summary;
    }

    function handleAddSchematics() {
        let form: SchematicQuery[] = [];

        if (details.chassis !== 0) {
            form = getSchematic(form, details.chassis);
        }

        if (details.frame !== 0) {
            form = getSchematic(form, details.frame);
        }

        if (details.module1 !== 0) {
            form = getSchematic(form, details.module1);
        }

        if (details.module2 !== 0) {
            form = getSchematic(form, details.module2);
        }

        if (details.module3 !== 0) {
            form = getSchematic(form, details.module3);
        }

        if (details.module4 !== 0) {
            form = getSchematic(form, details.module4);
        }

        if (details.module5 !== 0) {
            form = getSchematic(form, details.module5);
        }

        addSchematics(form);
        enqueueSnackbar('Schematics Added', { variant: "success" });
    }

    function handleSaveSchematic() {
        const form: ShipDesign = {
            id: id === undefined ? 0 : parseInt(id!),
            name: name,
            details: details,
            summary: summary
        };

        if (id !== undefined) {
            updateShipDesign(form);
        } else {
            addShipDesign(form);
        }

        enqueueSnackbar('Ship Design Saved', { variant: "success" });
    }

    function handleReset() {
        setName("");
        setSummary(new ShipSummary);
        setDetails(new ShipDetail);
    }

    function getSchematic(schems: SchematicQuery[], id: number) {
        const item = itemItems.find(e => e.id === id);

        if (item !== undefined) {
            const form : SchematicQuery = {
                id: item.schematicId,
                quantity: 1,
                name: item.name
            }

            schems.push(form);
        }

        return schems;
    }

    function setDetail(type: string, id: number) {
        switch(type) {
            case 'chassis':
                details.chassis = id;
                break;
            case 'frame':
                details.frame = id;
                break;
            case 'module1':
                details.module1 = id;
                break;
            case 'module2':
                details.module2 = id;
                break;
            case 'module3':
                details.module3 = id;
                break;
            case 'module4':
                details.module4 = id;
                break;
            case 'module5':
                details.module5 = id;
                break;
            case 'modifier':
                details.modifier = id;
                break;
        }

        setDetails(details);
        calculateSummary();
    }

    return (
        <PageContainer pagetitle="Ship Builder" breadcrumbs={breadcrumbs}>
            <Grid container>
                {!isAuthenticated && 
                    <Grid item md={12}>
                        <Alert severity="info" variant="filled">
                            <AlertTitle>Info</AlertTitle>
                            Did you know that if you create an account, that you can save your designs and compare them?
                        </Alert>
                    </Grid>
                }
                <Grid item md={12} sx={{marginLeft: "5px", marginRight: "5px"}}>
                    <ShipSummaryComponent summary={summary}  />
                    <Grid container justifyContent="flex-end" spacing={2} sx={{marginTop: "0"}}>
                        { isAuthenticated && 
                            <Grid item>
                                <TextField id="name" label="Name" variant="outlined" onChange={event => setName(event.target.value)} value={name} size="small" sx={{marginTop: "4px"}} />
                                <Button color="primary" onClick={handleSaveSchematic} variant="contained">Save</Button>
                            </Grid>
                        }
                        <Grid item>
                            <Button color="primary" onClick={handleAddSchematics} variant="contained">Add to Schematics</Button>
                        </Grid>
                        <Grid item>
                            <Button color="error" onClick={handleReset} variant="contained">Reset</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Chassis" isRequired={true} components={itemItems.filter(e => e.subCategorySlug === 'chassis')} selectedItem={details.chassis} setComponent={setDetail} type="chassis" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Engineering Frame" isRequired={true} components={itemItems.filter(e => e.subCategorySlug === 'frame')} selectedItem={details.frame} setComponent={setDetail} type="frame" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Module 1" isRequired={false} components={itemItems.filter(e => e.subCategorySlug === 'module')} selectedItem={details.module1} setComponent={setDetail} type="module1" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Module 2" isRequired={false} components={itemItems.filter(e => e.subCategorySlug === 'module')} selectedItem={details.module2} setComponent={setDetail} type="module2" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Module 3" isRequired={false} components={itemItems.filter(e => e.subCategorySlug === 'module')} selectedItem={details.module3} setComponent={setDetail} type="module3" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Module 4" isRequired={false} components={itemItems.filter(e => e.subCategorySlug === 'module')} selectedItem={details.module4} setComponent={setDetail} type="module4" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipComponent title="Module 5" isRequired={false} components={itemItems.filter(e => e.subCategorySlug === 'module')} selectedItem={details.module5} setComponent={setDetail} type="module5" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <ShipArmourComponent components={shipModifiers} selectedItem={details.modifier} setComponent={setDetail} />
                </Grid>
            </Grid>
        </PageContainer>
    )
})

