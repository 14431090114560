export class StarbaseSummary {
    armour: number = 0;
    hull: number = 0;
    energy: number = 0;
    recycling: number = 0;
    eco: number = 0;
    science: number = 0;
    population: number = 0;
    buildTime: number = 0;

    constructor(form?: StarbaseSummary) {
        if (form) {
            this.armour = form.armour;
            this.hull = form.hull;
            this.energy = form.energy;
            this.recycling = form.recycling;
            this.eco = form.eco;
            this.science = form.science;
            this.population = form.population;
            this.buildTime = form.buildTime;
        }
    }
}

export class StarbaseDetails {
    eco: number = 0;
    engineering: number = 0;
    frame: number = 0;
    housing: number = 0;
    recycling: number = 0;
    science: number = 0;
    chassis: number = 0;
    modifier: number = 0;

    constructo(form?: StarbaseDetails) {
        if (form) {
            this.eco = form.eco;
            this.engineering = form.engineering;
            this.frame = form.frame;
            this.housing = form.housing;
            this.recycling = form.recycling;
            this.science = form.science;
            this.chassis = form.chassis;
            this.modifier = form.modifier;
        }
    }
}

export class StarbaseOverview {
    summary: StarbaseSummary = new StarbaseSummary();
    details: StarbaseDetails = new StarbaseDetails();

    constructor(form?: StarbaseOverview) {
        if (form) {
            this.summary = form.summary;
            this.details = form.details;
        }
    }
}

export class StarbaseDesign {
    id: number = 0;
    name: string = "";
    summary: StarbaseSummary = new StarbaseSummary();
    details: StarbaseDetails = new StarbaseDetails();

    constructor(form?: StarbaseDesign) {
        if (form) {
            this.id = form.id;
            this.name = form.name;
            this.summary = form.summary;
            this.details = form.details;
        }
    }
}