import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";

interface Props {
    resourceTypeId: number;
    setResourceTypeId: (resourcetypeId: number) => void;
}

export default observer(function ResourceTypeFilter({ resourceTypeId, setResourceTypeId }: Props) {

    const handleChangeType = (event: SelectChangeEvent) => {
        setResourceTypeId(parseInt(event.target.value));
    }

    return (
        <FormControl>
            <InputLabel id="resourceType-select">Resource Type</InputLabel>
            <Select
                id="resourceType-select"
                value={resourceTypeId.toString()}
                onChange={handleChangeType}
                label="Resource Type"
                sx={{minWidth: "250px"}}
            >
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={1}>Resource</MenuItem>
                <MenuItem value={2}>Commodity</MenuItem>
                <MenuItem value={3}>Expanse</MenuItem>
                <MenuItem value={4}>Alien</MenuItem>
                <MenuItem value={5}>Settlement</MenuItem>
                <MenuItem value={6}>Nebula</MenuItem>
                <MenuItem value={7}>Special Resource</MenuItem>
                <MenuItem value={8}>Trade Commodity</MenuItem>
            </Select>
        </FormControl>
    )
})