import { observer } from "mobx-react-lite";
import { ErrorMessage, Form, Formik } from "formik";
import { Alert, Button } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { StoreUploadForm } from "../../../../app/models/Stores/Store";
import MyTextAreaInput from "../../../../app/common/form/MyTextAreaInput";
import { history } from "../../../..";
import { useNavigate } from "react-router-dom";


export default observer(function StoreUpload() {
    const { storeStore } = useStore();
    const { uploadStores } = storeStore;
    const navigate = useNavigate();
    
    function handleFormSubmit(form: StoreUploadForm) {
        uploadStores(form);

        navigate('/admin/stores')
    }

    return (
            <Formik
                initialValues={{locationData: "", error: null}}
                onSubmit={values => handleFormSubmit(values)}
            >
                {({handleSubmit, isSubmitting, isValid, dirty, errors, values}) => (
                    <Form className="form" onSubmit={handleSubmit} autoComplete='off'>
                        <MyTextAreaInput name="locationData" placeholder="Store Location Data" label="Store Location Data" help={errors.locationData} rows={20} />
                        <ErrorMessage name="error" render={() => (<Alert severity="error"></Alert>)} />
                                        
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isValid || !dirty || isSubmitting}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
    )
})

