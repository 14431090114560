import { Autocomplete, FormControl, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { useStore } from "../../../app/stores/store";

interface Props {
    factoryCategory: string;
    factoryId: number;
    setFactoryId: (factoryId: number) => void;
}

export default observer(function FactoryFilter({ factoryCategory, factoryId, setFactoryId }: Props) {
    const { factoryStore } = useStore();
    const { loading, loadFactoryList, factoryItems} = factoryStore;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        loadFactoryList(factoryCategory);
    }, [loadFactoryList])

    const handleChangeFactory = (factory: string | null) => {
        if (factory === null) {
            setFactoryId(0);            
        } else {
            var selectId = factoryItems.find(e => e.name === factory);

            if(selectId === undefined) {
                setFactoryId(0);
            } else {
                setFactoryId(selectId.id);
            }
        }
    }

    return (
        <FormControl>
            <Autocomplete
                id="factory-select"
                sx={{marginBottom: "10px"}}
                onChange={(event: any, newValue) => {
                    handleChangeFactory(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                options={factoryItems.map((option) => option.name)}
                renderInput={(params) => <TextField {...params} label="Facilities" />}
            />
        </FormControl>
    )
})