import { observer } from "mobx-react-lite";
import { Box, Button, FormControl, Modal, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FactoryCalcType } from "../../../../app/utils/globalConstants";
import { useStore } from "../../../../app/stores/store";
import { SmrRequestItem } from "../../../../app/models/Factory/Smr";
import FactoryCalcTypeFilter from "../../Components/FactoryCalcTypeFilter";

interface Props {
    open: boolean;
    facilityId: number;
    facility: string;
    quantity: number;
    researchLevel: number;
    prodQuantity: number;
    prodType: FactoryCalcType
    handleClose: () => void;
}

export default observer(function EditSmrFacilityModal({open, facilityId, facility, quantity, researchLevel, prodQuantity, prodType, handleClose} : Props) {
    const { smrStore } = useStore();
    const { addUpdateSmr } = smrStore;
    const [localQuantity, setLocalQuantity] = useState(0);
    const [localResearch, setLocalResearch] = useState(0);
    const [localProdQuantity, setLocalProdQuantity] = useState(0);
    const [localProdType, setLocalProdType] = useState(FactoryCalcType.Units);

    useEffect(() => {
        setLocalQuantity(quantity);
        setLocalResearch(researchLevel);
        setLocalProdQuantity(prodQuantity);
        setLocalProdType(prodType);
    }, [quantity, researchLevel, prodQuantity, prodType])


    const handleChangeQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        try {
            const input = parseInt(event.target.value);

            if (input < 1) {
                setLocalQuantity(1);
            } else  {
                setLocalQuantity(input);
            }
        } catch {
            setLocalQuantity(1);
        }
    }

    const handleChangeResearch = (event:  React.ChangeEvent<HTMLInputElement>) => {
        try {
            const input = parseInt(event.target.value);

            if (input < 0) {
                setLocalResearch(0);
            } else  {
                setLocalResearch(input);
            }
        } catch {
            setLocalResearch(0);
        }
    }

    const hanldeChangeProdQuantity = (event:  React.ChangeEvent<HTMLInputElement>) => {
        try {
            const input = parseInt(event.target.value);

            if (input < 1) {
                setLocalProdQuantity(1);
            } else  {
                setLocalProdQuantity(input);
            }
        } catch {
            setLocalProdQuantity(1);
        }
    }

    const handleOnClose = () => {
        addUpdateSmr(new SmrRequestItem({
            facilityId: facilityId,
            quantity: localQuantity,
            researchLevel: localResearch,
            productionQuantity: localProdQuantity,
            calcType: localProdType
        }));

        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={() => handleOnClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper elevation={3} sx={{ maxWidth: "90%", padding: "10px", paddingRight: "25px", border: "1px solid #000", position: 'absolute', top: '30%', left: '50%', width: 400}}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update {facility}
                </Typography>
                <Box id="modal-modal-description" sx={{ mt: 2 }}>
                    <FormControl>
                        <TextField 
                            id="Quantity-input"
                            label="Number of Facilities"
                            variant="outlined"
                            type="number"
                            value={localQuantity}
                            onChange={handleChangeQuantity}
                            sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                        />
                    </FormControl> 
                    <FormControl>
                        <TextField 
                            id="research-input"
                            label="Research Level"
                            variant="outlined"
                            type="number"
                            value={localResearch}
                            onChange={handleChangeResearch}
                            sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                        />
                    </FormControl>  
                    <FormControl>
                        <TextField 
                            id="production-input"
                            label="Quantity"
                            variant="outlined"
                            type="number"
                            value={localProdQuantity}
                            onChange={hanldeChangeProdQuantity}
                            sx={{marginTop: '10px', marginBottom: '10px', width: "250px"}}
                        />
                    </FormControl>
                    <FactoryCalcTypeFilter factoryCalcTypeId={localProdType} setFactoryCalcTypeId={setLocalProdType} />
                </Box>
                <Box id="modal-modal-buttons" sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleOnClose}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        type="submit"
                        onClick={handleOnClose}
                    >
                        Cancel
                    </Button>   
                </Box>
            </Paper>
        </Modal>    
    )
})

