import { useAuth0 } from "@auth0/auth0-react";
import { Button, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import { StarbaseSummary } from "../../../app/models/StarbaseBuilder/StarbaseBuilder";
import { StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";

interface Props {
    summary: StarbaseSummary;
}

export default observer(function StarbaseSummaryComponent({ summary }: Props) {
    const { isAuthenticated } = useAuth0();

    return (
        <>
            <Table aria-label="Starbase summary table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Armour</StyledTableCell>
                        <StyledTableCell>Hull</StyledTableCell>
                        <StyledTableCell>Energy</StyledTableCell>
                        <StyledTableCell>Recycling Efficiency</StyledTableCell>
                        <StyledTableCell>Ecosystem Efficiency</StyledTableCell>
                        <StyledTableCell>Scientific Efficiency</StyledTableCell>
                        <StyledTableCell>Population Management</StyledTableCell>
                        <StyledTableCell>Build Time</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow>
                        <StyledTableCell align="right">{summary.armour.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.hull.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.energy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</StyledTableCell>
                        <StyledTableCell align="right">{summary.recycling.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                        <StyledTableCell align="right">{summary.eco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                        <StyledTableCell align="right">{summary.science.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                        <StyledTableCell align="right">{summary.population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</StyledTableCell>
                        <StyledTableCell align="right">{`${summary.buildTime.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hrs`}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            {isAuthenticated ?? 
                <>
                    <Button size="small">Save</Button>
                </>
            }
        </>
    )
})