import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { LocationItem } from "../../models/Locations/location";
import { PagingParams } from "../../models/pagination";


export default class UserLiteStore {
    loadingLocations = false;
    totalLocationCount: number = 0;
    pagingParamsLocation = new PagingParams(10, 0, "name");
    locationItems: LocationItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsLocation = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalLocationCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsLocation.take.toString());
        params.append('skip', this.pagingParamsLocation.skip.toString());
        params.append('sort', this.pagingParamsLocation.sort);

        return params;
    }

    loadLocationList = async (galaxyId: number = 0, solarSystemId: number = 0, owner: string = '') => {
        this.loadingLocations = true;

        try {
            var axiosParams = this.axiosParams;

            if (galaxyId !== 0) {
                axiosParams.append('galaxyId', galaxyId.toString());
            }

            if (solarSystemId !== 0) {
                axiosParams.append('solarSystemId', solarSystemId.toString());
            }

            axiosParams.append('owner', owner);

            const result = await agent.LocationApi.getList(axiosParams);

            runInAction(() => {
                this.locationItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingLocations = false;
            })
        } catch(error) {
            runInAction(() => this.loadingLocations = false);
            throw error;
        }
    }
}