import { observer } from "mobx-react-lite";
import {  Grid } from "@mui/material";
import { useState } from "react";
import * as _ from "lodash";

import { SchematicItem } from "../../../app/models/Schematics/Schematic";
import { pageRows, SortingType } from "../../../app/utils/globalConstants";
import { CustomTable, IColumnType, IPagination } from "../../common/tables/CustomTable";
import TableLink from "../../common/tables/TableLink";

interface Props {
    schematics: SchematicItem[];
    loading: boolean;
}

export default observer(function SchematicModuleDetail({ schematics, loading }: Props) {
    const [sortColumn, setSortColumn] = useState("order");
    const [sortOrder, setSortOrder] = useState(SortingType.desc);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pageRows[1]);

    const handlePageChange = (page:number) => {
        setPage(page);
    }

    const handleRowPerPageChange = (rows: number) => {
        setRowsPerPage(rows);
    }

    const handleSortChange = (column: string, sortOrder: SortingType) => {
        setSortColumn(column);
        setSortOrder(sortOrder);
    }

    const pagination: IPagination = {
        rowsPerPage: rowsPerPage,
        count: schematics?.length ?? 0,
        page: page,
        onPageChange: handlePageChange,
        onRowsPerPageChange: handleRowPerPageChange
    }

    const columns: IColumnType<SchematicItem>[] = [
        {
            key: "order",
            title: "Craft Order",
            sortLabel: "order",
            isSortable: true,
            align: "right",
            render: (_, { order}) => (
                <>{order.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "name",
            title: "Schematic",
            sortLabel: "name",
            isSortable: true,
            render: (_, { name, slug}) => (
                <TableLink url={`/schematics/${slug}`} name={name} />
            )
        },
        {
            key: "quantity",
            title: "Quantity",
            sortLabel: "quantity",
            isSortable: true,
            align: "right",
            render: (_, { quantity}) => (
                <>{quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "category",
            title: "Category",
            sortLabel: "category",
            isSortable: true
        },
        {
            key: "store",
            title: "Store",
            sortLabel: "store",
            isSortable: true
        },
        {
            key: "cost",
            title: "Cost",
            sortLabel: "cost",
            isSortable: true,
            align: "right",
            render: (_, { cost, isCamp, camp}) => (
                <>{isCamp && <> CAMP: {camp} |</>} {cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "prod",
            title: "Prod",
            sortLabel: "prod",
            isSortable: true,
            align: "right",
            render: (_, { prod}) => (
                <>{prod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
        {
            key: "manu",
            title: "Manu",
            sortLabel: "manu",
            isSortable: true,
            align: "right",
            render: (_, { manu}) => (
                <>{manu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
            )
        },
    ]

    return (
        <Grid container>
            <Grid item md={12} sm={12}>
            <CustomTable
                    data={_.orderBy(schematics, [sortColumn], [sortOrder === SortingType.asc ? 'asc' : 'desc']).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                    columns={columns}
                    pagination={pagination}
                    tableName="Schematic Modules"
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={handleSortChange}
                    loading={loading}
                    emptyTableText="No Schematic Modules found"
                />
            </Grid>
        </Grid>
    )    

})