import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Breadcrumb } from "../../app/models/common";
import { useStore } from "../../app/stores/store";
import HomeIcon from "@mui/icons-material/Home";
import TimelineIcon from "@mui/icons-material/Timeline";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import PageContainer from "../../app/common/page/PageContainer";
import { Box, Button, Table, TableBody, TableContainer, TableHead, TablePagination, Toolbar, Link as MuiLink, IconButton, TableSortLabel, Typography } from "@mui/material";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';
import { visuallyHidden } from '@mui/utils';
import { PublishForm } from "../../app/models/common";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import PublishedListItem from "../components/PublishedListItem";
import { TimelineItem } from "../../app/models/Blog/Timeline";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { history } from "../..";
import { useAuth0 } from "@auth0/auth0-react";

export default observer(function TimelineListPage() {
    const { user } = useAuth0();
    const { timelineAdminStore: timelineStore } = useStore()
    const { loading, pagingParams, totalCount, timelineList, loadTimelineList, publishTimeline, deleteTimeline } = timelineStore;
    const [page, setPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState("-changedDateTime");
    const [sortOrder, setSortOrder] = React.useState(SortingType.asc);
    const navigate = useNavigate();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Timelines", "pageurl": "/admin/timelines", "active": true, icon: <TimelineIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        loadTimelineList();
    }, [loadTimelineList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadTimelineList();
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadTimelineList();
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadTimelineList();
    }

    const handlePublishTimeline = (
        timelineId: number
    ) => {
        const form: PublishForm = {
            id: timelineId
        }

        publishTimeline(form).then(() => loadTimelineList());
    }

    const handleDeleteTimeline = (
        timelineId: number
    ) => {
        deleteTimeline(timelineId).then(() => loadTimelineList());
    }

    if (loading) return <LoadingComponent />
    
    return (
        <PageContainer breadcrumbs={breadcrumbs} pagetitle="Timelines">
            <>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PostAddIcon />}
                        component={Link}
                        to="/admin/timelines/create"
                    >
                        New Timeline
                    </Button>
                </Toolbar>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Timelines table">
                        <TableHead>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "title"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("title")}
                                    >
                                        Title
                                        {sortColumn === "title" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "contents"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("contents")}
                                    >
                                        Contents
                                        {sortColumn === "contents" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "publishedDate"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("publishedDate")}
                                    >
                                        Published
                                        {sortColumn === "publishedDate" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                Actions
                            </StyledTableCell>
                        </TableHead>
                        <TableBody>
                            {timelineList ? timelineList.map((timeline: TimelineItem) => (
                                <StyledTableRow key={timeline.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/timelines/${timeline.id}`}
                                            color="inherit"
                                        >
                                            {timeline.title}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <ReactMarkdown>
                                            {timeline.contents}
                                        </ReactMarkdown>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <PublishedListItem isPublished={timeline.isPublished} publishedDate={timeline.publishedDate} />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {!timeline.isPublished && <IconButton aria-label="publish" color="primary" onClick={() => handlePublishTimeline(timeline.id)}>
                                            <PublishIcon />
                                        </IconButton>}

                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteTimeline(timeline.id)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={5}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No timelines found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        </PageContainer>
    )
})