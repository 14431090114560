import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { SolarSystemItem } from "../../models/Locations/solarSystem";
import { PagingParams } from "../../models/pagination";


export default class UserLiteStore {
    loadingSolarSystems = false;
    totalSolarSystemCount: number = 0;
    pagingParamsSolarSystem = new PagingParams(10, 0, "name");
    solarSystemItems: SolarSystemItem[]  = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParamsSolarSystem = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalSolarSystemCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParamsSolarSystem.take.toString());
        params.append('skip', this.pagingParamsSolarSystem.skip.toString());
        params.append('sort', this.pagingParamsSolarSystem.sort);

        return params;
    }

    loadSolarSystemList = async (galaxyId: number = 0) => {
        this.loadingSolarSystems = true;

        try {
            var axiosParams = this.axiosParams;

            if (galaxyId !== 0) {
                axiosParams.append('galaxyId', galaxyId.toString());
            }

            const result = await agent.SolarSystemApi.getList(axiosParams);

            runInAction(() => {
                this.solarSystemItems = result.data;
                this.setPagination(result.totalCount);
                this.loadingSolarSystems = false;
            })
        } catch(error) {
            runInAction(() => this.loadingSolarSystems = false);
            throw error;
        }
    }
}