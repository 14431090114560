import { FormControlLabel, FormGroup, Grid, Typography, Checkbox, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import ResourceIcon from "@mui/icons-material/Park";
import { LootType } from "../../app/utils/globalConstants";
import ReactMarkdown from "react-markdown";
import PageHeader from "../../app/common/page/PageHeader";
import ResourceStoreTab from "./components/ResourceStoreTab";
import ResourceGbmTab from "./components/ResourceGbmTab";
import ResourceSchematics from "./components/ResourceSchematics";
import ResourceBlueprints from "./components/ResourceBlueprints";
import FoundryPattern from "../Foundry/Components/FoundryPattern";
import FactoryByResourceList from "../Factories/Components/FactoryByResourceList";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default observer(function ResourceDetailPage() {
    const { resourcesStore } = useStore();
    const { loading, loadResource, resourceItem } = resourcesStore;
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState(0);

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Resources", "pageurl": "/resources", "active": false, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": `${resourceItem?.name}`, "pageurl": `/resources/${id}`, "active": true, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
        loadResource(id!);
    }, [loadResource, id])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }


    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle={resourceItem?.name!} breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <Typography variant="body2" component="p">
                        <ReactMarkdown>
                            {resourceItem?.description!}
                        </ReactMarkdown>
                    </Typography>
                </Grid>
                <Grid item md={12} sm={12}>
                    <Typography variant="body2" component="p">
                        <strong>Price: {resourceItem?.basePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr | Average Gbm: {resourceItem?.averagePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr</strong>
                    </Typography>
                    <br />
                </Grid>
                <Grid item md={12} sm={12}>
                    <Typography variant="h5" component="h5">
                        Loot Types
                    </Typography>
                </Grid>
                <Grid item md={4} sm={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox color="success" name="combat" checked={(resourceItem?.loot! & LootType.Combat) === LootType.Combat} />} label="Combat" />
                        <FormControlLabel control={<Checkbox color="success" name="derelict" checked={(resourceItem?.loot! & LootType.DerelictExploring) === LootType.DerelictExploring} />} label="Derelict Exploring"  />
                        <FormControlLabel control={<Checkbox color="success" name="mining" checked={(resourceItem?.loot! & LootType.Mining) === LootType.Mining} />} label="Mining" />
                    </FormGroup>
                </Grid>
                <Grid item md={4} sm={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox color="success" name="nebula" checked={(resourceItem?.loot! & LootType.Nebula) === LootType.Nebula} />} label="Nebula Harvester" />
                        <FormControlLabel control={<Checkbox color="success" name="exploration" checked={(resourceItem?.loot! & LootType.Exploration) === LootType.Exploration} />} label="Exploration" />
                        <FormControlLabel control={<Checkbox color="success" name="wrecks" checked={(resourceItem?.loot! & LootType.WreckSalvage) === LootType.WreckSalvage} />} label="Wreck Salvage" />
                    </FormGroup>
                </Grid>
                <Grid item md={4} sm={12}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox color="success" name="extractable" checked={(resourceItem?.loot! & LootType.Planet) === LootType.Planet} />} label="Planet Extractable" />
                        <FormControlLabel control={<Checkbox color="success" name="settlement" checked={(resourceItem?.loot! & LootType.Settlement) === LootType.Settlement} />} label="Settlement" />
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="resources menu"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tab label="Stores" {...a11yProps(0)} />
                    <Tab label="Gbm history" {...a11yProps(1)} />
                    <Tab label="Used in Schematics" {...a11yProps(2)} />
                    <Tab label="Used in Blueprints" {...a11yProps(3)} />
                    <Tab label="Foundry Pattern" {...a11yProps(4)} />
                    <Tab label="Manufacturing Facilities" {...a11yProps(5)} />
                </Tabs>
            </Grid>
            <Grid item md={12}>
                <TabPanel value={value} index={0}>
                    <PageHeader pagetitle="Stores" />
                    <ResourceStoreTab id={id!} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PageHeader pagetitle="Gbm history" />
                    <ResourceGbmTab id={id!} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <PageHeader pagetitle="Schematics" />
                    <ResourceSchematics id={id!} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <PageHeader pagetitle="Blueprints" />
                    <ResourceBlueprints id={id!} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <FoundryPattern id={id!} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <FactoryByResourceList slug={id!} />
                </TabPanel>
            </Grid>
        </PageContainer>
    )
})