import { observer } from "mobx-react-lite";
import {   Grid, Table, TableBody, TableContainer, TableHead, TablePagination, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import { BlueprintRelatedItem } from "../../../app/models/Blueprints/Blueprint";
import { useEffect, useState } from "react";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../../app/utils/globalConstants";
import { useStore } from "../../../app/stores/store";

interface Props {
    id: string
}

export default observer(function ResourceBlueprints({ id }: Props) {
    const { resourceBlueprintsStore } = useStore();
    const { loading ,loadBlueprints, blueprints, pagingParams, totalCount  } = resourceBlueprintsStore;
    const [sortColumn, setSortColumn] = useState("name");
    const [sortOrder, setSortOrder] = useState(SortingType.asc);
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);

    useEffect(() => {
        loadBlueprints(id);
    }, [id])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadBlueprints(id);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadBlueprints(id);
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadBlueprints(id);
    }

    
    return (
        <Grid container>
            <Grid item md={12} sm={12}>
                <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="sold at table">
                            <TableHead>
                                <StyledTableCell>
                                    Blueprint
                                </StyledTableCell>
                                <StyledTableCell>
                                    Category
                                </StyledTableCell>
                            </TableHead>
                            <TableBody>
                                {blueprints ? blueprints.map((item: BlueprintRelatedItem) => (
                                    <StyledTableRow key={item.blueprintId} sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}>
                                        <StyledTableCell>
                                            <MuiLink
                                                component={Link}
                                                to={`/blueprints/${item.slug}`}
                                                color="inherit"
                                            >
                                                {item.name}
                                            </MuiLink>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {item.category}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : 
                                <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell colSpan={2}>
                                        <Typography variant="body2" component="div">No Blueprints Found</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={pageRows}
                        component="div"
                        count={totalCount}
                        page={page}
                        rowsPerPage={take}
                        showFirstButton={true}
                        showLastButton={true}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </Grid>
        </Grid>
    )    
})
