import { observer } from "mobx-react-lite";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";

import { Breadcrumb } from "../../../app/models/common";
import PageContainer from "../../../app/common/page/PageContainer";
import { history } from "../../..";
import { useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import PageHeader from "../../../app/common/page/PageHeader";
import ResourceIcon from "@mui/icons-material/Park";
import ResourceUpload from "./resources/ResourceUpload";
import ResourceList from "./resources/ResourceList";
import { useNavigate } from "react-router-dom";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }


export default observer(function ResourcesListPage() {
    const { user } = useAuth0();
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    
    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Resources", "pageurl": "/admin/resources", "active": true, icon: <ResourceIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }
    })

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return (
        <PageContainer pagetitle="Resources" breadcrumbs={breadcrumbs}>
            <Grid>
                <Grid item>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="stores Menu"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Resources" {...a11yProps(0)} />
                        <Tab label="Upload Resources" {...a11yProps(1)} />
                    </Tabs>
                </Grid>
                <Grid item>
                    <TabPanel value={value} index={0}>
                        <PageHeader pagetitle="Resources" />
                        <ResourceList />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PageHeader pagetitle="Upload Resources" />
                        <ResourceUpload />
                    </TabPanel>
                </Grid>
            </Grid>
        </PageContainer>
    )
})

