import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Breadcrumb } from "../../app/models/common";
import { useStore } from "../../app/stores/store";
import HomeIcon from "@mui/icons-material/Home";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LoadingComponent from "../../app/common/page/LoadingComponent";
import PageContainer from "../../app/common/page/PageContainer";
import { Box, Button, Table, TableBody, TableContainer, TableHead, TablePagination, Toolbar, Link as MuiLink, IconButton, Typography, TableSortLabel  } from "@mui/material";
import { pageRows, SortingType, StyledTableCell, StyledTableRow } from "../../app/utils/globalConstants";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';
import { PublishForm } from "../../app/models/common";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import { FaqItem } from "../../app/models/Blog/Faq";
import PublishedListItem from "../components/PublishedListItem";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuth0 } from "@auth0/auth0-react";
import { history } from "../..";
import { visuallyHidden } from '@mui/utils';

export default observer(function FaqListPage() {
    const { user } = useAuth0();
    const { faqAdminStore: faqStore } = useStore()
    const { loading, pagingParams, totalCount, faqList, loadFaqList, publishFaq, deleteFaq } = faqStore;
    const [page, setPage] = React.useState(0);
    const [sortColumn, setSortColumn] = React.useState("order");
    const [sortOrder, setSortOrder] = React.useState(SortingType.desc);
    const navigate = useNavigate();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Admin", "pageurl": "/admin", "active": false, icon: <DashboardIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Faqs", "pageurl": "/admin/faqs", "active": true, icon: <QuestionAnswerIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        if (user !== undefined) {
            var roles: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            if (!roles.includes("admin")) {
                navigate('/unauthorized');
            }
        } else if (user === undefined) {
            navigate('/unauthorized');
        }

        loadFaqList();
    }, [loadFaqList])

    const handleChangePage = (
        event: unknown,
        newPage: number,
    ) => {
        pagingParams.skip = newPage * pagingParams.take;
        setPage(newPage);
        loadFaqList();
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        pagingParams.take = parseInt(event.target.value, 10);
        pagingParams.skip = 0;
        loadFaqList();
    }

    const handleChangeSort = (
        column: string
    ) => {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortOrder(SortingType.asc);
            pagingParams.sort = column;
        } else {
            if (sortOrder === SortingType.asc) {
                setSortOrder(SortingType.desc);
                pagingParams.sort = `-${column}`;
            } else {
                setSortOrder(SortingType.asc);
                pagingParams.sort = column;
            }
            setSortColumn(column);
        }
        loadFaqList();
    }

    const handlePublishFaq = (
        faqId: number
    ) => {
        const form: PublishForm = {
            id: faqId
        }

        publishFaq(form).then(() => loadFaqList());
    }

    const handleDeleteFaq = (
        faqId: number
    ) => {
        deleteFaq(faqId).then(() => loadFaqList());
    }

    if (loading) return <LoadingComponent />
    
    return (
        <PageContainer breadcrumbs={breadcrumbs} pagetitle="Faqs">
            <>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PostAddIcon />}
                        component={Link}
                        to="/admin/faqs/create"
                    >
                        New Faq
                    </Button>
                </Toolbar>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="Faqs table">
                        <TableHead>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "question"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("question")}
                                    >
                                        Question
                                        {sortColumn === "question" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "awnser"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("awnser")}
                                    >
                                        Awnser
                                        {sortColumn === "awnser" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "publishedDate"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("publishedDate")}
                                    >
                                        Published
                                        {sortColumn === "publishedDate" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                <TableSortLabel
                                        active={sortColumn === "order"}
                                        direction={sortOrder === SortingType.asc ? 'asc' : 'desc'}
                                        onClick={ () => handleChangeSort("order")}
                                    >
                                        Order
                                        {sortColumn === "order" ? (
                                            <Box 
                                                component="span" 
                                                sx={visuallyHidden}
                                            >
                                                {sortOrder === SortingType.desc ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null }
                                    </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>
                                Actions
                            </StyledTableCell>
                        </TableHead>
                        <TableBody>
                            {faqList ? faqList.map((faq: FaqItem) => (
                                <StyledTableRow key={faq.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <StyledTableCell>
                                        <MuiLink
                                            component={Link}
                                            to={`/admin/faqs/${faq.id}`}
                                            color="inherit"
                                        >
                                            {faq.question}
                                        </MuiLink>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <ReactMarkdown>
                                            {faq.answer}
                                        </ReactMarkdown>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <PublishedListItem isPublished={faq.isPublished} publishedDate={faq.publishedDate} />    
                                    </StyledTableCell>
                                    <StyledTableCell>{faq.order}</StyledTableCell>
                                    <StyledTableCell>
                                        {!faq.isPublished && <IconButton aria-label="publish" color="primary" onClick={() => handlePublishFaq(faq.id)}>
                                            <PublishIcon />
                                        </IconButton>}

                                        <IconButton aria-label="delete" color="error" onClick={() => handleDeleteFaq(faq.id)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : 
                            <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <StyledTableCell colSpan={5}>
                                    { loading ? <LoadingComponent /> : <Typography variant="body2" component="div">No faq's found</Typography> }
                                </StyledTableCell>
                            </StyledTableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={pageRows}
                    component="div"
                    count={totalCount}
                    page={page}
                    rowsPerPage={pagingParams.take}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
        </PageContainer>
    )
})