import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from 'formik';

interface Props {
    name: string;
    options: any;
    label?: string;
}

export default function MySelectInput(props: Props) {
    const [field, meta, helpers] = useField(props.name); 
    return (
        <FormControl variant="outlined" fullWidth >
            <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
            <Select 
                error={meta.touched && !!meta.error}
                labelId={`${props.name}-label`} 
                id={props.name}
                {...field} {...props} 
                style={{'display':'flex', 'margin': '10px 5px', 'marginRight': '-5px'}}
            >
                {props.options.map(({id, name}: any) => (
                    <MenuItem key={id} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}