import { makeAutoObservable, reaction } from "mobx";
import { ServerError } from "../models/serverError";

export default class CommonStore {
    error: ServerError | null = null;
    currentUser: string | null = localStorage.getItem('currentUser');
    theme: string | null = localStorage.getItem('theme');
    redmat: string | null = localStorage.getItem('redmat');
    foundry: string | null = localStorage.getItem('foundry');
    appLoaded = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.currentUser,
            currentUser => {
                if (currentUser) {
                    localStorage.setItem('currentUser', currentUser);
                } else {
                    localStorage.removeItem('currentUser');
                }
            },
        )

        reaction(
            () => this.theme,
            theme => {
                if(theme) {
                    localStorage.setItem('theme', theme);
                } else {
                    localStorage.setItem('theme', "lightTheme");
                }
            }
        )

        reaction(
            () => this.redmat,
            redmat => {
                if (redmat) {
                    localStorage.setItem('redmat', redmat);
                } else {
                    localStorage.setItem('redmat', "none");
                }
            }
        )

        reaction(
            () => this.foundry,
            foundry => {
                if (foundry) {
                    localStorage.setItem('foundry', foundry);
                } else {
                    localStorage.setItem('foundry', "none");
                }
            }
        )
    }

    setServerError = (error: ServerError) => {
        this.error= error;
    }

    setCurrentUser = (currentUser: string | null) => {
        this.currentUser = currentUser;
    }

    setTheme = (theme: string) => {
        if (theme === "0") {
            theme = "lightTheme";
        }

        this.theme = theme;
    }

    setRedMat = (redmat: string) => {
        this.redmat = redmat;
    }

    setFoundry = (foundry: string) => {
        this.foundry = foundry;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }
}