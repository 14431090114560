import { observer } from "mobx-react-lite";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumb } from "../../app/models/common";
import PageContainer from "../../app/common/page/PageContainer";
import { useStore } from "../../app/stores/store";
import { useEffect, useState } from "react";
import StarbaseIcon from "@mui/icons-material/AccountBalance";
import MyStarbasesIcon from "@mui/icons-material/SatelliteAlt";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid, Alert, AlertTitle, TextField } from "@mui/material";
import StarbaseComponent from "./components/StarbaseComponent";
import StarbaseArmourComponent from "./components/StarbaseArmourComponent";
import { StarbaseDesign, StarbaseDetails, StarbaseSummary } from "../../app/models/StarbaseBuilder/StarbaseBuilder";
import StarbaseSummaryComponent from "./components/StarbaseSummaryComponent";
import { useSnackbar } from "notistack";
import { SchematicQuery } from "../../app/models/Schematics/Schematic";
import { useParams } from "react-router-dom";

export default observer(function BuildStarbasePage() {
    const { starbaseBuilderStore, itemStore, craftingStore } = useStore();
    const { starbaseModifiers, loadModifiers, addStarbaseDesign, updateStarbaseDesign, loadStarbaseDesign, starbaseDesign } = starbaseBuilderStore;
    const { loadItemList, pagingParams, itemItems } = itemStore;
    const { addSchematics } = craftingStore;
    const { isAuthenticated } = useAuth0();
    const [summary, setSummary] = useState(new StarbaseSummary);
    const [details, setDetails] = useState(new StarbaseDetails);
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const { id } = useParams<{ id: string }>();


    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Starbase Builder", "pageurl": "/starbasebuilder", "active": !isAuthenticated, icon: <MyStarbasesIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Build", "pageurl": "/starbasebuilder/build", "active": true, icon: <StarbaseIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
    ]

    useEffect(() => {
        pagingParams.skip = 0;
        pagingParams.take = -1;
        pagingParams.sort = "name";

        loadItemList('starbases');
    }, [loadItemList])

    useEffect(() => {
        loadModifiers();
        calculateSummary();
    }, [loadModifiers])

    useEffect(() => {
        if (id !== undefined) {
            loadStarbaseDesign(parseInt(id!));
        } else {
            handleReset();
        }
    }, [id])

    useEffect(() => {
        if (starbaseDesign !== null) {
            setSummary(starbaseDesign?.summary!);
            setDetails(starbaseDesign?.details!);
            setName(starbaseDesign?.name!);
        }
    }, [starbaseDesign])

    function calculateSummary() {
        let form : StarbaseSummary = {
            armour: 0,
            hull: 0,
            energy: 0,
            recycling: 0,
            eco: 0,
            science: 0,
            population: 0,
            buildTime: 0
        };

        if (details.chassis !== 0) {
            form = addComponent(form, details.chassis);
        }

        if (details.frame !== 0) {
            form = addComponent(form, details.frame);
        }

        if (details.engineering !== 0) {
            form = addComponent(form, details.engineering);
        }

        if (details.eco !== 0) {
            form = addComponent(form, details.eco);
        }

        if (details.recycling !== 0) {
            form = addComponent(form, details.recycling);
        }

        if (details.science !== 0) {
            form = addComponent(form, details.science);
        }

        if (details.housing !== 0) {
            form = addComponent(form, details.housing);
        }

        if (details.modifier !== 0) {
            const selectedArmour = starbaseModifiers.find(e => e.id == details.modifier)!;

            form.armour = Math.ceil((form.armour * selectedArmour.starbaseModifier / 100) + form.armour);
            form.hull = Math.ceil((form.hull * selectedArmour.starbaseModifier / 100) + form.hull);
        }

        setSummary(form);
    }

    function addComponent(summary: StarbaseSummary, id: number) {
        const item = itemItems.find(e => e.id === id)!;

        if (item !== undefined) {
            summary.armour += parseInt(item.attributes.find(e => e.key === "Armour")?.value!);
            summary.hull += parseInt(item.attributes.find(e => e.key === "Hull")?.value!);
            summary.energy += parseInt(item.attributes.find(e => e.key === "Energy")?.value!);
            summary.recycling += parseInt(item.attributes.find(e => e.key === "Recycling")?.value!);
            summary.eco += parseInt(item.attributes.find(e => e.key === "Ecosystem")?.value!);
            summary.science += parseInt(item.attributes.find(e => e.key === "Scientific")?.value!);
            summary.population += parseInt(item.attributes.find(e => e.key === "PopulationManagement")?.value!);
            summary.buildTime += parseInt(item.attributes.find(e => e.key === "BuildTime")?.value!);
        }
        return summary;
    }

    function handleAddSchematics() {
        let form: SchematicQuery[] = [];

        if (details.eco !== 0) {
            form = getSchematic(form, details.eco);
        }

        if (details.engineering !== 0) {
            form = getSchematic(form, details.engineering);
        }

        if (details.frame !== 0) {
            form = getSchematic(form, details.frame);
        }

        if (details.housing !== 0) {
            form = getSchematic(form, details.housing);
        }

        if (details.recycling !== 0) {
            form = getSchematic(form, details.recycling);
        }

        if (details.science !== 0) {
            form = getSchematic(form, details.science);
        }

        if (details.chassis !== 0) {
            form = getSchematic(form, details.chassis);
        }

        addSchematics(form);
        enqueueSnackbar('Schematics Added', { variant: "success" });
    }

    function handleSaveSchematic() {
        const form: StarbaseDesign = {
            id: id === undefined ? 0 : parseInt(id!),
            name: name,
            details: details,
            summary: summary
        };

        if (id !== undefined) {
            updateStarbaseDesign(form);
        } else {
            addStarbaseDesign(form);
        }

        enqueueSnackbar('Ship Design Saved', { variant: "success" });
    }

    function handleReset() {
        setName("");
        setSummary(new StarbaseSummary);
        setDetails(new StarbaseDetails);
    }

    function getSchematic(schems: SchematicQuery[], id: number) {
        const item = itemItems.find(e => e.id === id);

        if (item !== undefined) {
            const form : SchematicQuery = {
                id: item.schematicId,
                quantity: 1,
                name: item.name
            }

            schems.push(form);
        }

        return schems;
    }

    function setDetail(type: string, id: number) {
        switch(type) {
            case 'eco':
                details.eco = id;
                break;
            case 'engineering':
                details.engineering = id;
                break;
            case 'frame':
                details.frame = id;
                break;
            case 'housing':
                details.housing = id;
                break;
            case 'recycling':
                details.recycling = id;
                break;
            case 'science':
                details.science = id;
                break;
            case 'chassis':
                details.chassis = id;
                break;
            case 'modifier':
                details.modifier = id;
                break;
        }

        setDetails(details);
        calculateSummary();
    }

    return (
        <PageContainer pagetitle="Starbase Builder" breadcrumbs={breadcrumbs}>
            <Grid container>
                {!isAuthenticated && 
                    <Grid item md={12}>
                        <Alert severity="info" variant="filled">
                            <AlertTitle>Info</AlertTitle>
                            Did you know that if you create an account, that you can save your designs and compare them?
                        </Alert>
                    </Grid>
                }
                <Grid item md={12} sx={{marginLeft: "5px", marginRight: "5px"}}>
                    <StarbaseSummaryComponent summary={summary}  />
                    <Grid container justifyContent="flex-end" spacing={2}>
                        { isAuthenticated && 
                            <Grid item>
                                <TextField id="name" label="Name" variant="outlined" onChange={event => setName(event.target.value)} value={name} size="small" sx={{marginTop: "4px"}} />
                                <Button color="primary" onClick={handleSaveSchematic} variant="contained">Save</Button>
                            </Grid>
                        }
                        <Grid item>
                            <Button color="primary" onClick={handleAddSchematics} variant="contained">Add to Schematics</Button>
                        </Grid>
                        <Grid item>
                            <Button color="error" onClick={handleReset} variant="contained">Reset</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Ecosystem" components={itemItems.filter(e => e.subCategorySlug === 'ecosystem')} selectedItem={details.eco} setComponent={setDetail}  type="eco" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Engineering" components={itemItems.filter(e => e.subCategorySlug === 'engineering')} selectedItem={details.engineering} setComponent={setDetail} type="engineering" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Frame" components={itemItems.filter(e => e.subCategorySlug === 'frame')} selectedItem={details.frame} setComponent={setDetail} type="frame" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Housing" components={itemItems.filter(e => e.subCategorySlug === 'housing')} selectedItem={details.housing} setComponent={setDetail} type="housing" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Recycling System" components={itemItems.filter(e => e.subCategorySlug === 'recycling')} selectedItem={details.recycling} setComponent={setDetail} type="recycling" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Science" components={itemItems.filter(e => e.subCategorySlug === 'science')} selectedItem={details.science} setComponent={setDetail} type="science" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseComponent title="Chassis" components={itemItems.filter(e => e.subCategorySlug === 'chassis')} selectedItem={details.chassis} setComponent={setDetail} type="chassis" />
                </Grid>
                <Grid item md={3} sm={12} >
                    <StarbaseArmourComponent components={starbaseModifiers} selectedItem={details.modifier} setComponent={setDetail} />
                </Grid>
            </Grid>
        </PageContainer>
    )
})

