import { FactoryCalcType } from "../../utils/globalConstants";

export class RefineryRequest {
    facilities: RefineryRequestItem[] = [];
    locationId: number = 0;
    archimedes: boolean = false;

    constructor(form?: RefineryRequest) {
        if (form) {
            this.facilities = form.facilities;
            this.locationId = form.locationId;
            this.archimedes = form.archimedes;
        }
    }
}

export class RefineryRequestItem {
    facilityId: number = 0;
    quantityFacilities: number = 0;
    productionQuantity: number = 0;
    calcType: FactoryCalcType = 1;

    constructor(form?: RefineryRequestItem) {
        if (form){
            this.facilityId = form.facilityId;
            this.quantityFacilities = form.quantityFacilities;
            this.productionQuantity = form.productionQuantity;
            this.calcType = form.calcType;
        }
    }
}

export interface RefineryResponse {
    facilities: RefineryFacilityResponse[];
    production: RefineryProductionResponse[];
    requirements: RefineryRequirementResponse[];
}

export interface RefineryFacilityResponse {
    facilityId: number;
    facility: string;
    quantityFacilities: number;
    productionQuantity: number;
    productionType: FactoryCalcType;
}

export interface RefineryProductionResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    duration: number;
    productionType: FactoryCalcType;
}

export interface RefineryRequirementResponse {
    resourceId: number;
    resourceSlug: string;
    resourceName: string;
    quantity: number;
    quantityHave: number;
    quantityNeed: number;
}