import { Grid, Tabs, Tab, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";

import PageContainer from "../../app/common/page/PageContainer";
import LoadingComponent from "../../app/common/page/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../../app/models/common";

import HomeIcon from "@mui/icons-material/Home";
import PageHeader from "../../app/common/page/PageHeader";
import SchematicIcon from "@mui/icons-material/Apartment";
import SchematicResources from "./components/SchematicResources";
import SchematicModules from "./components/SchematicModules";
import SchematicParents from "./components/SchematicParents";
import LocationList from "../components/LocationList";
import { useAuth0 } from "@auth0/auth0-react";
import ReductionMatrixList from "../components/ReductionMatrixList";
import AddSchematicComponent from "./components/AddSchematicComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

export default observer(function SchematicsDetailPage() {
    const { schematicStore, commonStore } = useStore();
    const { loading, loadSchematic, schematicItem } = schematicStore;
    const { redmat, setRedMat } = commonStore;
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState(0);
    const [locationId, setLocationId] = useState(0);
    const { isAuthenticated } = useAuth0();

    const breadcrumbs : Breadcrumb[] = [
        {"title": "Home", "pageurl": "/", "active": false, icon: <HomeIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": "Schematics", "pageurl": "/schematics", "active": false, icon: <SchematicIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />},
        {"title": `${schematicItem?.schematics[0].name}`, "pageurl": `/schematics/${id}`, "active": true, icon: <SchematicIcon sx={{ mr: 0.5, marginBottom: "-2px" }} fontSize="inherit" />}
    ]

    useEffect(() => {
        loadSchematic(id!, redmat!, locationId);
    }, [loadSchematic, id, redmat, locationId])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    function handleChangeLocation(locId: number) {
        setLocationId(locId);
    };

    function handleChangeRedMat(redMat: string) {
        setRedMat(redMat);
    }

    if (loading) return <LoadingComponent />

    return (
        <PageContainer pagetitle={schematicItem?.schematics[0].name!} breadcrumbs={breadcrumbs}>
            <Grid container>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Manufacture: ${schematicItem?.manu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Production: ${schematicItem?.prod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Category: ${schematicItem?.schematics[0]?.category}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Tech: ${schematicItem?.schematics[0]?.tech.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Schematic XP: ${schematicItem?.schematics[0]?.xp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total XP: ${schematicItem?.totalXP?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total Schematic Cost: ${schematicItem?.schematicCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Total Resource Cost: ${schematicItem?.resourcesCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Cr`} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={4}>
                    <List>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Schematic Store: ${schematicItem?.schematics[0]?.store}`} />
                        </ListItem>
                        <ListItem sx={{ paddingTop: '1px', paddingBottom: '1px' }}>
                            <ListItemText primary={`Schematic Cost: ${schematicItem?.schematics[0]?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${schematicItem?.schematics[0]?.currency}`} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="schematics menu"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tab label="Resources" {...a11yProps(0)} />
                    <Tab label="Modules" {...a11yProps(1)} />
                    <Tab label="Schematics Used In" {...a11yProps(2)} />
                </Tabs>
            </Grid>
            <Grid item md={12}>
                <TabPanel value={value} index={0}>
                    <PageHeader pagetitle="Resources" />
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <AddSchematicComponent id={parseInt(id!)} name={schematicItem?.schematics[0].name!} small={false} />
                        </Grid>
                        <Grid item>
                            { isAuthenticated && <LocationList locationId={locationId} setLocationId={handleChangeLocation} />}
                        </Grid>
                        <Grid item>
                            <ReductionMatrixList redMat={redmat!} setRedMat={handleChangeRedMat} />    
                        </Grid>
                    </Grid>
                    <SchematicResources resources={schematicItem?.resources!} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PageHeader pagetitle="Modules" />
                    <SchematicModules schematics={schematicItem?.schematics!} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <PageHeader pagetitle="Schematics Used In" />
                    <SchematicParents id={id!} />
                </TabPanel>
            </Grid>
        </PageContainer>
    )
})