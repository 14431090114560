export interface SchematicListItem {
    id: number;
    name: string;
    slug: string;
    level: number;
    isAlien: boolean;
    isModule: boolean;
    isArtifact: boolean;
    category: string;
}

export interface SchematicLiteItem {
    id: number;
    name: string;
}

export interface SchematicSummary {
    manu: number;
    prod: number;
    totalXP: number;
    schematicCost: number;
    resourcesCost: number;
    schematics: SchematicItem[];
    resources: SchematicResource[];
}

export interface SchematicItem {
    id: number;
    name: string;
    slug: string;
    manu: number;
    prod: number;
    totalXP: number;
    xp: number;
    quantity: number;
    order: number;
    category: string;
    tech: number;
    store: string;
    cost: number;
    currency: string;
    parentId: number;
    camp: number;
    isCamp: boolean;
    level: number;
}

export interface SchematicResource {
    id: number;
    name: string;
    slug: string;
    quantity: number;
    cost: number;
    myQty: number;
    missingQty: number;
}

export class SchematicQuery {
    id: number = 0;
    quantity: number = 1;
    name: string = '';

    constructor(form?: SchematicQuery) {
        if (form) {
            this.id = form.id;
            this.quantity = form.quantity;
            this.name = form.name;
        }
    }
}

export class SchematicQueryLite {
    id: number = 0;
    quantity: number = 1;

    constructor(form?: SchematicQuery) {
        if (form) {
            this.id = form.id;
            this.quantity = form.quantity;
        }
    }
}

export class SchematicRequest {
    locationId: number = 0;
    redMat: string = "none";
    schematics: SchematicQueryLite[] = [];

    constructor(form?: SchematicRequest) {
        if (form) {
            this.locationId = form.locationId;
            this.redMat = form.redMat;
            this.schematics = form.schematics;
        }
    }
}

export interface SchematicRelatedItem {
    schematicId: number;
    name: string;
    slug: string;
    category: string;
}


export interface IModuleUploadForm {
    myModules: string;
}

export class ModuleUploadForm {
    myModules: string = "";

    constructor(form?: IModuleUploadForm) {
        if (form) {
            this.myModules = form.myModules;
        }
    }
}

export interface MyModuleItem {
    schematicId: number;
    schematicSlug: string;
    schematicName: string;
    category: string;
    quantity: number;
}

export interface MyBattleshipModuleItem {
    id: number;
    slug: string;
    name: string;
    store: string;
    quantity: number;
}