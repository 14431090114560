import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import DashboardIcon from '@mui/icons-material/Dashboard';
import UsersIcon from '@mui/icons-material/Groups';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TimelineIcon from "@mui/icons-material/Timeline";
import ArticleIcon from "@mui/icons-material/Article";
import ShipIcon from "@mui/icons-material/RocketLaunch";
import MyShipsIcon from "@mui/icons-material/Rocket";
import StarbaseIcon from "@mui/icons-material/AccountBalance";
import MyStarbasesIcon from "@mui/icons-material/SatelliteAlt";
import ResourceIcon from "@mui/icons-material/Park";
import MyResourcesIcon from "@mui/icons-material/NaturePeople";
import BlueprintIcon from "@mui/icons-material/Business";
import SchematicIcon from "@mui/icons-material/Apartment";
import DataRepoIcon from "@mui/icons-material/ViewModule";
import StoreIcon from "@mui/icons-material/Store";
import LocationIcon from "@mui/icons-material/Public";
import CraftingIcon from '@mui/icons-material/Construction';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import MySchematicIcon from '@mui/icons-material/Widgets';
import MyBsModulesIcon from '@mui/icons-material/ViewModule';
import FoundryIcon from '@mui/icons-material/Factory';
import FactoryIcon from '@mui/icons-material/LocalGasStation';
import SmrIcon from '@mui/icons-material/PrecisionManufacturing';
import AdvIcon from '@mui/icons-material/AccountTree';
import FactoryCalcIcon from '@mui/icons-material/Warehouse';
import RefineryIcon from '@mui/icons-material/ControlCamera';

const drawerWidth: number = 250;

const Drawer = styled(MuiDrawer, { 
  shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(55,55,55)' : '#eee',
      position: 'relative',
      width: drawerWidth,
      top: theme.spacing(8),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
  }),
);

interface SideMenuProps {
    open: boolean;
  }

export default function SideMenu({open}: SideMenuProps) {
  const { isAuthenticated, user } = useAuth0();
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState("none");

  function handleOpenSettings(menu: string){
    if (openMenu === menu) {
      setOpenCollapse(!openCollapse);
    }
    else {
      setOpenMenu(menu);
      setOpenCollapse(true);
    }
  }

  function isInRole(role: string) {
    if (user !== undefined) {
        var claims: Array<string> = user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  
        if (claims.includes(role)) {
          return true
        }
      }
  
      return false;
}

    return (
        <Drawer 
        variant='permanent' 
        open={open}
        style={{marginBottom: "65px"}}
      >
        <List component="nav">
          <ListItem button component={Link} to='/'>
            <ListItemIcon>
              <DashboardIcon color='info' />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {isAuthenticated && isInRole("admin") ?
            (<>
              <ListItem button onClick={() => handleOpenSettings("admin")}>
                <ListItemIcon>
                  <UsersIcon color='warning' />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {openCollapse && openMenu === "admin" ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openCollapse && openMenu === "admin"} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                  <ListItem button component={Link} to='/admin'>
                      <ListItemIcon>
                        <DashboardIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button component={Link} to='/admin/users'>
                      <ListItemIcon>
                        <UsersIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem button component={Link} to='/admin/articles'>
                      <ListItemIcon>
                        <ArticleIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="Articles" />
                    </ListItem>
                    <ListItem button component={Link} to='/admin/timelines'>
                      <ListItemIcon>
                        <TimelineIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="Timelines" />
                    </ListItem>
                    <ListItem button component={Link} to='/admin/faqs'>
                      <ListItemIcon>
                        <QuestionAnswerIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="Faqs" />
                    </ListItem>
                </List>
              </Collapse>
              </>) : ("")
          }

          {isAuthenticated && isInRole("dr_admin") ?
          (<>
          <ListItem button onClick={() => handleOpenSettings("dr")}>
            <ListItemIcon>
              <DataRepoIcon color='error' />
            </ListItemIcon>
            <ListItemText primary="Repository admin" />
            {openCollapse && openMenu === "dr" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapse && openMenu === "dr"} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
                <ListItem button component={Link} to='/admin/repository'>
                  <ListItemIcon>
                    <DataRepoIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to='/admin/locations'>
                  <ListItemIcon>
                    <LocationIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </ListItem>
                <ListItem button component={Link} to='/admin/stores'>
                  <ListItemIcon>
                    <StoreIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Stores" />
                </ListItem>
                <ListItem button component={Link} to='/admin/resources'>
                  <ListItemIcon>
                    <ResourceIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Resources" />
                </ListItem>
                <ListItem button component={Link} to='/admin/blueprints'>
                  <ListItemIcon>
                    <BlueprintIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Blueprints" />
                </ListItem>
                <ListItem button component={Link} to='/admin/schematics'>
                  <ListItemIcon>
                    <SchematicIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Schematics" />
                </ListItem>
              </List>
            </Collapse>
          </>) : ("")
          }
          
          <ListItem button onClick={() => handleOpenSettings("ships")}>
            <ListItemIcon>
              <ShipIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary="Ships" />
            {openCollapse && openMenu === "ships" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapse && openMenu === "ships"} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
            {isAuthenticated ?
              (<>
                <ListItem button component={Link} to='/shipbuilder'>
                  <ListItemIcon>
                    <MyShipsIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="My ships" />
                </ListItem>
                <ListItem button component={Link} to='/schematics/battleships'>
                  <ListItemIcon>
                    <MyBsModulesIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="My BS Modules" />
                </ListItem>
              </>) : ("")}
                <ListItem button component={Link} to='/shipbuilder/build'>
                  <ListItemIcon>
                    <ShipIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Ship builder" />
                </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleOpenSettings("starbases")}>
            <ListItemIcon>
              <StarbaseIcon color='success' />
            </ListItemIcon>
            <ListItemText primary="Starbases" />
            {openCollapse && openMenu === "starbases" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapse && openMenu === "starbases"} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
              {isAuthenticated ?
                (<>
                  <ListItem button component={Link} to='/starbasebuilder'>
                    <ListItemIcon>
                      <MyStarbasesIcon color='secondary' />
                    </ListItemIcon>
                    <ListItemText primary="My starbases" />
                  </ListItem>
                </>) : ("")}
                <ListItem button component={Link} to='/starbasebuilder/build'>
                  <ListItemIcon>
                    <StarbaseIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Starbase builder" />
                </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleOpenSettings("resources")}>
            <ListItemIcon>
              <ResourceIcon color='warning' />
            </ListItemIcon>
            <ListItemText primary="Resources" />
            {openCollapse && openMenu === "resources" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapse && openMenu === "resources"} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
                {isAuthenticated ?
                  (<>
                    <ListItem button component={Link} to='/resources/cs'>
                      <ListItemIcon>
                        <MyResourcesIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="My resources" />
                    </ListItem>
                  </>) : ("")}
              <ListItem button component={Link} to='/resources'>
                  <ListItemIcon>
                    <ResourceIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Resources" />
                </ListItem>
              <ListItem button component={Link} to='/gbmHistory'>
                  <ListItemIcon>
                    <LocalGroceryStoreIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Gbm History" />
              </ListItem>
              <ListItem button component={Link} to='/foundry/calculator'>
                  <ListItemIcon>
                    <FoundryIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Foundry" />
                </ListItem>
                <ListItem button component={Link} to='/factory/heavyFuel'>
                  <ListItemIcon>
                    <FactoryIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Heavy Fuel" />
                </ListItem>
                <ListItem button component={Link} to='/factory/smr'>
                  <ListItemIcon>
                    <SmrIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="SMR" />
                </ListItem>
                <ListItem button component={Link} to='/factory/advRefinery'>
                  <ListItemIcon>
                    <AdvIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Adv Refinery" />
                </ListItem>
                <ListItem button component={Link} to='/factory/factory'>
                  <ListItemIcon>
                    <FactoryCalcIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Factory" />
                </ListItem>
                <ListItem button component={Link} to='/factory/refinery'>
                  <ListItemIcon>
                    <RefineryIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Refinery" />
                </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleOpenSettings("blueprints")}>
            <ListItemIcon>
              <BlueprintIcon color='error' />
            </ListItemIcon>
            <ListItemText primary="Blueprints" />
            {openCollapse && openMenu === "blueprints" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapse && openMenu === "blueprints"} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
              <ListItem button component={Link} to='/blueprints'>
                  <ListItemIcon>
                    <BlueprintIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Blueprints" />
                </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleOpenSettings("schematics")}>
            <ListItemIcon>
              <SchematicIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary="Schematics" />
            {openCollapse && openMenu === "schematics" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCollapse && openMenu === "schematics"} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
              <ListItem button component={Link} to='/crafting'>
                <ListItemIcon>
                    <CraftingIcon color='secondary' />
                </ListItemIcon>
                <ListItemText primary="Crafting" />
              </ListItem>
              {isAuthenticated ?
                  (<>
                    <ListItem button component={Link} to='/schematics/modules'>
                      <ListItemIcon>
                        <MySchematicIcon color='secondary' />
                      </ListItemIcon>
                      <ListItemText primary="My Modules" />
                    </ListItem>
                  </>) : ("")}
              <ListItem button component={Link} to='/schematics'>
                  <ListItemIcon>
                    <SchematicIcon color='secondary' />
                  </ListItemIcon>
                  <ListItemText primary="Schematics" />
                </ListItem>
            </List>
          </Collapse>

        </List>
      </Drawer>
    );
}

