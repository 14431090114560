import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { PagingParams } from "../../models/pagination";
import { MyResourceItem, ResourceUploadForm } from "../../models/Resources/Resource";

export default class MyResourceStore {
    loading = false;
    totalCount: number = 0;
    pagingParams = new PagingParams(10, 0, "name");
    myResources: MyResourceItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPagination = (totalCount: number) => {
        this.totalCount = totalCount;
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('take', this.pagingParams.take.toString());
        params.append('skip', this.pagingParams.skip.toString());
        params.append('sort', this.pagingParams.sort);

        return params;
    }

    loadMyResources = async (type: number = 0, locationId: number = 0, name: string, groupBy: string, userId: string, lootType: number) => {
        this.loading = true;

        try {

            var axiosParams = this.axiosParams;

            if (type !== 0) {
                axiosParams.append('type', type.toString());
            }

            if (name !== '') {
                axiosParams.append('name', name);
            }

            if (locationId !== 0) {
                axiosParams.append('locationId', locationId.toString());
            }

            if (groupBy !== '') {
                axiosParams.append('groupBy', groupBy);
            }

            if (userId !== '') {
                axiosParams.append('userId', userId);
            }

            if (lootType !== 0) {
                axiosParams.append('lootType', lootType.toString());
            }

            const result = await agent.ResourcesApi.getMyResources(axiosParams);

            runInAction(() => {
                this.myResources = result.data;
                this.setPagination(result.totalCount);
                this.loading = false;
            })
        } catch(error) {
            runInAction(() => this.loading = false);
            throw error;
        }
    }

    uploadResources = async(form: ResourceUploadForm) => {
        try {
            this.loading = true;
            await agent.ResourcesApi.upload(form);

            runInAction(() => {
                this.loadMyResources(0, 0, '', 'none', '', 0)
                this.loading = false;
            })
        } catch(error) {
            this.loading = false;
            throw error;
        }
    }
}